import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../components/Loader";
import {
  getCountriesFn,
  getCountryCode,
  getPortDetails,
  getQuotes,
  getSKUList,
  getVendorList,
} from "../utils/commonFn";
import { SuccessModal } from "../components/SuccessModal";
import AddSkuModal from "../components/AddSkuModal";
import AddVendorModal from "../components/AddVendorModal";
function Apo() {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const location = useLocation();
  console.log(location, "location");
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsModified, setoptionsModified] = useState([
    {
      label: "Add Vendor",
      value: "Add Vendor",
    },
  ]);
  const [successMessagePopup, setsuccessMessagePopup] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [selectedOptionQuote, setSelectedOptionQuote] = useState(null);
  const [optionsModifiedQuote, setoptionsModifiedQuote] = useState([]);
  const [skuListDetails, setskuListDetails] = useState([]);
  const [showvendorAdd, setShowvendorAdd] = useState(false);
  const [showvendorAddSku, setShowvendorAddSku] = useState(false);
  const [selectedOptionCountry, setselectedOptionCountry] = useState(null);
  const [selectedOptionPort, setSelectedOptionPort] = useState(null);
  const [optionsForPorts, setoptionsForPorts] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [vendorListDetailsRaw, setvendorListDetailsRaw] = useState([]);
  const [addNewVendorDetails, setaddNewVendorDetails] = useState({
    customer_id: customerId,
    company_name: "",
    origin_country: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    communication_mode: "",
    nearest_sea_port_code: "",
    nearest_sea_port_name: "",
    nearest_air_port_code: "",
    nearest_air_port_name: "",
    nearest_land_port_code: "",
    nearest_land_port_name: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [quoteDetailsFetched, setQuoteDetailsFetched] = useState([]);
  const [skuListOptions, setskuListOptions] = useState([
    {
      label: "Add SKU",
      value: "Add SKU",
    },
  ]);
  const [selectedSKUListOption, setselectedSKUListOption] = useState({
    label: "",
    value: "",
  });
  console.log(selectedSKUListOption, "selectedSKUListOption");

 
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [showSKUAdd, setShowSKUAdd] = useState(false);
 

  const [submittingPurchaseOrderDetails, setsubmittingPurchaseOrderDetails] =
    useState({
      customer_id: customerId,
      vendor_id: "",
      doc_number: "",
      expected_pickup_date: "",
    });
  const [
    submittingPurchaseOrderDetailsError,
    setsubmittingPurchaseOrderDetailsError,
  ] = useState({
    doc_number: null,
    expected_pickup_date: null,
    skuLength: null,
    assignVendor: null,
  });
  const [submittingPurchaseOrder, setsubmittingPurchaseOrder] = useState(false);
  const [finalPoError, setfinalPoError] = useState(null);
  const [addSKUDetails, setaddSKUDetails] = useState({
    customer_id: customerId,
    item_code: "",
    item_name: "",
    pallet_type: "",
    tax_code: "",
    unit_cost: "",
    boxes_per_pallet: "",
    package_factor: "",
    boxes_per_layer: "",
    pallet_length: "",
    pallet_width: "",
    pallet_height: "",
    c_length: "",
    c_width: "",
    c_height: "",
    item_weight: "",
    minimum_request_qty: "",
    stackable_pallet: "",
    no_of_stack: "",
    capacity: "",
    brand: "",
    brand_classification: "",
    vendor_code: "",
    supplier_country: "",
    item_country: "",
    group_name: "",
    units_per_box: "",
    units_per_pallet: "",
    purchase_status: "",
    sales_status: "",
    inactive_status: "",
    bar_code: "",
    alcohal_grad: "",
    incoterm: "",
    stock_qty: "",
  });
  const [addSKUDetailsError, setaddSKUDetailsError] = useState({
    item_code: null,
    item_name: null,
    pallet_type: null,
    tax_code: null,
    unit_cost: null,
    boxes_per_pallet: null,
    package_factor: null,
    boxes_per_layer: null,
    pallet_length: null,
    pallet_width: null,
    pallet_height: null,
    c_length: null,
    c_width: null,
    c_height: null,
    item_weight: null,
    minimum_request_qty: null,
    stackable_pallet: null,
    no_of_stack: null,
    capacity: null,
    brand: null,
    brand_classification: null,
    vendor_code: null,
    supplier_country: null,
    item_country: null,
    group_name: null,
    units_per_box: null,
    units_per_pallet: null,
    purchase_status: null,
    sales_status: null,
    inactive_status: null,
    bar_code: null,
    alcohal_grad: null,
    incoterm: null,
    stock_qty: null,
  });
  const handleSubmittingPurchaseOrderDetails = (e) => {
    setsubmittingPurchaseOrderDetails({
      ...submittingPurchaseOrderDetails,
      [e.target.id]: e.target.value,
    });
    setsubmittingPurchaseOrderDetailsError({
      ...submittingPurchaseOrderDetailsError,
      [e.target.id]: null,
    });
  };
  

  // submit purchase order function
  const submitPurchaseOrderFn = async () => {
    setfinalPoError(null);
    let assignVendor = !selectedOption ? "Please Assign Vendor" : null;
    let docnumber =
      submittingPurchaseOrderDetails.doc_number?.length == 0
        ? "Please enter PO Number"
        : null;
    let pickUpDate =
      submittingPurchaseOrderDetails.expected_pickup_date.length == 0
        ? "Please select Pickup date"
        : null;
    let skuLength = skuList.length == 0 ? "Please add SKU" : null;
    setsubmittingPurchaseOrderDetailsError({
      expected_pickup_date: pickUpDate,
      assignVendor: assignVendor,
      doc_number: docnumber,
      skuLength: skuLength,
    });
    if (assignVendor || docnumber || pickUpDate || skuLength) {
      return;
    }
    setsubmittingPurchaseOrder(true);
    let vendor_id = vendorListDetailsRaw.find(
      (item) => item?.vendor_name == selectedOption.label
    );

    let data = {
      customer_id: customerId,
      vendor_id: vendor_id?.vendor_id,
      doc_number: submittingPurchaseOrderDetails.doc_number,
      expected_pickup_date: submittingPurchaseOrderDetails.expected_pickup_date,
      quote_id: selectedOptionQuote ? selectedOptionQuote?.value : null,
      orderItems: [],
    };
    let items = skuList?.map((item) => {
      let modified = { ...item };
      delete modified.id;
      return modified;
    });
    data.orderItems = items;
    data["customer_id"] = customerId;
    console.log(data, "data");

    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/po/add`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      setSelectedOptionQuote(null);
      setSelectedOption(null);
      setskuList([]);
      if (response.status == 200) {
        submitPurchaseOrderFnSuccess(response);
      }
    } catch (error) {
      setskuList([]);
      setSelectedOption(null);
      setSelectedOptionQuote(null);
      setsubmittingPurchaseOrder(false);
      setsubmittingPurchaseOrderDetails({
        customer_id: customerId,
        vendor_id: "",
        doc_number: "",
        expected_pickup_date: "",
      });
      alert(error.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  function submitPurchaseOrderFnSuccess(response) {
    setsubmittingPurchaseOrder(false);
    if (!response.data.error && response.data.state == 1) {
      setSelectedOption(null);
      setsubmittingPurchaseOrderDetails({
        customer_id: customerId,
        vendor_id: "",
        doc_number: "",
        expected_pickup_date: "",
      });

      setskuList([]);
      setsuccessMessagePopup(response.data.message);
      handleShowSuccess();
      setTimeout(() => {
        handleCloseSuccess();
      }, 1500);
    } else if (response.data.error && response.data.state == 1) {
      setShowErrModal(true);
      setfinalPoError(response.data.message);
      setsubmittingPurchaseOrderDetails({
        ...submittingPurchaseOrderDetails,
        doc_number: "",
      });
    } else {
      alert(response.data.message);
      setsubmittingPurchaseOrderDetails({
        customer_id: customerId,
        vendor_id: "",
        doc_number: "",
        expected_pickup_date: "",
      });
    }
  }

  const handleCloseSKUAdd = () => {setShowSKUAdd(false);
    setaddSKUDetails({
      customer_id: customerId,
      item_code: "",
      item_name: "",
      pallet_type: "",
      tax_code: "",
      unit_cost: "",
      boxes_per_pallet: "",
      package_factor: "",
      boxes_per_layer: "",
      pallet_length: "",
      pallet_width: "",
      pallet_height: "",
      c_length: "",
      c_width: "",
      c_height: "",
      item_weight: "",
      minimum_request_qty: "",
      stackable_pallet: "",
      no_of_stack: "",
      capacity: "",
      brand: "",
      brand_classification: "",
      vendor_code: "",
      supplier_country: "",
      item_country: "",
      group_name: "",
      units_per_box: "",
      units_per_pallet: "",
      purchase_status: "",
      sales_status: "",
      inactive_status: "",
      bar_code: "",
      alcohal_grad: "",
      incoterm: "",
      stock_qty: "",
    })
    setaddSKUDetailsError({
      item_code: null,
      item_name: null,
      pallet_type: null,
      tax_code: null,
      unit_cost: null,
      boxes_per_pallet: null,
      package_factor: null,
      boxes_per_layer: null,
      pallet_length: null,
      pallet_width: null,
      pallet_height: null,
      c_length: null,
      c_width: null,
      c_height: null,
      item_weight: null,
      minimum_request_qty: null,
      stackable_pallet: null,
      no_of_stack: null,
      capacity: null,
      brand: null,
      brand_classification: null,
      vendor_code: null,
      supplier_country: null,
      item_country: null,
      group_name: null,
      units_per_box: null,
      units_per_pallet: null,
      purchase_status: null,
      sales_status: null,
      inactive_status: null,
      bar_code: null,
      alcohal_grad: null,
      incoterm: null,
      stock_qty: null,
    })
    };
  const handleShowSKUAdd = () => setShowSKUAdd(true);

  const handleClosevendorAdd = () => setShowvendorAdd(false);
  const handleShowvendorAdd = () => setShowvendorAdd(true);
  const handleClosevendorAddSku = () => setShowvendorAddSku(false);
  const handleShowvendorAddSku = () => setShowvendorAddSku(true);
  const [modalData, setmodalData] = useState({
    sku: "",
    qty: "",
    unit_cost: "",
    description: "",
    volume: "",
    pallet: "",
  });
  const [modalDataErrors, setmodalDataErrors] = useState({
    sku: null,
    qty: null,
    unit_cost: null,
    description: null,
    volume: null,
    pallet: null,
  });
  const [skuList, setskuList] = useState([]);

  const handleChange = (option) => {
    setSelectedOption(option);
    setsubmittingPurchaseOrderDetailsError({
      ...submittingPurchaseOrderDetailsError,
      assignVendor: null,
    });
  };
  const handleSkudata = (e) => {
    setmodalData({ ...modalData, [e.target.id]: e.target.value });
    setmodalDataErrors({ ...modalDataErrors, [e.target.id]: null });
  };

  function submitModalSkuDataErrors() {
    let skuError = !selectedSKUListOption ? "Please select SKU" : null;
    let qtyError =
      !modalData?.qty || modalData?.qty == "" ? "Please enter Quantity" : null;
    setmodalDataErrors({ ...modalDataErrors, sku: skuError, qty: qtyError });
    return skuError || qtyError;
  }

  const submitModalSkuData = () => {
    if (submitModalSkuDataErrors()) {
      console.log("entered");
      return;
    }

    function checkFnForSku(property) {
      return !modalData[property] || modalData[property].length == ""
        ? null
        : parseFloat(modalData[property]);
    }

    let qty = checkFnForSku("qty");
    let volume = checkFnForSku("volume");
    let pallet = checkFnForSku("pallet");
    let unit_cost = checkFnForSku("unit_cost");
    if (skuList.length == 0) {
      setskuList([{ ...modalData, id: 1, qty, volume, pallet, unit_cost }]);
      setsubmittingPurchaseOrderDetailsError({
        ...submittingPurchaseOrderDetailsError,
        skuLength: null,
      });
    } else {
      if (modalData?.id) {
        setskuList((prev) =>
          prev.map((item) => {
            if (item.id == modalData?.id) {
              return { ...item, ...modalData, qty, volume, pallet, unit_cost };
            } else {
              return item;
            }
          })
        );
      } else {
        let skuListData = { ...modalData, qty, volume, pallet, unit_cost };
        skuListData["id"] = skuList[skuList.length - 1].id + 1;
        setskuList([...skuList, skuListData]);
      }
      setsubmittingPurchaseOrderDetailsError({
        ...submittingPurchaseOrderDetailsError,
        skuLength: null,
      });
    }
    handleClosevendorAddSku();
    setmodalData({
      sku: "",
      qty: "",
      unit_cost: "",
      description: "",
      volume: "",
      pallet: "",
    });
  };

  //

  useEffect(() => {
    if (!selectedOptionCountry) {
      setSelectedOptionPort(null);
    }
    if (selectedOptionCountry && allCountries?.length > 0) {
      let countryCode = getCountryCode(allCountries, selectedOptionCountry);
      getPortDetails(countryCode, setoptionsForPorts, null, navigate);
    }
  }, [selectedOptionCountry, allCountries?.length]);
  useEffect(() => {
    if (selectedOption?.label == "Add Vendor") {
      setaddNewVendorDetails({
        customer_id: customerId,
        company_name: "",
        origin_country: "",
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        communication_mode: "",
        nearest_sea_port_code: "",
        nearest_sea_port_name: "",
        nearest_air_port_code: "",
        nearest_air_port_name: "",
        nearest_land_port_code: "",
        nearest_land_port_name: "",
      });
      setselectedOptionCountry(null);
      setSelectedOptionPort(null);
      handleShowvendorAdd();
    }
  }, [selectedOption?.label]);
  useEffect(() => {
    if (selectedSKUListOption) {
      if (selectedSKUListOption?.label == "Add SKU") {
        handleShowSKUAdd();
      } else {
        let data = skuListDetails?.find(
          (item) => item.item_code == selectedSKUListOption.label
        );
        setmodalData({
          ...modalData,
          sku: selectedSKUListOption.label ? selectedSKUListOption.label : "",
          unit_cost: data?.unit_cost,
          description: data?.description,
        });
      }
    } else {
      setmodalData({
        sku: "",
        qty: "",
        unit_cost: "",
        description: "",
        volume: "",
        pallet: "",
      });
    }
  }, [selectedSKUListOption?.label]);
  useEffect(() => {
    getCountriesFn(
      setoptionsForCountries,
      setallCountries,
      navigate,
      customerId
    );
    if (customerId) {
      console.log(customerId, "apo page customerId");
      !vendorListDetailsRaw?.length &&
        getVendorList(
          customerId,
          setoptionsModified,
          setvendorListDetailsRaw,
          null,
          navigate
        );
      !skuListDetails.length &&
        getSKUList(
          customerId,
          setskuListDetails,
          null,
          navigate,
          setskuListOptions
        );
      !quoteDetailsFetched.length &&
        getQuotes(
          customerId,
          "apo",
          setoptionsModifiedQuote,
          setQuoteDetailsFetched,
          navigate,
          {}
        );
    }
  }, []);
  useEffect(() => {
    if (location.state) {
      setSelectedOption({
        label: location.state.vendor_name,
        value: location.state.vendor_name,
      });
    }
  }, [location.state]);
  console.log(quoteDetailsFetched, "quote details fetched");

  return (
    <>
      <div className="">
        <div className="py-3">
          <button
            type="button"
            className="back-btn d-flex alig-items-center"
            onClick={() => {
              navigate(`/${customerId}`, { state: location?.state });
            }}
          >
            <svg className="icon me-2 fs-22">
              <use href="#icon_leftarrow"></use>
            </svg>
            <strong className="fs-14">Back</strong>
          </button>
        </div>
        <div className="content-wrapper">
          <h2 className="fw-700 fs-24 text-center pb-4">Add Purchase Order</h2>
          <div className="mb-3">
            {selectedOption && <label className="mb-1 fw-600">Vendor *</label>}

            <Select
              value={selectedOption}
              onChange={handleChange}
              options={optionsModified}
              isSearchable={true}
              placeholder="Assign Vendor"
              isClearable
              isLoading={!optionsModified?.length}
            />
          </div>
          {submittingPurchaseOrderDetailsError.assignVendor && (
            <div className="text-danger mb-3">
              {submittingPurchaseOrderDetailsError.assignVendor}
            </div>
          )}
          <div className="input-box mb-3">
            {submittingPurchaseOrderDetails.doc_number &&
              submittingPurchaseOrderDetails.doc_number != "" && (
                <label className="fw-600">PO Number *</label>
              )}
            <input
              type="text"
              id="doc_number"
              onChange={handleSubmittingPurchaseOrderDetails}
              value={submittingPurchaseOrderDetails.doc_number}
              className="w-100"
              placeholder="PO Number*"
            />
          </div>
          {submittingPurchaseOrderDetailsError.doc_number && (
            <div className="text-danger mb-3">
              {submittingPurchaseOrderDetailsError.doc_number}
            </div>
          )}
          <div className="input-box mb-3">
            <label className="fw-600">Pick up date *</label>
            <input
              type="date"
              id="expected_pickup_date"
              onChange={handleSubmittingPurchaseOrderDetails}
              value={submittingPurchaseOrderDetails.expected_pickup_date}
              className="w-100"
            />
          </div>
          {submittingPurchaseOrderDetailsError.expected_pickup_date && (
            <div className="text-danger mb-3">
              {submittingPurchaseOrderDetailsError.expected_pickup_date}
            </div>
          )}

          <div className="mb-3">
            {selectedOptionQuote && (
              <label className="mb-1 fw-600">Assign Quote</label>
            )}
            <Select
              value={selectedOptionQuote}
              onChange={(option) => {
                setSelectedOptionQuote(option);
              }}
              options={optionsModifiedQuote}
              isSearchable={true}
              placeholder="Assign Quote"
              isClearable
              isLoading={!optionsModifiedQuote?.length}
            />
          </div>
          <div className="ms-auto d-flex">
            <button
              type="button"
              className="grd-btn rounded-pill"
              onClick={() => {
                handleShowvendorAddSku();
                setselectedSKUListOption(null);
              }}
            >
              Add SKU
            </button>
          </div>
          {skuList?.length > 0 && (
            <div className="w-100 overflow-auto sku-tbl">
              <table class="table text-center my-4 border">
                <thead>
                  <tr className="border" style={{ background: "#EBEEFF" }}>
                    <th>SKU</th>
                    <th>Description</th>
                    <th>Quantity(pcs)</th>
                    <th>Volume CBM</th>
                    <th># Pallets</th>
                    <th>Unit Cost</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {skuList?.map((item, index) => (
                    <tr key={item?.sku}>
                      <td>{item?.sku}</td>
                      <td>{item?.description}</td>
                      <td>{item?.qty}</td>
                      <td>{item?.volume}</td>
                      <td>{item?.pallet}</td>
                      <td>{item?.unit_cost}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <button
                            type="button"
                            className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                            onClick={() => {
                              setmodalData({
                                sku: item.sku,
                                id: item.id,
                                description: item.description,
                                volume: item.volume,
                                qty: item.qty ? item.qty?.toString() : "",
                                unit_cost: item.unit_cost,
                                pallet: item.pallet,
                              });
                              setselectedSKUListOption({
                                label: item.sku,
                                value: item.sku,
                              });
                              handleShowvendorAddSku();
                            }}
                          >
                            <svg className="icon ms-2 fs-22">
                              <use href="#icon_edit"></use>
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {submittingPurchaseOrderDetailsError.skuLength && (
            <div className="text-danger mb-3">
              {submittingPurchaseOrderDetailsError.skuLength}
            </div>
          )}

          <div className="d-flex">
            <div className="ms-auto">
              <button
                className={`grd-btn rounded-pill mt-5`}
                disabled={submittingPurchaseOrder}
                onClick={submitPurchaseOrderFn}
              >
                {submittingPurchaseOrder ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Vendor Modal */}

      <AddVendorModal
        showvendorAdd={showvendorAdd}
        handleClosevendorAdd={handleClosevendorAdd}
        addNewVendorDetails={addNewVendorDetails}
        setaddNewVendorDetails={setaddNewVendorDetails}
        optionsForCountries={optionsForCountries}
        optionsForPorts={optionsForPorts}
        selectedOptionCountry={selectedOptionCountry}
        setselectedOptionCountry={setselectedOptionCountry}
        selectedOptionPort={selectedOptionPort}
        setSelectedOption={setSelectedOption}
        setSelectedOptionPort={setSelectedOptionPort}
        setoptionsForPorts={setoptionsForPorts}
        customerId={customerId}
        getVendorList={() =>
          !vendorListDetailsRaw.length &&
          getVendorList(
            customerId,
            setoptionsModified,
            setvendorListDetailsRaw,
            null,
            navigate
          )
        }
        setvendorListDetailsRaw={setvendorListDetailsRaw}
      />
      <Modal
        show={showvendorAddSku}
        centered
        onHide={() => {
          handleClosevendorAddSku();
          setselectedSKUListOption(null);
          setmodalData({
            sku: "",
            qty: "",
            unit_cost: "",
            description: "",
            volume: "",
            pallet: "",
          });
          setmodalDataErrors({
            sku: null,
            qty: null,
            unit_cost: null,
            description: null,
            volume: null,
            pallet: null,
          });
        }}
        scrollable={true}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-4">
          <div className="mb-3">
            {selectedSKUListOption && <label className="mb-1">SKU *</label>}
            <Select
              value={selectedSKUListOption}
              onChange={(option) => {
                setselectedSKUListOption(option);
                setmodalDataErrors({
                  sku: null,
                  qty: null,
                  unit_cost: null,
                  description: null,
                  volume: null,
                  pallet: null,
                });
              }}
              options={skuListOptions}
              isSearchable={true}
              placeholder="Select SKU*"
              isClearable
              isLoading={!skuListOptions.length}
            />
          </div>

          {modalDataErrors.sku && (
            <div className="text-danger mb-3">{modalDataErrors.sku}</div>
          )}

          <div className="input-box mb-3">
            {modalData.description && modalData.description != "" && (
              <label>Description *</label>
            )}
            <input
              type="text"
              id="description"
              disabled
              onChange={handleSkudata}
              value={modalData.description}
              className="w-100"
              placeholder="Description*"
            />
          </div>

          <div className="input-box mb-3">
            {modalData.unit_cost && modalData.unit_cost != "" && (
              <label>Unit Cost *</label>
            )}
            <input
              type="number"
              id="unit_cost"
              disabled
              onChange={handleSkudata}
              value={modalData.unit_cost}
              className="w-100"
              placeholder="Unit Cost*"
            />
          </div>
          <div className="input-box mb-3">
            {modalData.qty && modalData.qty != "" && (
              <label>Quantity(pcs) *</label>
            )}
            <input
              type="number"
              id="qty"
              onChange={handleSkudata}
              value={modalData.qty}
              className="w-100"
              placeholder="Quantity(pcs)*"
            />
          </div>
          {modalDataErrors.qty && (
            <div className="text-danger mb-3">{modalDataErrors.qty}</div>
          )}
          <div className="input-box mb-3">
            {modalData.volume && modalData.volume != "" && (
              <label>Volume CBM</label>
            )}
            <input
              type="number"
              id="volume"
              onChange={handleSkudata}
              value={modalData.volume}
              className="w-100"
              placeholder="Volume CBM"
            />
          </div>
          <div className="input-box mb-3">
            {modalData.pallet && modalData.pallet != "" && (
              <label># Pallets</label>
            )}
            <input
              type="number"
              id="pallet"
              onChange={handleSkudata}
              value={modalData.pallet}
              className="w-100"
              placeholder="# Pallets"
            />
          </div>

          <div className="d-flex">
            <div className="ms-auto">
              <button
                className="grd-btn rounded-pill"
                onClick={submitModalSkuData}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Add SKU Modal*/}

      <AddSkuModal
        addSKUDetails={addSKUDetails}
        addSKUDetailsError={addSKUDetailsError}
        setaddSKUDetails={setaddSKUDetails}
        setaddSKUDetailsError={setaddSKUDetailsError}
        customerId={customerId}
        getSKUList={() =>
          !skuListDetails.length &&
          getSKUList(
            customerId,
            setskuListDetails,
            null,
            navigate,
            setskuListOptions
          )
        }
        handleCloseSKUAdd={handleCloseSKUAdd}
        showSKUAdd={showSKUAdd}
        setselectedSKUListOption={setselectedSKUListOption}
        setmodalData={setmodalData}
      />
      <SuccessModal
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        successMessagePopup={successMessagePopup}
      />
      <Modal show={showErrModal} onHide={() => setShowErrModal(false)} centered>
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center text-danger pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <h3>Error</h3>
          <h4>{finalPoError}</h4>
          <div className="d-flex justify-content-center  text-center mt-2">
            <Button
              variant="primary"
              className=""
              onClick={() => setShowErrModal(false)}
            >
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Apo;
