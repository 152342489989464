import {React} from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import NavBar from '../components/NavBar'


function Selectcard() {

  return (
    <>
    <NavBar/>
    <Container>
      <Row>
        <Col md={12}>
          <div className='wrapper-box d-flex justify-content-around'>
            <button type='button' className='card-btn'>
              <div className='card-box d-flex align-items-center justify-content-center'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <div className='icon-box'>
                      <svg className="icon fs-50">
                        <use href="#icon_opportunity"></use>
                      </svg>
                    </div>
                  </div>
                  <p className='fw-700 fs-20 text-center py-4'>Opportunity</p>
                </div>
              </div>
            </button>

            <button type='button' className='card-btn'>
              <div className='card-box d-flex align-items-center justify-content-center'>
                <div>
                  <div className='d-flex justify-content-center'>
                    <div className='icon-box'>
                      <svg className="icon fs-50">
                        <use href="#icon_opportunity"></use>
                      </svg>
                    </div>
                  </div>
                  <p className='fw-700 fs-20 text-center py-4'>CRM</p>
                </div>
              </div>
            </button>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Selectcard