import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { DeleteVerificationModal } from "./DeleteVerificationModal";
function AssignedUsersModal({
  showAssigned,
  handleCloseAssigned,
  assinedUsersList,
  customer_id,
  organization_name,
  setIsLoading,
  setCustomersList,
  setSearchInput,
  setSearchedCustomers,
  setAssignedList,
}) {
  const [submittingAssignNew, setsubmittingAssignNew] = useState(false);

  const [userList, setUserList] = useState([]);
const [showDeleteUser, setShowDeleteUser] = useState(false)
const [selectedUser, setSelectedUser] = useState()
const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false)
  const navigate = useNavigate();
  function handleCloseDelete() {
    setShowDeleteUser(false)
    setSelectedUser()
  }
  async function fetchUserList() {
    const token = localStorage.getItem("mtm_user_token");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/user/assign`,
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setUserList(response?.data?.users);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("Error while Fetching User List");
      console.log(error.response.status);
    }
  }

  async function assignUser(userId, customerId) {
    console.log(userId);
    if (userId == "") return;

    setsubmittingAssignNew(true);
    const token = localStorage.getItem("mtm_user_token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/assign/update`,
        {
          customer_id: customerId,
          user_id: userId,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        let user = userList.find((user) => user.id == userId);
        setCustomersList((pre) => {
          return pre.map((customer) => {
            if (customer.customer_id == customer_id) {
              return { ...customer, ops_users: [...customer.ops_users, user] };
            }
            return customer;
          });
        });
        setSearchedCustomers((pre) => {
          return pre.map((customer) => {
            if (customer.customer_id == customer_id) {
              return { ...customer, ops_users: [...customer.ops_users, user] };
            }
            return customer;
          });
        });
        setAssignedList((pre) => [...pre, user]);
        setIsLoading(false);
        // handleCloseAssigned()
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      setSearchInput("");
      alert("Error while assigning user");
      setIsLoading(false);
      console.log(error);
    } finally {
      setsubmittingAssignNew(false);
    }
  }
  async function unAssignUser(customerId, id) {
    console.log(id);
    if ( !id) return;

    setsubmittingAssignNew(true);
    setIsLoadingDeleteUser(true);
    const token = localStorage.getItem("mtm_user_token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/user_unassign`,
        {
          customer_id: customerId,
          user_id: id,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setCustomersList((pre) => {
          return pre.map((customer) => {
            if (customer.customer_id == customer_id) {
              return {
                ...customer,
                ops_users: customer.ops_users?.filter((u) => u.id != id),
              };
            }
            return customer;
          });
        });
        setSearchedCustomers((pre) => {
          return pre.map((customer) => {
            if (customer.customer_id == customer_id) {
              return {
                ...customer,
                ops_users: customer.ops_users?.filter((u) => u.id != id),
              };
            }
            return customer;
          });
        });
        setAssignedList((pre) => pre?.filter(u=>u.id != id));
        setIsLoading(false);
        // handleCloseAssigned()
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      setSearchInput("");
      alert("Error while assigning user");
      setIsLoading(false);
      console.log(error);
    } finally {
      setsubmittingAssignNew(false);
      setIsLoadingDeleteUser(false);
      handleCloseDelete()
    }
  }

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <>
    <Modal
      size="xl"
      show={showAssigned}
      centered
      onHide={handleCloseAssigned}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4 ">
        <p className="text-center fs-26 fw-bold pb-2">
          Assigned Users Of ({organization_name})
        </p>
        <form>
          <div className="mb-2 d-flex justify-content-center">
            <div className="w-50">
              <Select
                onChange={(option) => assignUser(option?.value, customer_id)}
                options={userList?.map((user) => ({
                  label: `${user?.user_full_name} `,
                  value: user?.id,
                }))}
                isOptionDisabled={(option) =>
                  assinedUsersList?.find((e) => e.id == option.value)
                }
                isSearchable={true}
                placeholder="Assign User"
              />
            </div>
          </div>
          <div
            style={{ minHeight: "25vh" }}
            className="w-100  overflow-auto vendor-tbl"
          >
            {
              <table class="table text-center my-4 border">
                <thead>
                  <tr className="border" style={{ background: "#EBEEFF" }}>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th></th>
                    
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "100%", overflowX: "auto" }}>
                  {assinedUsersList?.map((item) => (
                    <tr key={item?.user_full_name + item?.id + "assigned"}>
                      <td>{item?.user_full_name}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone}</td>
                      <td><button className="py-1 mx-2 border-0 bg-body grd-btn rounded-pill" onClick={(e)=>{
                        e.preventDefault()
                        setSelectedUser(item)
                        setShowDeleteUser(true)
                       }}>
                      Unassign
                       </button></td>

                    </tr>
                  ))}
                </tbody>
              </table>
            }
            {!assinedUsersList.length ? (
              <h4
                style={{
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                No Users Assigned
              </h4>
            ) : null}
            {submittingAssignNew && <Loader />}
          </div>
        </form>
      </Modal.Body>
      
      
      
    </Modal>
    <DeleteVerificationModal handleCloseDelete={handleCloseDelete} handleDelete={()=>unAssignUser(customer_id,selectedUser?.id)} isLoadingDeleteUser={isLoadingDeleteUser} showDeleteUser={showDeleteUser} />
    </>
  );
}

export default AssignedUsersModal;
