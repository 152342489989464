import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col, Modal, Button, Accordion } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Drawer } from "antd";
import axios from "axios";
import * as XLSX from "xlsx";
import { CustomerContext } from "../context/customerContext";
import Loader from "../components/Loader";
import Select from "react-select";
import { CustomIcon } from "../components/Common";
import moment from "moment/moment";
import {
  conditionalExp,
  getCarrier,
  getCountriesFn,
  getFormattedDate,
  getPortDetails,
  getQuotes,
  getSKUListHome,
  getTagCalculationList,
  getTagSKUList,
  getVendorList,
  getWarehouseList,
  syncWarehouseFromSap,
} from "../utils/commonFn";
import { SuccessModal } from "../components/SuccessModal";
import { AddBookingModal } from "../components/AddBookingModal";
import AddSkuModal from "../components/AddSkuModal";
import AddVendorModal from "../components/AddVendorModal";
import AddWarehouseModal from "../components/AddWarehouseModal";
import AddTagRangeModal from "../components/AddTagRangeModal";
import AddDestroyedTagModal from "../components/AddDestroyedTagsModal";
import SkuFootprint from "./SKU_cost_footprint";
const { RangePicker } = DatePicker;
function Home() {
  const { state } = useLocation();
  const { customerId } = useParams();
  const previousState = state?.previous;
  console.log(previousState, "previous state");
  const [isLoadingAgentChange, setisLoadingAgentChange] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [successMessagePopup, setsuccessMessagePopup] = useState("");
  const [showAddBookingModal, setShowAddBookingModal] = useState(false);
  const [selectedOption, setselectedOption] = useState();
  const [drVisible, setDrVisible] = useState(false);
  const [arrivedContainers, setArrivedContainers] = useState([]);
  const [errormsg, setErrormsg] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [selectedDate, setselectedDate] = useState([]);
  const [selectedDateTagList, setselectedDateTagList] = useState([]);
  const [tagCalculationList, setTagCalculationList] = useState([]);
  const [isLoadingTagCalList, setIsLoadingTagCalList] = useState(false);
  const [tagCalculationSKUList, setTagCalculationSKUList] = useState([]);
  const [isLoadingTagCalSKU, setIsLoadingTagCalSKU] = useState(false);
  const [selectedProductType, setselectedProductType] = useState("");
  const [quoteDetailsFetched, setQuoteDetailsFetched] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState("");
  const [clickupData, setClickupData] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [dataFetchedPillsDocuments, setdataFetchedPillsDocuments] =
    useState(true);
  const [dataFetchedPillsQuotes, setdataFetchedPillsQuotes] = useState(true);
  const [dataFetchedPillsTracking, setdataFetchedPillsTracking] =
    useState(true);
  const [dataFetchedPillsShipDocs, setdataFetchedPillsShipDocs] =
    useState(true);
  const [sendingMail, setsendingMail] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileUploadingSKU, setIsFileUploadingSKU] = useState(false);
  const [showimgpop, setShowimgpop] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [filteredPoList, setFilteredPoList] = useState([]);
  const [uploadOption, setUploadOption] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [isLoadingWarehoseSync, setIsLoadingWarehoseSync] = useState(false);
  const [shippingDocList, setShippingDocList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState();
  const [selectedBookingid, setSelectedBookingid] = useState();
  const [submittingBrokerData, setsubmittingBrokerData] = useState(false);
  const [selectedBrokerId, setSelectedBrokerId] = useState();
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [rawFileSKU, setrawFileSKU] = useState(null);
  const [previewUrlSKU, setpreviewUrlSKU] = useState(null);
  const [agentsList, setAgentsList] = useState([]);
  const [showVendor, setShowVendor] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [selectedDocNumber, setSelectedDocNumber] = useState();
  const [contactsData, setContactsData] = useState();
  const [designation, setDesignation] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [contact_email, setContact_email] = useState("");
  const [contact_phone, setContact_phone] = useState("");
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedPoItems, setSelectedPoItems] = useState([]);
  const [showShippingDocModal, setShowShippingDocModal] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [coo, setCoo] = useState(""); //country of origin
  const [cooOptions, setCooOptions] = useState([]);
  const [statusF, setstatusF] = useState("");
  const [statusFOptions, setstatusFOptions] = useState([]);
  const [isLoadingVendorChange, setisLoadingVendorChange] = useState(false);
  const [isLoadingDelete, setisLoadingDelete] = useState(false);
  const [selecteQuote, setSelecteQuote] = useState();
  const [isLoadingAddQuote, setIsLoadingAddQuote] = useState(false);
  const [isOrgEdit, setisOrgEdit] = useState();
  const [agentData, setAgentData] = useState({
    clickup_product_id: "",
    doc_number: "",
    clickup_task_id: "",
  });
  const [bookingAgentsWithCostArray, setbookingAgentsWithCostArray] = useState(
    []
  );
  const [showErrModal, setShowErrModal] = useState(false);
  const [isExportingToExel, setIsExportingToExel] = useState(false);
  const [showErrModal2, setShowErrModal2] = useState(false);
  const [finalPoError, setfinalPoError] = useState(false);
  const [error, setError] = useState();
  const [allModals, setallModals] = useState([]);
  const [vendorListDetails, setvendorListDetails] = useState([]);
  const [warehouseListDetails, setwarehouseListDetails] = useState([]);
  const [isgettingwarehouselist, setIsgettingwarehouselist] = useState(false);
  const [vendorListDetailsRaw, setvendorListDetailsRaw] = useState([]);
  const [warehouseListDetailsRaw, setwarehouseListDetailsRaw] = useState([]);
  const [skuListDetails, setskuListDetails] = useState([]);
  const [skuListDetailsRaw, setskuListDetailsRaw] = useState([]);
  const [addNewVendorDetails, setaddNewVendorDetails] = useState({
    customer_id: customerId,
    company_name: "",
    origin_country: "",
    contact_1_name: "",
    contact_2_name: "",
    contact_1_email: "",
    contact_2_email: "",
    contact_phone: "",
    communication_mode: "",
    nearest_sea_port_code: "",
    nearest_sea_port_name: "",
    nearest_air_port_code: "",
    nearest_air_port_name: "",
    nearest_land_port_code: "",
    nearest_land_port_name: "",
  });
  const [addNewWarehouseDetails, setaddNewWarehouseDetails] = useState({
    customer_id: customerId,
    company_name: "",
    warehouse_code: "",
    warehouse_name: "",
    city: "",
    state: "",
    country: "",
    zip_cdoe: "",
  });
  const [allBrokers, setallBrokers] = useState([]);
  const [showvendorAdd, setShowvendorAdd] = useState(false);
  const [showwarehouseAdd, setShowwarehouseAdd] = useState(false);
  const [showSKUAdd, setShowSKUAdd] = useState(false);
  const [showAddAgentsAdd, setShowAddAgentsAdd] = useState(false);
  const [showModalSKUBULK, setshowModalSKUBULK] = useState(false);
  const [vendorSearchInput, setVendorSearchInput] = useState("");
  const [selectedOptionCountry, setselectedOptionCountry] = useState(null);
  const [selectedOptionPort, setSelectedOptionPort] = useState(null);
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const [optionsForPorts, setoptionsForPorts] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [modalselected, setmodalselected] = useState({});

  const [addAddNewCostDetails, setaddAddNewCostDetails] = useState({
    agent_name: "",
    cost: "",
  });
  const [addAddNewCostDetailsError, setaddAddNewCostDetailsError] = useState({
    agent_name: null,
    cost: null,
  });
  const [addNewCostOptions, setaddNewCostOptions] = useState([]);
  const [selectedAgentNameFromModal, setselectedAgentNameFromModal] =
    useState(null);

  //range modal
  const [showrange, setShowrange] = useState(false);

  const handleCloserange = () => setShowrange(false);
  const handleShowrange = () => setShowrange(true);
  //

  //destroy modal
  const [showdestroy, setShowdestroy] = useState(false);

  const handleClosedestroy = () => setShowdestroy(false);
  const handleShowdestroy = () => setShowdestroy(true);

  const handleClosevendorAdd = () => setShowvendorAdd(false);
  const handleShowvendorAdd = () => setShowvendorAdd(true);
  const handleClosewarehouseAdd = () => setShowwarehouseAdd(false);
  const handleShowwarehouseAdd = () => setShowwarehouseAdd(true);
  const handleCloseSKUAdd = () => {setShowSKUAdd(false);
    setaddSKUDetails({
      customer_id: customerId,
      item_code: "",
      item_name: "",
      pallet_type: "",
      tax_code: "",
      unit_cost: "",
      boxes_per_pallet: "",
      package_factor: "",
      boxes_per_layer: "",
      pallet_length: "",
      pallet_width: "",
      pallet_height: "",
      c_length: "",
      c_width: "",
      c_height: "",
      item_weight: "",
      minimum_request_qty: "",
      stackable_pallet: "",
      no_of_stack: "",
      capacity: "",
      brand: "",
      brand_classification: "",
      vendor_code: "",
      supplier_country: "",
      item_country: "",
      group_name: "",
      units_per_box: "",
      units_per_pallet: "",
      purchase_status: "",
      sales_status: "",
      inactive_status: "",
      bar_code: "",
      alcohal_grad: "",
      incoterm: "",
      stock_qty: "",
    })
    setaddSKUDetailsError({
      item_code: null,
      item_name: null,
      pallet_type: null,
      tax_code: null,
      unit_cost: null,
      boxes_per_pallet: null,
      package_factor: null,
      boxes_per_layer: null,
      pallet_length: null,
      pallet_width: null,
      pallet_height: null,
      c_length: null,
      c_width: null,
      c_height: null,
      item_weight: null,
      minimum_request_qty: null,
      stackable_pallet: null,
      no_of_stack: null,
      capacity: null,
      brand: null,
      brand_classification: null,
      vendor_code: null,
      supplier_country: null,
      item_country: null,
      group_name: null,
      units_per_box: null,
      units_per_pallet: null,
      purchase_status: null,
      sales_status: null,
      inactive_status: null,
      bar_code: null,
      alcohal_grad: null,
      incoterm: null,
      stock_qty: null,
    })
    };
  const handleShowSKUAdd = () => setShowSKUAdd(true);
  const handleCloseAddAgentsAdd = () => setShowAddAgentsAdd(false);
  const handleShowAddAgentsAdd = () => setShowAddAgentsAdd(true);
  const handleCloseSKUBULKAdd = () => {
    setshowModalSKUBULK(false);
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };
  const handleShowSKUBULKAdd = () => {
    setshowModalSKUBULK(true);
  };
  const [documentNameError, setdocumentNameError] = useState(null);

  const [addSKUDetails, setaddSKUDetails] = useState({
    customer_id: customerId,
    item_code: "",
    item_name: "",
    pallet_type: "",
    tax_code: "",
    unit_cost: "",
    boxes_per_pallet: "",
    package_factor: "",
    boxes_per_layer: "",
    pallet_length: "",
    pallet_width: "",
    pallet_height: "",
    c_length: "",
    c_width: "",
    c_height: "",
    item_weight: "",
    minimum_request_qty: "",
    stackable_pallet: "",
    no_of_stack: "",
    capacity: "",
    brand: "",
    brand_classification: "",
    vendor_code: "",
    supplier_country: "",
    item_country: "",
    group_name: "",
    units_per_box: "",
    units_per_pallet: "",
    purchase_status: "",
    sales_status: "",
    inactive_status: "",
    bar_code: "",
    alcohal_grad: "",
    incoterm: "",
    stock_qty: "",
  });
  const [addSKUDetailsError, setaddSKUDetailsError] = useState({
    item_code: null,
    item_name: null,
    pallet_type: null,
    tax_code: null,
    unit_cost: null,
    boxes_per_pallet: null,
    package_factor: null,
    boxes_per_layer: null,
    pallet_length: null,
    pallet_width: null,
    pallet_height: null,
    c_length: null,
    c_width: null,
    c_height: null,
    item_weight: null,
    minimum_request_qty: null,
    stackable_pallet: null,
    no_of_stack: null,
    capacity: null,
    brand: null,
    brand_classification: null,
    vendor_code: null,
    supplier_country: null,
    item_country: null,
    group_name: null,
    units_per_box: null,
    units_per_pallet: null,
    purchase_status: null,
    sales_status: null,
    inactive_status: null,
    bar_code: null,
    alcohal_grad: null,
    incoterm: null,
    stock_qty: null,
  });
  const [isloading, setIsloading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      console.log("intersecting");
      if (isloading) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isloading, hasMore]
  );

  const xlsxExport = (filename, tableId) => {
    const htmlTable = document.getElementById(tableId);

    if (htmlTable) {
        const ws = XLSX.utils.table_to_sheet(htmlTable);

        // Loop through each cell to apply the centering style
        for (const cell in ws) {
            if (ws.hasOwnProperty(cell)) {
                // Skip header cells (e.g., A1, B1, etc.)
                if (cell.match(/[A-Z]+1$/)) {
                    continue;
                }

                const cellRef = XLSX.utils.decode_cell(cell);
                const cellStyle = ws[cellRef.r] && ws[cellRef.r][cellRef.c]
                    ? Object.assign({}, ws[cellRef.r][cellRef.c], { alignment: { horizontal: 'center', vertical: 'center' } })
                    : { alignment: { horizontal: 'center', vertical: 'center' } };
                
                ws[cellRef.r] = ws[cellRef.r] || [];
                ws[cellRef.r][cellRef.c] = cellStyle;
            }
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Generate the Excel file
        const excelBlob = new Blob([s2ab(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // Create a downloadable link
        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filename}.xlsx`;
        link.click();

        // Clean up the object URL
        URL.revokeObjectURL(url);
        // setModeexportbtn(false)
    }
};// Convert string to ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
};

  console.log(previousState, "previous satae");
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const checkRxType = (file) => {
    let rawFilee = file ? file : rawFile;
    if (!rawFilee) {
      return (
        <img
          src={previewUrl}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFilee?.name?.split(".");
    return getTypeDoc(previewUrl, type);
  };
  const checkRxTypeSKU = () => {
    if (!rawFileSKU) {
      return (
        <img
          src={previewUrlSKU}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFileSKU?.name?.split(".");
    return getTypeDoc(previewUrlSKU, type);
  };
  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    console.log(type, "type ");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };

  const handleClose = () => {
    setUploadOption("");
    setSelectedDoc();
    setSelectedBookingid();
    setShow(false);
    setDocumentName("");
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };

  const handleShow = (doc) => {
    if (doc) {
      setUploadOption(doc.document_type);
      setSelectedDoc(doc);
      console.log("doc selected", doc);
    }
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
    setShow(true);
  };

  const handleCloseShippingModal = () => {
    setSelectedBookingid();
    setShowShippingDocModal(false);
    setDocumentName("");
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };
  const handleShowShippingModal = (booking_id) => {
    setShowShippingDocModal(true);
  };
  const handleCloseSuccess = () => {
    setShowSuccess(false);
    setsuccessMessagePopup("");
  };
  const handleShowSuccess = () => setShowSuccess(true);
  const handleClose2 = () => {
    setShowAddBookingModal(false);
    setErrormsg("");
    setselectedOption();
    setSelecteQuote();
  };
  const handleClose3 = () => {
    setShowMemberModal(false);
    setDesignation("");
    setContact_name("");
    setContact_email("");
    setContact_phone("");
    setselectedOption("");
    setisOrgEdit();
  };
  const handleShow2 = () => {
    setShowAddBookingModal(true);
  };
  const handleShow3 = (desig) => {
    if (desig == "approver") {
      setDesignation("Demand Planning");
      setselectedOption({ label: "Approver", value: "approver" });
    } else if (desig == "new_item_manager") {
      setDesignation("Demand Planning");
      setselectedOption({
        label: "New Item Manager",
        value: "new_item_manager",
      });
    } else {
      setDesignation(desig);
    }
    setShowMemberModal(true);
  };
  const [ShowCustomBrokerModal, setShowCustomBrokerModal] = useState(false);
  const [showQuoteSelectModal, setShowQuoteSelectModal] = useState(false);
  const handleCloseVendor = () => setShowVendor(false);
  const handleShowVendor = () => setShowVendor(true);
  const handleCloseVendorModal = () => setShowVendorModal(false);
  const handleCloseQuoteSelectModal = () => {
    setShowQuoteSelectModal(false);
    setSelecteQuote();
  };
  const handleShowVendorModal = () => setShowVendorModal(true);
  const handleCloseCustomBrokerModal = () => setShowCustomBrokerModal(false);
  const handleShowCustomBrokerModal = () => setShowCustomBrokerModal(true);
  const tagCalculationTblRef = useRef();
  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedDoc();
    setisOrgEdit();
  };
  const handleShowDelete = (doc) => {
    setShowDelete(true);
    setSelectedDoc(doc);
  };
  const dateFormat = "DD-MM-YYYY";
  const { customer } = useContext(CustomerContext);
  const navigate = useNavigate();

  const handleChangeFileFun = (e) => {
    for (let file of e.target.files) {
      console.log(file);
      if (file && file.size > MAX_FILE_SIZE) {
        setfileUploadLimitRxError(true);
        return;
      }
    }
    setfileUploadLimitRxError(false);
    setrawFile(Object.values(e.target.files));
  };
  const handleChangeFileFunSKU = (e) => {
    let file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      setfileUploadLimitRxError(true);
      return;
    }
    setfileUploadLimitRxError(false);
    setrawFileSKU(file);
  };

  const getCreatedDate = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - createdAtDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    let color = "";
    if (hoursDifference <= 24) {
      color = "green-bottom";
    } else if (hoursDifference <= 48) {
      color = "yellow-bottom";
    } else {
      color = "maroon-bottom";
    }

    let sinceText = "";
    if (daysDifference > 1) {
      sinceText = `(Since ${daysDifference} days)`;
    } else {
      sinceText = `(Since ${daysDifference} day)`;
    }

    const formattedDate = `${createdAtDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(createdAtDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${createdAtDate.getFullYear()}`;
    return {
      formattedDate,
      daysDifference,
      sinceText,
      color,
    };
  };

  const getAgentName = (broker_id) => {
    if (broker_id) {
      let item = addNewCostOptions?.find((item) => item.value == broker_id);
      // console.log(addNewCostOptions,"addNewCostOptions")
      // console.log(item,"item")
      return item?.label;
    } else {
      return "";
    }
  };

  async function sendMailFn(booking_id) {
    setsendingMail(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/broker/sendMail`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        // timeout: 10000,
        data: {
          booking_id: booking_id,
          customer_id: customerId,
        },
      });
      setsendingMail(false);
      if (!response.data.error) {
        setsuccessMessagePopup(response.data.message);
        await getShippingDocuments();
        handleShowSuccess();
        setTimeout(() => {
          handleCloseSuccess();
        }, 2000);
      } else {
        setError({
          title: response.data.message,
          err: response.data.err?.toString(),
        });
        setShowErrModal(true);
      }
    } catch (error) {
      setsendingMail(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  }

  async function createOrEditMember(e) {
    e.preventDefault();
    setisLoading(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/${
          isOrgEdit ? "edit" : "add"
        }`,
        {
          customer_id: customerId,
          designation:
            designation == "Demand Planning"
              ? selectedOption?.value
              : designation,
          contact_name: contact_name,
          contact_email: contact_email,
          contact_phone: contact_phone,
          contact_id: isOrgEdit || null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response?.data?.error) {
        await getCustomerContacts();
        setisLoading(false);
        handleClose3();
      } else {
        setErrormsg(response?.data?.message);
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert(
        `error while ${isOrgEdit ? "editing" : "adding"} member  `,
        err.message
      );
      console.log(err);
    } finally {
      setErrormsg("");
      setDesignation("");
      setContact_name("");
      setContact_email("");
      setContact_phone("");
    }
  }

  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setClickupData(clickup_data?.data?.dropdown_details);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }

  async function getStatuses() {
    const token = localStorage.getItem("mtm_user_token");
    try {
      let statuses = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipment/status`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setStatusOptions(
        statuses?.data?.result?.map((option) => {
          return { value: option.status_id, label: option?.status_name };
        })
      );
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }

  async function handleQuote(quote) {
    navigate(`quote/${quote?.quote_id}`, {
      state: {
        quote,
        previous: {
          tab: "quotes",
          selectedDate,
          quoteDetailsFetched,
        },
      },
    });
  }

  function handleTracking(quote, tab) {
    if (quote?.shipment_id) {
      navigate(`booking_number`, {
        state: {
          quote,
          previous: {
            tab: tab || "quotes",
            selectedDate,
            quoteDetailsFetched,
          },
        },
      });
    } else {
      navigate("tracking", {
        state: {
          quote,
          previous: {
            tab: tab || "quotes",
            selectedDate,
            quoteDetailsFetched,
          },
        },
      });
    }
  }

  function getCarrierName(career) {
    const carrer = carrierList.find((item) => item?.value === career);
    return carrer?.label;
  }

  function handleFileUploadSuccess(response) {
    if (response.status == 200 && !response.data.error) {
      setIsFileUploading(false);
      setsuccessMessagePopup("File Uploaded Successfully!");
      handleShowSuccess();
      setTimeout(() => {
        handleCloseSuccess();
      }, 2000);
      handleClose();
      setrawFile();
      setpreviewUrl();
      setfileUploadLimitRxError(false);
    } else {
      setIsFileUploading(false);
      setError({
        err: response.data.message,
        title: "error while uploading demand file",
      });
      setShowErrModal(true);
    }
  }

  function handleFileUploadError(error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    alert("error uploading document: " + error.message);
    setIsFileUploading(false);
  }

  //function to upload Purchase Order file
  async function handleFileUpload() {
    if (rawFile.length > 0) {
      setIsFileUploading(true);

      try {
        const uploadFormData = new FormData();
        const uploadFormDataDemand = new FormData();
        let response;
        if (
          uploadOption == "International Demand Planning" ||
          uploadOption == "Domestic Demand Planning"
        ) {
          uploadFormDataDemand.append("ord_file", rawFile[0]);
          uploadFormDataDemand.append("customer_id", customerId);
          uploadFormDataDemand.append("doc_type", uploadOption);
          response = await axios.post(
            `${process.env.REACT_APP_EVEREST_BASE_URL}/everest/order/upload`,
            uploadFormDataDemand
          );

          if (response.status == 200 && response.data.error) {
            setIsFileUploading(false);
            setShowErrModal(true);
            setError({
              title: response.data?.message,
              err: conditionalExp(
                typeof response?.data?.err == "object",
                JSON.stringify(response?.data?.err),
                response?.data?.err
              ),
            });
            return;
          }
        }

        rawFile?.forEach((file) => {
          uploadFormData.append("document", file);
        });
        uploadFormData.append("customer_id", customerId);
        uploadFormData.append(
          "document_type",
          conditionalExp(
            uploadOption == "Add new documents",
            documentName,
            uploadOption
          )
        );
        uploadFormData.append(
          "booking_id",
          conditionalExp(selectedBookingid, selectedBookingid, "")
        );
        uploadFormData.append(
          "user_id",
          JSON.parse(localStorage.getItem("mtm_user_data")).id
        );
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/upload`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        handleFileUploadSuccess(response);
      } catch (error) {
        handleFileUploadError(error);
      } finally {
        getDocuments();
        getShippingDocuments();
      }
    } else {
      alert("Please select a file");
    }
  }
  async function handleFileUploadSKU() {
    if (rawFileSKU) {
      setIsFileUploadingSKU(true);
      try {
        const uploadFormData = new FormData();
        uploadFormData.append("document", rawFileSKU);
        uploadFormData.append("customer_id", customerId);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/bulkAdd`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        if (response.status == 200 && !response.data.error) {
          setIsFileUploadingSKU(false);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          handleCloseSKUBULKAdd();
          getSKUListHome(
            customerId,
            setskuListDetails,
            setskuListDetailsRaw,
            navigate,
            setIsloading,
            setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput,'reload'
          );
          setrawFileSKU(null);
          setpreviewUrlSKU(null);
          setfileUploadLimitRxError(false);
        } else {
          setIsFileUploadingSKU(false);
          setShowErrModal(true);
          setError({ title: response.data?.message });
          handleCloseSKUBULKAdd();
          setrawFileSKU(null);
          setpreviewUrlSKU(null);
          setfileUploadLimitRxError(false);
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        alert("error uploading document: " + error.message);
        setIsFileUploadingSKU(false);
      }
    } else {
      alert("Please select a file");
    }
  }
  const checkConditionCustomer = (po) => {
    if (customer?.everest_type) {
      return po?.expected_pickup_date ? true : false;
    } else {
      return po?.expected_pickup_date && po?.modal_id ? true : false;
    }
  };

  async function handleUploadShippingDoc() {
    if (!documentName || documentName == "") {
      setdocumentNameError("Please Choose Document Name");
    }
    if (rawFile) {
      setdocumentNameError(null);
      setIsFileUploading(true);
      try {
        const uploadFormData = new FormData();
        rawFile?.forEach((file) => {
          uploadFormData.append("document", file);
        });
        uploadFormData.append("booking_id", selectedBookingid || "");
        uploadFormData.append("document_name", documentName);
        uploadFormData.append("customer_id", customerId);
        uploadFormData.append(
          "user_id",
          JSON.parse(localStorage.getItem("mtm_user_data")).id
        );
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/upload`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        if (response.status == 200) {
          setIsFileUploading(false);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          handleCloseShippingModal();
          setrawFile();
          setpreviewUrl();
          setfileUploadLimitRxError(false);
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        alert("error uploading document: " + error.message);
        setIsFileUploading(false);
      } finally {
        getDocuments();
        getShippingDocuments();
      }
    } else {
      alert("Please select a file");
    }
  }

  async function getDocuments() {
    setdataFetchedPillsDocuments(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setDocumentList(response.data.documents);
        setdataFetchedPillsDocuments(true);
      } else {
        setDocumentList([]);
        setdataFetchedPillsDocuments(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getBookingList(quotes) {
    setdataFetchedPillsTracking(false);
    const quote_ids = quotes.map((quote) => quote.quote_id);
    console.log(quote_ids);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/bookings`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          // quote_ids: quote_ids,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setBookingList(response.data.bookingsList);
        setdataFetchedPillsTracking(true);
      } else {
        setdataFetchedPillsTracking(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setdataFetchedPillsTracking(true);
    }
  }
  async function getPoList() {
    setdataFetched(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/poList`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setPoList(response.data.purchaseOrders);
        let optionsForCountries = new Set(
          response?.data?.purchaseOrders?.map(
            (po) => po?.vendor?.county?.toUpperCase() || ""
          )
        );
        setCooOptions(Array.from(optionsForCountries).filter((o) => o));
        let optionsForStatuses = new Set(
          response?.data?.purchaseOrders?.map(
            (po) => po?.clickup_status?.toUpperCase() || ""
          )
        );
        setstatusFOptions(Array.from(optionsForStatuses).filter((o) => o));
        console.log(optionsForCountries, "set of option");
        setdataFetched(true);
        await getAgents();
      } else {
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }
  async function getAgents() {
    setdataFetched(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          type: "origin",
          customer_id: customerId,
        },

        // data: {
        //   // dropdown_type: "agent_aduanal",
        //   dropdown_type: "agent_origin",
        // },
      });

      if (response.status == 200 && !response.data.error) {
        setAgentsList(response.data.brokers);
        setdataFetched(true);
      } else {
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  function handlePdfView(po) {
    setSelectedPoItems(po.poItems);
    setImgURL(po.po_file_path);
    setPopupTitle("Purchase Order");
    setShowimgpop(true);
  }
  function handlePdfViewShipping(doc) {
    setImgURL(doc.document_path);
    setPopupTitle(doc.document_name);
    setShowimgpop(true);
  }
  const getAllModals = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getModals`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        // console.log(response.data)
        setallModals(response.data.modals?.filter((modal) => modal?.transport_type));;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  async function handleAgentChange() {
    const { clickup_product_id, doc_number, clickup_task_id, broker_id } =
      agentData;

    if (clickup_product_id != "") {
      setisLoadingVendorChange(true);
      const clickup_product = agentsList.find(
        (agent) => agent.broker_id == broker_id
      );
      console.log(clickup_product, "clickup_product");
      try {
        const token = localStorage.getItem("mtm_user_token");
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setAgent`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },

          data: {
            clickup_task_id: clickup_task_id,
            clickup_product_id: clickup_product?.clickup_id,
            clickup_product_name: clickup_product?.company_name,
            doc_number: doc_number,
            broker_id: broker_id,
            customer_id: customerId,
          },
        });

        if (response.status == 200 && !response.data.error) {
          await getPoList();
          handleCloseVendor();
        } else {
          setShowErrModal(true);
          setError({
            title: "Error While Assigning Agent",
            err: response.data.message,
          });
          setdataFetched(true);
          handleCloseVendor();
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
      } finally {
        setisLoadingVendorChange(false);
      }
    }
  }

  async function handleAgentChangeModalSet() {
    setisLoadingAgentChange(true);
    const { doc_number, modal_id } = modalselected;
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setModal`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          modal_id: modal_id,
          doc_number: doc_number,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        await getPoList();

        setdataFetched(true);
        handleCloseVendorModal();
      } else {
        setdataFetched(true);
        handleCloseVendorModal();
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoadingAgentChange(false);
    }
  }
  async function handleAssignCustomBroker() {
    setsubmittingBrokerData(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setCustomBroker`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          booking_id: selectedBookingid,
          broker_id: selectedBrokerId,
          customer_id: customerId,
        },
      });
      setsubmittingBrokerData(false);
      if (response.status == 200 && !response.data.error) {
        await getShippingDocuments();
        handleCloseCustomBrokerModal();
      } else {
        alert(response.data.message);
        handleCloseCustomBrokerModal();
      }
    } catch (error) {
      setsubmittingBrokerData(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getCustomerContacts() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/get`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setContactsData(response.data.contacts);
      } else {
        setContactsData();
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  const getCountryCode = () => {
    let countryCode = "";
    for (let i = 0; i < allCountries?.length; i++) {
      const element = allCountries[i];
      if (element?.country == selectedOptionCountry?.label) {
        countryCode = element?.country_sort_code;
        break;
      }
    }
    return countryCode;
  };
  useEffect(() => {
    if (!selectedOptionCountry) {
      setSelectedOptionPort(null);
    }
    if (selectedOptionCountry && allCountries?.length > 0) {
      let countryCode = getCountryCode();
      getPortDetails(countryCode, setoptionsForPorts, null, navigate);
    }
  }, [selectedOptionCountry, allCountries?.length]);

  const downloadFile = async (filePath, fileType) => {
    setdataFetchedPillsDocuments(false);
    setdataFetchedPillsShipDocs(false);
    try {
      // Make a request to the proxy route with the 'url' header
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/proxy`, {
        headers: {
          url: filePath,
        },
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Error while fetching the file.");
      }

      // Create a blob from the response data
      const blob = await response.blob();

      // Create a temporary link to initiate the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileType + "." + filePath.split(".").slice(-1); // Replace with your desired file name
      link.click();

      // Clean up the temporary link
      URL.revokeObjectURL(link.href);
      setdataFetchedPillsDocuments(true);
      setdataFetchedPillsShipDocs(true);
    } catch (error) {
      // Handle any errors
      setdataFetchedPillsDocuments(true);
      setdataFetchedPillsShipDocs(true);
      console.error("Error downloading the file:", error);
      alert("Error downloading the file: " + error);
    }
  };

  async function handleFileView(doc) {
    setImgURL(doc.document_path);
    setPopupTitle(doc.document_type);
    setShowimgpop(true);
  }

  async function handleDocDelete(doc_id) {
    setdataFetched(false);
    setisLoadingDelete(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/delete`,
        method: "POST",
        data: {
          customer_id: customerId,
          doc_id: doc_id,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
      } else {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
        console.log(response.data);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      handleCloseDelete();
      setisLoadingDelete(false);
    }
  }

  async function handleShippingDocDelete(doc_id, booking_id) {
    setdataFetched(false);
    setisLoadingDelete(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/delete`,
        method: "POST",
        data: {
          booking_id: booking_id,
          doc_id: doc_id,
          customer_id: customerId,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      handleCloseDelete();
      setisLoadingDelete(false);
    }
  }

  async function handleDocEdit() {
    setIsFileUploading(true);
    try {
      const uploadFormData = new FormData();
      uploadFormData.append("document", rawFile);
      uploadFormData.append("customer_id", customerId);
      uploadFormData.append("doc_id", selectedDoc.doc_id);
      uploadFormData.append("booking_id", selectedBookingid || "");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/edit`,
        uploadFormData,
        {
          headers: {
            jwt_token: localStorage.getItem("mtm_user_token"),
          },
        }
      );

      if (response.status == 200) {
        setIsFileUploading(false);
        setUploadOption("");
        handleShowSuccess();
        setTimeout(() => {
          handleCloseSuccess();
        }, 2000);
        handleClose();
        setrawFile();
        setpreviewUrl();
        setfileUploadLimitRxError(false);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error uploading document: " + error.message);
      setIsFileUploading(false);
    } finally {
      await getDocuments();
      await getShippingDocuments();
    }
  }

  async function getShippingDocuments() {
    setdataFetchedPillsShipDocs(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });
      //agents with cost
      if (response.status == 200 && !response.data.error) {
        setShippingDocList(response.data.bookings);
        setdataFetchedPillsShipDocs(true);
      } else {
        setShippingDocList([]);
        setdataFetchedPillsShipDocs(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  function getFileType(type) {
    if (type == "pdf") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_pdf"></use>
        </svg>
      );
    } else if (type == "xls" || type == "xlsx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_exel"></use>
        </svg>
      );
    } else if (type == "doc" || type == "docx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_word"></use>
        </svg>
      );
    } else {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_doc"></use>
        </svg>
      );
    }
  }

  const getAllAgents = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          console.log(response.data, "response data agents");
          let { brokers } = response.data;
          console.log(brokers, "brokers");
          let options = brokers?.map((item) => {
            return {
              label: `${item.company_name} | ${item.type}`,
              value: item.broker_id,
            };
          });
          setallBrokers(brokers);
          setaddNewCostOptions(options);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  const syncToClickup = async (po) => {
    setisLoading(true);
    setdataFetched(false);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmpo/sync/shipment`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          doc_number: po?.doc_number,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          console.log("syncToClickup done");
          setsuccessMessagePopup(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          await getPoList();
        } else {
          setShowErrModal2(true);
          setfinalPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoading(false);
      setdataFetched(true);
    }
  };

  function handleAddAgentCostCheckFn() {
    if (
      !selectedAgentNameFromModal ||
      !addAddNewCostDetails.cost ||
      addAddNewCostDetails.cost == ""
    ) {
      let selectedAgentNameFromModalError = !selectedAgentNameFromModal
        ? true
        : false;
      let costError = addAddNewCostDetails.cost == "" ? true : false;
      setaddAddNewCostDetailsError({
        ...addAddNewCostDetailsError,
        agent_name: selectedAgentNameFromModalError
          ? "Please select Agent Name"
          : null,
        cost: costError ? "Please Enter Cost" : null,
      });
      return true;
    } else {
      return false;
    }
  }
  const handleAddAgentCost = async () => {
    if (handleAddAgentCostCheckFn()) {
      return;
    }
    setisLoading(true);
    setdataFetchedPillsShipDocs(false);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipping/broker/addCost`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          broker_id: selectedAgentNameFromModal.value,
          booking_id: selectedBookingid,
          cost: addAddNewCostDetails.cost,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          handleCloseAddAgentsAdd();
          setselectedAgentNameFromModal(null);
          setaddAddNewCostDetails({
            agent_name: "",
            cost: "",
          });
          await getShippingDocuments();
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoading(false);
      setdataFetchedPillsShipDocs(true);
    }
  };

  async function handleOrgEdit(contact) {
    console.log("handle edit");
    setisOrgEdit(contact?.contact_id);
    setselectedOption(contact?.designation);
    setContact_name(contact?.contact_name);
    setContact_email(contact?.contact_email);
    setContact_phone(contact?.contact_phone);
    handleShow3(contact?.designation);
  }
  async function handleOrgDelete(contact_id) {
    setisLoadingDelete(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/delete`,
        {
          customer_id: customerId,
          contact_id: isOrgEdit,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response?.data?.error) {
        await getCustomerContacts();
        handleCloseDelete();
      } else {
        setErrormsg(response?.data?.message);
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error while deleting member  ", err.message);
      console.log(err);
    } finally {
      setisLoadingDelete(false);
    }
  }
  async function handleAddQuoteToPo() {
    setIsLoadingAddQuote(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/quote/addToPo`,
        {
          customer_id: customerId,
          doc_number: selecteQuote.doc_number,
          quote_id: selecteQuote.quote_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response?.data?.error) {
        await getPoList();
      } else {
        setError({
          title: "Error while adding quote",
          err: response?.data?.message,
        });
        setShowErrModal(true);
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    } finally {
      setIsLoadingAddQuote(false);
      handleCloseQuoteSelectModal();
    }
  }

  function mapPurchaseOrders(array) {
    return array?.map((po, index) => (
      <div
        key={po?.doc_number}
        style={{
          border: conditionalExp(
            po?.clickup_migration_status == 1,
            "2px solid #0e2181",
            "1px solid #e0e0e0"
          ),
        }}
        className="border-card mb-3 mt-3"
      >
        <div className="row pt-2 px-4 gap-4">
          <div className="col align-items-center">
            <p className="fw-700">Purchase Order Number: {po?.doc_number}</p>
            {po?.poquote?.opportunity_name && (
              <>
                <p className="fw-700 mt-1">
                  Opportunity Name:
                  <br />
                  {po?.poquote?.opportunity_name}{" "}
                  {po?.poquote?.custom_task_id && "|"}{" "}
                  {po?.poquote?.custom_task_id}
                </p>
              </>
            )}
          </div>

          <div className="col-2 align-items-center">
            <p className="fw-600">
              <span className="fw-700">Status:</span> {po?.clickup_status}
            </p>
            {po?.clickup_status == "en almacén" && (
              <button
                onClick={() => {
                  setDrVisible(true);
                  setArrivedContainers(po?.arrived_containers || []);
                }}
                type="button"
                className="quotes-btn d-flex justify-content-center my-2  d-block"
                style={{ minWidth: "max-content" }}
              >
                View Arrived Containers
              </button>
            )}
          </div>

          <div className="col-6 d-flex align-items-center justify-content-between ">
            {po?.clickup_error && (
              <button
                onClick={() => syncToClickup(po)}
                type="button"
                className="quotes-btn d-flex justify-content-center mx-4  d-block"
                style={{ minWidth: "max-content" }}
              >
                Sync To Clickup
              </button>
            )}
            <button
              onClick={() => handlePdfView(po)}
              type="button"
              className="quotes-btn d-flex justify-content-center mx-4  d-block"
            >
              <svg className="icon fs-22">
                <use href="#icon_eye"></use>
              </svg>
            </button>
            {!po?.quote_id && (
              <select
                className="form-select py-2  rounded-pill d-block me-2"
                value={po?.quote_id || ""}
                onChange={(e) => {
                  setSelecteQuote({
                    quote_id: e.target.value,
                    doc_number: po?.doc_number,
                  });
                  setShowQuoteSelectModal(true);
                }}
              >
                <option value="">Choose Quote</option>
                {quoteDetailsFetched?.map((item, index) => (
                  <option
                    key={item?.quote_id + item?.opportunity_name}
                    value={item?.quote_id}
                  >
                    {item?.opportunity_name}
                  </option>
                ))}
              </select>
            )}
            <select
              className="form-select py-2  rounded-pill d-block me-2"
              value={po?.modal_id || ""}
              disabled={po?.modal_id}
              onChange={(e) => {
                setmodalselected({
                  doc_number: po?.doc_number,
                  modal_id: e.target.value,
                });
                handleShowVendorModal();
              }}
            >
              <option value="">Choose Modal</option>
              {allModals?.map((item, index) => (
                <option
                  key={item?.id + item?.modal_name}
                  value={item?.modal_id}
                >
                  {item?.modal_name} | {item?.transport_type}
                </option>
              ))}
            </select>

            {conditionalExp(
              checkConditionCustomer(po),
              <>
                <select
                  className="form-select py-2  rounded-pill d-block"
                  value={po.broker_id || ""}
                  disabled={
                    (po?.clickup_agent_id || po?.broker_id) &&
                    po?.clickup_status != "pendiente de booking"
                  }
                  onChange={(e) => {
                    setAgentData({
                      broker_id: e.target.value,
                      clickup_product_id: e.target.value,
                      doc_number: po.doc_number,
                      clickup_task_id: po.clickup_task_id,
                    });
                    handleShowVendor();
                  }}
                >
                  <option value="">Assign Agent At Origin</option>
                  {agentsList?.map((agent) => (
                    <option
                      key={agent?.broker_id + agent?.company_name}
                      value={agent.broker_id}
                    >
                      {agent.company_name}
                    </option>
                  ))}
                </select>
              </>,
              ""
            )}
            {(po?.clickup_agent_id || po?.broker_id) &&
              conditionalExp(
                po?.shipment_id,
                <button
                  onClick={() =>
                    handleTracking(
                      { shipment_id: po.shipment_id },
                      "purchase-order"
                    )
                  }
                  type="button"
                  style={{ minWidth: "fit-content" }}
                  className="quotes-btn d-flex justify-content-center mx-4  d-block"
                >
                  <span style={{ wordBreak: "normal" }}>Tracking</span>
                </button>,
                <>
                  <button
                    onClick={() => {
                      setSelectedDocNumber(po.doc_number);
                      if (po?.quote_id) {
                        setSelecteQuote(po.quote_id);
                      }
                      if (po?.carrier_code) {
                        setselectedOption(po.carrier_code);
                      }
                      handleShow2();
                    }}
                    type="button"
                    style={{ minWidth: "fit-content" }}
                    className="quotes-btn d-flex justify-content-center mx-4  d-block "
                  >
                    <span style={{ wordBreak: "normal" }}>Add Tracking</span>
                  </button>
                </>
              )}
          </div>
          <div className="row text-danger">
            {po?.assign_agent_error && (
              <p style={{ textAlign: "end" }}>
                <b>Error in clickup:</b> {po?.assign_agent_error}
              </p>
            )}
          </div>
        </div>
        <div className="row   mx-4 gap-2">
          {customer.everest_type && po?.company_name && (
            <p className="col   fw-600">{po?.company_name}</p>
          )}
          <p className="col fw-600">
            <span className="fw-700">Total Quantity:</span>{" "}
            {po?.poItems?.reduce((a, b) => a + b.qty, 0)}
          </p>
          <p className="col fw-600">
            <span className="fw-700">Total SKUs:</span> {po?.poItems?.length}
          </p>
          <p className="col-6">&nbsp;</p>
        </div>
        <hr />
        <div className="row pb-2 px-4 gap-4">
          <div className="col d-flex justify-content-between align-items-center">
            <p className="fw-600">
              <span className="fw-700">Vendor Name: </span>{" "}
              {po?.vendor?.vendor_name}{" "}
              {po?.vendor?.vendor_code && `(${po?.vendor?.vendor_code})`}
            </p>
            <p className="fw-600">
              <span className="fw-700"> Country Origin: </span>{" "}
              {po?.vendor?.county}
            </p>
          </div>
          {po?.shipment_id ? (
            <div className="col d-flex justify-content-between align-items-center">
              <p className="fw-600 hn">
                <span className="fw-700"> Booking Number: </span>{" "}
                {po?.booking_id}
              </p>
              <p className="fw-600 hn">
                <span className="fw-700"> Carrier: </span>{" "}
                {po?.career_scac && getCarrierName(po?.career_scac)}
              </p>
            </div>
          ) : (
            <div className="col  align-items-center"> </div>
          )}
          <div className="col  align-items-center">
            <p className="fw-600 hn">
              <span className="fw-700"> Pickup Date: </span>{" "}
              {po?.expected_pickup_date}
            </p>
          </div>
        </div>
      </div>
    ));
  }

  function acceptFileTypesFn(modal) {
    if (modal == "demand") {
      if (
        uploadOption == "International Demand Planning" ||
        uploadOption == "Domestic Demand Planning"
      ) {
        return ".xls,.xlsx";
      } else {
        return "";
      }
    } else if (modal == "sku") {
      return ".xls,.xlsx";
    } else {
      return "";
    }
  }

  function documentsUploadModalDups(modal) {
    return (
      <>
        <div className="upload-box p-3">
          <label className="drag-box input-file">
            <img
              className="img-fluid rounded-start"
              width={80}
              height={55}
              src={window.location.origin + "/images/uploader.png"}
              alt="..."
            />
            <input
              type="file"
              multiple
              accept={acceptFileTypesFn(modal)}
              id="fileUpload2"
              onChange={
                modal == "sku" ? handleChangeFileFunSKU : handleChangeFileFun
              }
            />
            <span>
              Drag and drop your image or <a href="">browse file</a> on your
              computer
            </span>
          </label>
        </div>
        <div className="d-block rounded-2 p-1 my-3">
          {previewUrlSKU || previewUrl ? (
            conditionalExp(
              modal == "sku",
              <div
                className="file-import d-flex ai-center mt-10"
                id="remove_${inputid}"
              >
                {modal == "sku" ? checkRxTypeSKU() : checkRxType()}
                <span style={{ marginRight: "auto" }}>
                  {modal == "sku" ? rawFileSKU?.name : rawFile?.name}
                </span>
                <label htmlFor="fileUpload2" className="mr-10">
                  <svg
                    className="icon"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <use href="#icon_edit"></use>
                  </svg>
                </label>
                <button
                  onClick={() => {
                    if (modal == "sku") {
                      setpreviewUrlSKU(null);
                      setrawFileSKU(null);
                    } else {
                      setpreviewUrl(null);
                      setrawFile(null);
                    }
                  }}
                >
                  <svg
                    className="icon"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <use href="#icon_fileclose"></use>
                  </svg>
                </button>
              </div>,
              rawFile?.map((file, index) => (
                <div
                  key={file?.name + "rawFiles"}
                  className="file-import d-flex ai-center my-2"
                  id="remove_${inputid}"
                >
                  {checkRxType(file)}
                  <span style={{ marginRight: "auto" }}>{file?.name}</span>

                  <button
                    onClick={() => {
                      setrawFile((pre) =>
                        pre.filter((item, iIndex) => index != iIndex)
                      );
                    }}
                  >
                    <svg
                      className="icon"
                      style={{ width: "20px", height: "20px" }}
                    >
                      <use href="#icon_fileclose"></use>
                    </svg>
                  </button>
                </div>
              ))
            )
          ) : (
            <div></div>
          )}
        </div>
        {fileUploadLimitRxError && (
          <div className="mb-2 w-100 text-danger text-center">
            Please upload file less than 6MB
          </div>
        )}
      </>
    );
  }

  function pdfModalDups() {
    return (
      <>
        {!imgURL ? (
          <h4
            style={{
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            No file found!
          </h4>
        ) : (
          <iframe
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "5px",
              overflow: "scroll",
            }}
            // src={"https://docs.google.com/viewer?url=" + imgURL + "&embedded=true"}
            src={
              imgURL?.split(".")?.slice(-1) == "xls" ||
              imgURL?.split(".")?.slice(-1) == "xlsx" ||
              imgURL?.split(".")?.slice(-1) == "doc" ||
              imgURL?.split(".")?.slice(-1) == "docx" ||
              imgURL?.split(".")?.slice(-1) == "pptx" ||
              imgURL?.split(".")?.slice(-1) == "ppt"
                ? "https://view.officeapps.live.com/op/embed.aspx?src=" + imgURL
                : imgURL
            }
          ></iframe>
        )}
      </>
    );
  }

  async function handleExportToExel() {
    setIsExportingToExel(true);
    try {
      const html = tagCalculationTblRef.current;
      const ws = XLSX.utils.table_to_sheet(html);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Table Data");
      XLSX.writeFile(wb, "tag-calculation.xlsx");
    } catch (error) {
      console.log(error);
      alert("error while exporting" + error);
    } finally {
      setIsExportingToExel(false);
    }
  }

  useEffect(() => {
    
    if (
      !quoteDetailsFetched.length ||
      selectedProductType ||
      selectedDate ||
      selectedShipment
    ) {
      console.log(selectedProductType);
      getQuotes(customerId, "home", null, setQuoteDetailsFetched, navigate, {
        selectedProductType,
        selectedDate,
        selectedShipment,
        clickupData,
        setCurrentClickup: null,
        setdataFetchedPillsQuotes,
        getBookingList,
      });
    }
   
  }, [selectedDate, selectedProductType, selectedShipment]);

  useEffect(() => {
    !tagCalculationSKUList?.length &&
      getTagSKUList(
        setTagCalculationSKUList,
        setIsLoadingTagCalSKU,
        navigate,
        customerId
      );
    getTagCalculationList(
      selectedDateTagList,
      setTagCalculationList,
      setIsLoadingTagCalList,
      navigate,
      customerId
    );
  }, [selectedDateTagList]);

  useEffect(() => {
    getStatuses();
    getDropdown();
    getAllModals();
    getCarrier(setCarrierList, navigate);
    getPoList();
    getDocuments();
    getCustomerContacts();
    getShippingDocuments();
    getCountriesFn(
      setoptionsForCountries,
      setallCountries,
      navigate,
      customerId
    );
    !vendorListDetailsRaw.length &&
      getVendorList(
        customerId,
        null,
        setvendorListDetailsRaw,
        setvendorListDetails,
        navigate
      );
    
    !warehouseListDetailsRaw.length &&
      getWarehouseList(
        customerId,
        setwarehouseListDetailsRaw,
        setwarehouseListDetails,
        navigate,
        setIsgettingwarehouselist
      );
    getAllAgents();
  }, []);

  useEffect(()=>{
    
      if(currentPage == 0){
        getSKUListHome(
          customerId,
          setskuListDetails,
          setskuListDetailsRaw,
          navigate,
          setIsloading,
              setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput,'reload'
        );
      }else{
        getSKUListHome(
          customerId,
          setskuListDetails,
          setskuListDetailsRaw,
          navigate,
          setIsloading,
              setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput
        );
      }
  },[currentPage])

  useEffect(()=>{
    if (!searchInput.length) {
      getSKUListHome(
        customerId,
        setskuListDetails,
        setskuListDetailsRaw,
        navigate,
        setIsloading,
            setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput,'reload'
      );}
  },[searchInput])

  useEffect(() => {
    console.log(rawFile);
    if (!rawFile || rawFile.length == 0) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(
      rawFile.length ? rawFile[0] : rawFile
    );
    setpreviewUrl(objectURL);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    if (!rawFileSKU) {
      setpreviewUrlSKU(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFileSKU);
    setpreviewUrlSKU(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFileSKU]);
  useEffect(() => {
    if (!previousState) {
      navigate(`/${customerId}`, {
        state: {
          previous: {
            tab: "organization",
          },
        },
      });
    } else {
      return;
    }
  }, [previousState]);

  useEffect(() => {
    const filteredPOList = poList?.filter((po) => {
      // Check if PO's demand_type matches the selected orderType
      const isOrderTypeMatch = orderType === "" || po.demand_type === orderType;

      // Check if COO is empty or PO's vendor's county (converted to uppercase) matches the selected coo
      const isCOOMatch =
        coo === "" || po?.vendor?.county?.toUpperCase() === coo;
      const isstatusFMatch =
        statusF === "" || po?.clickup_status?.toUpperCase() === statusF;

      // Return true if both conditions are met (or if coo and orderType are empty), indicating a match
      return isOrderTypeMatch && isCOOMatch && isstatusFMatch;
    });
    setFilteredPoList(filteredPOList);
  }, [orderType, coo, statusF]);

  useEffect(() => {
    if (!vendorSearchInput) {
      return setvendorListDetails(vendorListDetailsRaw);
    }
    let filteredVendors = vendorListDetailsRaw?.filter((item) => {
      let vendorname = item?.vendor_name
        ?.toLowerCase()
        ?.includes(vendorSearchInput?.toLowerCase());
      let vendorcode = item?.vendor_code
        ?.toLowerCase()
        ?.includes(vendorSearchInput?.toLowerCase());
      return vendorname || vendorcode;
    });
    setvendorListDetails(filteredVendors);
  }, [vendorSearchInput]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="col-12 py-3">
            <div className="w-100 tabs-row">
              <ul
                className="nav nav-pills mb-3 gap-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "organization",
                      "active",
                      ""
                    )}`}
                    id="pills-organization-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-organization"
                    type="button"
                    role="tab"
                    aria-controls="pills-organization"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "organization",
                          },
                        },
                      });
                    }}
                  >
                    Organization
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "document",
                      "active",
                      ""
                    )}`}
                    id="pills-document-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-document"
                    type="button"
                    role="tab"
                    aria-controls="pills-document"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "document",
                          },
                        },
                      });
                    }}
                  >
                    Document
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "quotes",
                      "active",
                      ""
                    )}`}
                    id="pills-quotes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-quotes"
                    type="button"
                    role="tab"
                    aria-controls="pills-quotes"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "quotes",
                          },
                        },
                      });
                    }}
                  >
                    Quotes
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "tracking",
                      "active",
                      ""
                    )}`}
                    id="pills-tracking-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tracking"
                    type="button"
                    role="tab"
                    aria-controls="pills-tracking"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "tracking",
                          },
                        },
                      });
                    }}
                  >
                    Tracking
                  </button>
                </li>
                {/* {customer?.everest_type == false  && ( */}
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "vendor-supplier",
                      "active",
                      ""
                    )}`}
                    id="pills-vendor-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-vendor"
                    type="button"
                    role="tab"
                    aria-controls="pills-vendor"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "vendor-supplier",
                          },
                        },
                      });
                    }}
                  >
                    Vendor/Supplier
                  </button>
                </li>
                {/* )} */}

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "purchase-order",
                      "active",
                      ""
                    )}`}
                    id="pills-purchase-order-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-purchase-order"
                    type="button"
                    role="tab"
                    aria-controls="pills-purchase-order"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "purchase-order",
                          },
                        },
                      });
                    }}
                  >
                    Purchase Order
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "shipping-documents",
                      "active",
                      ""
                    )}`}
                    id="pills-shipping-documents-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-shipping-documents"
                    type="button"
                    role="tab"
                    aria-controls="pills-shipping-documents"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "shipping-documents",
                          },
                        },
                      });
                    }}
                  >
                    Shipping Documents
                  </button>
                </li>
                {!customer?.everest_type && (
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${conditionalExp(
                        previousState?.tab == "SKU-Catalogue",
                        "active",
                        ""
                      )}`}
                      id="pills-SKU-Catalogue-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-SKU-Catalogue"
                      type="button"
                      role="tab"
                      aria-controls="pills-SKU-Catalogue"
                      aria-selected="false"
                      onClick={() => {
                        navigate(`/${customerId}`, {
                          state: {
                            previous: {
                              tab: "SKU-Catalogue",
                            },
                          },
                        });
                      }}
                    >
                      SKU Catalogue
                    </button>
                  </li>
                )}
                {
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${conditionalExp(
                        previousState?.tab == "WAREHOUSE",
                        "active",
                        ""
                      )}`}
                      id="pills-warehouse-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-warehouse"
                      type="button"
                      role="tab"
                      aria-controls="pills-warehouse"
                      aria-selected="false"
                      onClick={() => {
                        navigate(`/${customerId}`, {
                          state: {
                            previous: {
                              tab: "WAREHOUSE",
                            },
                          },
                        });
                      }}
                    >
                      Warehouse
                    </button>
                  </li>
                }
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "tag-calculation",
                      "active",
                      ""
                    )}`}
                    id="pills-tag-calculation-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tag-calculation"
                    type="button"
                    role="tab"
                    aria-controls="pills-tag-calculation"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "tag-calculation",
                          },
                        },
                      });
                    }}
                  >
                    Tag Calculation
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "sku-footprint",
                      "active",
                      ""
                    )}`}
                    id="pills-sku-footprint-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-sku-footprint"
                    type="button"
                    role="tab"
                    aria-controls="pills-sku-footprint"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "sku-footprint",
                          },
                        },
                      });
                    }}
                  >
                    SKU Footprint
                  </button>
                </li> */}
              </ul>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "organization",
                  "show active",
                  ""
                )}`}
                id="pills-organization"
                role="tabpanel"
                aria-labelledby="pills-organization-tab"
                tabIndex="1"
              >
                <div className="py-3">
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Director Details</h5>
                      <button
                        onClick={() => handleShow3("Director")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.directors.length,
                        contactsData?.directors.map((director, index) => (
                          <Row
                            key={director?.contact_name + director?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={director?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={director?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={director?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(director)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(director?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No directors found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Finance Details</h5>
                      <button
                        onClick={() => handleShow3("Finance")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.finances.length,
                        contactsData?.finances.map((fincance, index) => (
                          <Row
                            key={fincance?.contact_name + fincance?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={fincance?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={fincance?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={fincance?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(fincance)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(fincance?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No finances found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Sales Details</h5>
                      <button
                        onClick={() => handleShow3("Sales")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.sales.length,
                        contactsData?.sales.map((sale, index) => (
                          <Row
                            key={sale?.contact_name + sale?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={sale?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={sale?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={sale?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(sale)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(sale?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No Sales found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Oprations Details</h5>
                      <button
                        onClick={() => handleShow3("Operation")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.operations.length,
                        contactsData?.operations.map((opration, index) => (
                          <Row
                            key={opration?.contact_name + opration?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={opration?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={opration?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={opration?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(opration)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(opration?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No Oprations found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  {customer?.everest_type && (
                    <Col className=" g-3 pb-4 ">
                      <Col className="col-12 d-flex align-items-center  plus-btn">
                        <h5 className="fw-700">Demand Planning Management</h5>
                        <button
                          onClick={() => handleShow3("Demand Planning")}
                          type="button"
                        >
                          <CustomIcon name="icon_plusradius" className="fs-3" />
                        </button>
                      </Col>
                      {contactsData &&
                        conditionalExp(
                          contactsData?.approvers.length,
                          <Col style={{ paddingLeft: "4px" }}>
                            <h5 className="fw-700 mb-2">Approvers</h5>
                            {contactsData?.approvers.map((approver, index) => (
                              <Row
                                key={
                                  approver?.contact_name + approver?.contact_id
                                }
                                className="row-cols-3 cols-lg-3 g-3 pb-4"
                              >
                                <Col>
                                  <div className="input-box">
                                    <label className="fw-600">Name</label>
                                    <input
                                      type="text"
                                      className="w-100"
                                      value={approver?.contact_name}
                                      placeholder="Enter First Name"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="input-box">
                                    <label className="fw-600">Email</label>
                                    <input
                                      type="email"
                                      className="w-100"
                                      value={approver?.contact_email}
                                      placeholder="Enter Your Email"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="d-flex  align-items-center  h-100 ">
                                    <button
                                      onClick={() => handleOrgEdit(approver)}
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        margin: "10px",
                                      }}
                                      type="button"
                                    >
                                      <CustomIcon
                                        name="icon_edit"
                                        className="fs-4"
                                      />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setisOrgEdit(approver?.contact_id);
                                        setShowDelete(true);
                                      }}
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                      type="button"
                                    >
                                      <CustomIcon
                                        name="icon_delete"
                                        className="fs-3"
                                      />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </Col>,
                          null
                        )}
                      {contactsData &&
                        conditionalExp(
                          contactsData?.new_item_managers.length,
                          <Col style={{ paddingLeft: "4px" }}>
                            <h5 className="fw-700 mb-2">New Item Managers</h5>
                            {contactsData?.new_item_managers.map(
                              (new_item_manager, index) => (
                                <Row
                                  key={
                                    new_item_manager?.contact_name +
                                    new_item_manager?.contact_id
                                  }
                                  className="row-cols-3 cols-lg-3 g-3 pb-4"
                                >
                                  <Col>
                                    <div className="input-box">
                                      <label className="fw-600">Name</label>
                                      <input
                                        type="text"
                                        className="w-100"
                                        value={new_item_manager?.contact_name}
                                        placeholder="Enter First Name"
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="input-box">
                                      <label className="fw-600">Email</label>
                                      <input
                                        type="email"
                                        className="w-100"
                                        value={new_item_manager?.contact_email}
                                        placeholder="Enter Your Email"
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="d-flex  align-items-center  h-100 ">
                                      <button
                                        onClick={() =>
                                          handleOrgEdit(new_item_manager)
                                        }
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          margin: "10px",
                                        }}
                                        type="button"
                                      >
                                        <CustomIcon
                                          name="icon_edit"
                                          className="fs-4"
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          setisOrgEdit(
                                            new_item_manager?.contact_id
                                          );
                                          setShowDelete(true);
                                        }}
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }}
                                        type="button"
                                      >
                                        <CustomIcon
                                          name="icon_delete"
                                          className="fs-3"
                                        />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Col>,
                          null
                        )}
                    </Col>
                  )}
                  {/* <Row className="row-cols-3 cols-lg-3 g-3 pb-4">
                    <Col className="col-12">
                      <h5 className="fw-700">Tax and Credit</h5>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Tax ID</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.tax_id}
                          placeholder="Tax Id"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Credit Days Approved</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.credit_days}
                          placeholder="Credit Days"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Credit Amount Approved</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.credit_amount_limit}
                          placeholder="Credit Amount"
                        />
                      </div>
                    </Col>
                  </Row> */}
                </div>
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "notes",
                  "show active",
                  ""
                )}`}
                id="pills-notes"
                role="tabpanel"
                aria-labelledby="pills-notes-tab"
                tabIndex="2"
              >
                Notes
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "document",
                  "show active",
                  ""
                )}`}
                id="pills-document"
                role="tabpanel"
                aria-labelledby="pills-document-tab"
                tabIndex="3"
              >
                {conditionalExp(
                  dataFetchedPillsDocuments,
                  <>
                    <div className="d-flex py-3">
                      <div style={{ width: "580px" }}>
                        <select
                          className="form-select py-2 rounded-pill"
                          value={uploadOption}
                          onChange={(e) => setUploadOption(e.target.value)}
                        >
                          <option value="">Please select Document</option>
                          {customer?.everest_type && (
                            <>
                              <option value="International Demand Planning">
                                International Demand Planning
                              </option>
                              <option value="Domestic Demand Planning">
                                Domestic Demand Planning
                              </option>
                            </>
                          )}
                          <option value="ID Legal Rep">ID Legal Rep</option>
                          <option value="Company Legal Documents">
                            Company Legal Documents
                          </option>
                          <option value="Address ID Documents">
                            Address ID Documents
                          </option>
                          <option value="Service Contract">
                            Service Contract
                          </option>
                          <option value="Tax ID Documents">
                            Tax ID Documents
                          </option>
                          <option value="Onboarding Files">
                            Onboarding Files
                          </option>
                          <option value="Add new documents">
                            Add new documents
                          </option>
                        </select>
                      </div>
                      <div className="ms-4">
                        <button
                          disabled={uploadOption === ""}
                          onClick={() => handleShow(null)}
                          type="button"
                          className="upload-btn fs-20 fw-600 d-flex text-center"
                        >
                          Upload
                        </button>
                      </div>
                    </div>

                    <div className="col-7 py-4">
                      {conditionalExp(
                        documentList.length > 0,
                        documentList.map((doc, index) => (
                          <div
                            key={doc.doc_id + "DOC"}
                            className="rounded-pill my-2 py-2 px-3 d-flex align-items-center"
                            style={{
                              border: " 1px solid #F1F1F1",
                              background: "#FDFDFD",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {doc &&
                                getFileType(
                                  doc?.document_path?.split(".")?.slice(-1)
                                )}
                              <div>
                                <b>
                                  <p>{doc.document_type}</p>
                                </b>
                                <i>
                                  <strong>Uploaded By:</strong>{" "}
                                  {doc.user_full_name}
                                </i>
                              </div>
                              <div className="mx-1">
                                <p>&nbsp;</p>
                                <i>
                                  <strong>Uploaded Date:</strong>{" "}
                                  {doc.createdAt &&
                                    moment(doc.createdAt).format("MM-DD-YYYY")}
                                </i>
                              </div>
                            </div>
                            <div className="icon-btn ms-auto">
                              <button
                                disabled={isLoading}
                                onClick={() =>
                                  downloadFile(
                                    doc.document_path,
                                    doc.document_type
                                  )
                                }
                                type="button"
                                className=""
                              >
                                {isLoading ? (
                                  <Loader />
                                ) : (
                                  <svg className="icon fs-22">
                                    <use href="#icon_download"></use>
                                  </svg>
                                )}
                              </button>
                              <button
                                onClick={() => handleFileView(doc)}
                                type="button"
                                className=""
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_eye"></use>
                                </svg>
                              </button>
                              <button
                                onClick={() => handleShow(doc)}
                                type="button"
                                className=""
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>
                              <button
                                onClick={() => handleShowDelete(doc)}
                                type="button"
                                className="delete-btn"
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_delete"></use>
                                </svg>
                              </button>
                            </div>
                          </div>
                        )),
                        null
                      )}
                    </div>
                  </>,
                  <div
                    style={{
                      marginTop: "100px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "quotes",
                  "show active",
                  ""
                )}`}
                id="pills-quotes"
                role="tabpanel"
                aria-labelledby="pills-quotes-tab"
                tabIndex="4"
              >
                <div className="pt-5 pb-4">
                  <form className="d-flex me-auto align-items-center ">
                    <div className="d-flex align-items-center">
                      <div className="me-4">
                        <RangePicker
                          onChange={(values) => {
                            if (!values) {
                              setselectedDate([]);
                            } else {
                              setselectedDate(
                                values?.map((item) => {
                                  return getFormattedDate(item);
                                })
                              );
                            }
                          }}
                          style={{ height: "38px" }}
                          format={dateFormat}
                        />
                      </div>

                      <div className="me-4">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          value={selectedProductType}
                          onChange={(e) =>
                            setselectedProductType(e.target.value)
                          }
                        >
                          <option value="">All Products</option>
                          <option value="OCEAN FCL">OCEAN FCL</option>
                          <option value="OCEAN LCL">OCEAN LCL</option>
                          <option value="TRUCK FCL">TRUCK FCL</option>
                          <option value="TRUCK LTL">TRUCK LTL</option>
                          <option value="Air">Air</option>
                          <option value="Control Tower">Control Tower</option>
                          <option value="INTERMODAL">INTERMODAL</option>
                          <option value="COURIER">COURIER</option>
                        </select>
                      </div>
                      <div>
                        <select
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          className="form-select"
                          value={selectedShipment}
                          onChange={(e) => setSelectedShipment(e.target.value)}
                        >
                          <option value="">All Shipping</option>
                          {statusOptions.map((option, index) => (
                            <option
                              key={option?.label + option?.value}
                              value={option?.value}
                            >
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                {!dataFetchedPillsQuotes && (
                  <div className="w-100 text-center">
                    <Loader />
                  </div>
                )}

                {dataFetchedPillsQuotes &&
                  quoteDetailsFetched?.map((quote, index) => (
                    <div
                      key={quote?.opportunity_name + quote?.custom_task_id}
                      className="border-card mb-4"
                    >
                      <div className="row py-3 px-4 gap-4">
                        <div className="col">
                          <p className="fw-600 mxw-250">
                            Opportunity Name: {quote?.opportunity_name}
                          </p>
                          <p className="fw-600 mxw-250">
                            {quote?.custom_task_id}
                          </p>
                        </div>
                        <div className="col-auto">
                          <p className="fw-600">
                            Product Type: {quote?.product_type_name}
                          </p>
                        </div>
                        <div className="col-auto">
                          <div className="bg-lightgreen p-2 rounded-2 d-flex align-items-center">
                            <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
                              <svg className="icon fs-22 text-white">
                                <use href="#icon_won"></use>
                              </svg>
                            </div>
                            <span className="fw-600 text-green">
                              {quote?.clickup_status}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="bg-lightblue p-2 rounded-2 d-flex align-items-center">
                            <div className="w30px h30px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
                              <svg className="icon fs-22 text-white">
                                <use href="#icon_transit"></use>
                              </svg>
                            </div>
                            <span className="fw-600 text-blue">
                              {quote?.shipment_status}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => handleQuote(quote)}
                            type="button"
                            className="quotes-btn d-flex justify-content-between px-3"
                          >
                            <span>Quotes</span>
                            <svg className="icon fs-22">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div
                        style={{ height: "1px", background: "#E0E0E0" }}
                      ></div>
                      <div className="row py-3 px-4 gap-4">
                        <div className="col">
                          <p>
                            Created:{" "}
                            {getCreatedDate(quote?.createdAt)?.formattedDate}{" "}
                            {getCreatedDate(quote?.createdAt)?.sinceText}
                          </p>
                        </div>
                        <div className="col-auto">
                          <p>Created By: {quote?.created_by}</p>
                        </div>
                        <div className="col-auto">
                          <p>Total Quotes: {quote?.quote_count}</p>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => handleTracking(quote, "quotes")}
                            type="button"
                            className="quotes-btn d-flex justify-content-between px-3"
                          >
                            <span>Tracking</span>
                            <svg className="icon fs-22">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                {dataFetchedPillsQuotes && quoteDetailsFetched?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No opportunities available</h1>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "tracking",
                  "show active",
                  ""
                )}`}
                id="pills-tracking"
                role="tabpanel"
                aria-labelledby="pills-tracking-tab"
                tabIndex="5"
              >
                <div className=" pb-4">
                  <div className="d-flex me-auto align-items-center justify-content-center ">
                    <button
                      onClick={handleShow2}
                      style={{ maxWidth: "fit-content" }}
                      className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center "
                    >
                      Add Booking Number
                    </button>
                  </div>
                </div>

                {!dataFetchedPillsTracking && (
                  <div className="w-100 text-center">
                    <Loader />
                  </div>
                )}

                {dataFetchedPillsTracking &&
                  bookingList?.map((booking, index) => (
                    <div key={booking?.booking_id} className="border-card mb-4">
                      <div className="row py-3 px-4 gap-4">
                        <div className="col d-flex align-items-center">
                          <p className="fw-700 hn">
                            Booking Number: {booking?.booking_id}
                          </p>
                        </div>
                        <div className="col d-flex align-items-center">
                          <p className="fw-700 hn">
                            Carrier:{" "}
                            {booking?.career_scac &&
                              getCarrierName(booking?.career_scac)}
                          </p>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() =>
                              handleTracking(booking.quote, "tracking")
                            }
                            type="button"
                            className="quotes-btn d-flex justify-content-between "
                          >
                            <span>Tracking</span>
                            <svg className="icon fs-22">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                {dataFetchedPillsTracking && bookingList?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No Bookings available</h1>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "purchase-order",
                  "show active",
                  ""
                )}`}
                id="pills-purchase-order"
                role="tabpanel"
                aria-labelledby="pills-purchase-order-tab"
                tabIndex="6"
              >

                <div className="d-flex">
                
                  {conditionalExp(
                    customer?.everest_type,
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      style={{
                        width: "200px",
                        marginTop: "10px",
                        display: "none",
                      }}
                      value={orderType}
                      onChange={(e) => setOrderType(e.target.value)}
                    >
                      <option value="">Select Order Type</option>
                      <option value="I">International</option>
                      <option value="D">Domestic</option>
                    </select>
                  )}
                  <select
                    className="form-select mx-2"
                    aria-label="Default select example"
                    style={{ width: "200px", marginTop: "10px" }}
                    value={coo}
                    onChange={(e) => setCoo(e.target.value)}
                  >
                    <option value="">Select Origin Country</option>
                    {cooOptions?.map((option) => (
                      <option key={"coo" + option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    style={{ width: "200px", marginTop: "10px" }}
                    value={statusF}
                    onChange={(e) => setstatusF(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    {statusFOptions?.map((option) => (
                      <option key={"status f" + option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {conditionalExp(
                    !customer?.everest_type,
                    <div className="ms-auto d-flex">
                    <button
                      type="button"
                      className="grd-btn rounded-pill"
                      onClick={() => {
                        navigate(`/${customerId}/apo`, {
                          state: {
                            previous: {
                              tab: "purchase-order",
                              selectedDate,
                              quoteDetailsFetched,
                            },
                          },
                        });
                      }}
                    >
                      Add Purchase Order
                    </button>
                  </div>
                  )}
                </div>

                {!dataFetched && (
                  <div className="w-100 text-center">
                    <Loader />
                  </div>
                )}

                {(function () {
                  if (
                    dataFetched &&
                    orderType == "" &&
                    coo == "" &&
                    statusF == ""
                  ) {
                    return mapPurchaseOrders(poList);
                  } else {
                    if (filteredPoList?.length) {
                      return mapPurchaseOrders(filteredPoList);
                    } else if (dataFetched) {
                      return (
                        <div className="w-100 text-center mt-5">
                          <h1>No Matching Purchase Orders Found</h1>
                        </div>
                      );
                    }
                  }
                })()}

                {dataFetched && poList?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No Purchase Orders available</h1>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade pt-4 ${conditionalExp(
                  previousState?.tab == "shipping-documents",
                  "show active",
                  ""
                )}`}
                id="pills-shipping-documents"
                role="tabpanel"
                aria-labelledby="pills-shipping-documents-tab"
                tabIndex="5"
              >
                {!dataFetchedPillsShipDocs && (
                  <div className="w-100 text-center">
                    <Loader />
                  </div>
                )}

                {dataFetchedPillsShipDocs &&
                  shippingDocList?.map((booking, index) => (
                    <div
                      key={booking?.booking_id}
                      className="border-card mb-4  "
                    >
                      <div className="row py-3 px-4 gap-4">
                        <div className="col  align-items-center">
                          <p className="fw-700 hn">
                            Booking Number:{" "}
                            <span className="fw-600">
                              {booking?.booking_id}
                            </span>
                          </p>
                          <p className="fw-700 hn d-inline">
                            Po Numbers:{" "}
                            {booking?.poNums?.map((num, index) => (
                              <span className="fw-600" key={num + "span"}>
                                {index == 0 ? "" : ", "}
                                {num.doc_number}
                              </span>
                            ))}
                          </p>
                          {conditionalExp(
                            booking?.quotes?.length,
                            <p className="fw-700 hn">
                              Quotes:{" "}
                              <span className="fw-600">
                                {booking?.quotes
                                  ?.map((qt) => qt?.custom_task_id)
                                  ?.join(", ")}
                              </span>
                            </p>,
                            null
                          )}
                        </div>
                        <div className="col  align-items-center">
                          <p className="fw-700 hn">
                            Carrier:{" "}
                            {booking?.career_scac &&
                              getCarrierName(booking?.career_scac)}
                          </p>
                          {booking?.missingDocuments
                            ?.custom_declration_number && (
                            <p className="fw-700 hn">
                              Custom Declaration Number:
                              {
                                booking?.missingDocuments
                                  ?.custom_declration_number
                              }
                            </p>
                          )}
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => {
                              setSelectedBookingid(booking?.booking_id);
                              handleShowAddAgentsAdd();
                              setbookingAgentsWithCostArray(
                                booking?.agentsWithCost
                              );
                            }}
                            type="button"
                            style={{ minWidth: "max-content" }}
                            className="quotes-btn d-flex justify-content-between "
                          >
                            Add Additional Cost
                          </button>
                        </div>
                        <div className="col-auto">
                          <select
                            className="form-select rounded-pill"
                            aria-label="Assign Custom Broker"
                            value={booking?.broker_id || ""}
                            disabled={booking?.broker_id}
                            onChange={(e) => {
                              setSelectedBookingid(booking?.booking_id);
                              setSelectedBrokerId(e.target.value);
                              handleShowCustomBrokerModal();
                            }}
                          >
                            <option value="">Assign Custom Broker</option>
                            {allBrokers
                              ?.filter((item) => item?.type == "custom_broker")
                              ?.map((item) => (
                                <option
                                  key={item?.broker_id + item?.company_name}
                                  value={item?.broker_id}
                                >
                                  {item?.company_name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => {
                              setSelectedBookingid(booking?.booking_id);
                              handleShowShippingModal();
                            }}
                            type="button"
                            style={{ minWidth: "max-content" }}
                            className="quotes-btn d-flex justify-content-between "
                          >
                            Add Document
                          </button>
                        </div>
                        {booking?.sendMail && (
                          <div className="col-auto">
                            <button
                              onClick={() => {
                                sendMailFn(booking?.booking_id);
                              }}
                              type="button"
                              className="quotes-btn d-flex justify-content-between "
                              disabled={sendingMail}
                            >
                              {sendingMail ? <Loader /> : "Send Email"}
                            </button>
                          </div>
                        )}
                      </div>
                      <Accordion
                        key={"Accordian" + booking?.booking_id}
                        style={{ marginTop: "2px" }}
                      >
                        <Accordion.Item
                          style={{ borderRadius: "24px", margin: "4px" }}
                          eventKey="0"
                        >
                          <Accordion.Header>Show Documents</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <p className="fw-700 hn">Shipping Documents</p>
                              {booking?.shippingDocuments?.length > 0 ? (
                                booking?.shippingDocuments?.map(
                                  (doc, index) => (
                                    <div
                                      key={doc.id + "DOC"}
                                      className="rounded-pill my-2 py-2 px-3 d-flex align-items-center"
                                      style={{
                                        border: " 1px solid #F1F1F1",
                                        background: "#FDFDFD",
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        {doc &&
                                          getFileType(
                                            doc?.document_path
                                              ?.split(".")
                                              ?.slice(-1)
                                          )}
                                        <div>
                                          <b>
                                            <p>{doc.document_name}</p>
                                          </b>
                                          <i>
                                            <strong>Uploaded By:</strong>{" "}
                                            {doc.user_full_name}
                                          </i>
                                        </div>
                                      </div>
                                      <div className="icon-btn ms-auto">
                                        <button
                                          onClick={() =>
                                            handlePdfViewShipping(doc)
                                          }
                                          type="button"
                                          className="quotes-btn2 d-flex justify-content-center  d-block"
                                        >
                                          <svg className="icon fs-22">
                                            <use href="#icon_eye"></use>
                                          </svg>
                                        </button>
                                        <button
                                          onClick={() =>
                                            downloadFile(
                                              doc.document_path,
                                              doc.document_name
                                            )
                                          }
                                          type="button"
                                          className=""
                                        >
                                          <svg className="icon fs-22">
                                            <use href="#icon_download"></use>
                                          </svg>
                                        </button>

                                        <button
                                          onClick={() => handleShowDelete(doc)}
                                          type="button"
                                          className="delete-btn"
                                        >
                                          <svg className="icon fs-22">
                                            <use href="#icon_delete"></use>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <p>No shipping documents available</p>
                              )}
                            </div>
                            <div>
                              {booking?.customDocuments?.length > 0 ? (
                                <>
                                  <p className="fw-700 hn">Custom Documents</p>
                                  {booking?.customDocuments?.map(
                                    (doc, index) => (
                                      <div
                                        key={doc.id + "cDOC"}
                                        className="rounded-pill my-2 py-2 px-3 d-flex align-items-center"
                                        style={{
                                          border: " 1px solid #F1F1F1",
                                          background: "#FDFDFD",
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          {doc &&
                                            getFileType(
                                              doc?.document_path
                                                ?.split(".")
                                                ?.slice(-1)
                                            )}
                                          <div>
                                            <b>
                                              <p>{doc.document_name}</p>
                                            </b>
                                            {/* <i>
                                        <strong>Uploaded By:</strong>{" "}
                                        {doc.user_full_name}
                                      </i> */}
                                          </div>
                                        </div>
                                        <div className="icon-btn ms-auto">
                                          <button
                                            onClick={() =>
                                              handlePdfViewShipping(doc)
                                            }
                                            type="button"
                                            className="quotes-btn2 d-flex justify-content-center   d-block"
                                          >
                                            <svg className="icon fs-22">
                                              <use href="#icon_eye"></use>
                                            </svg>
                                          </button>
                                          <button
                                            onClick={() =>
                                              downloadFile(
                                                doc.document_path,
                                                doc.document_name
                                              )
                                            }
                                            type="button"
                                            className=""
                                          >
                                            <svg className="icon fs-22">
                                              <use href="#icon_download"></use>
                                            </svg>
                                          </button>
                                          {/* <button
                                              onClick={() => handleFileView({...doc,document_type:doc.document_name})}
                                              type="button"
                                              className=""
                                            >
                                              <svg className="icon fs-22">
                                                <use href="#icon_eye"></use>
                                              </svg>
                                            </button> */}

                                          <button
                                            onClick={() =>
                                              handleShowDelete(doc)
                                            }
                                            type="button"
                                            className="delete-btn"
                                          >
                                            <svg className="icon fs-22">
                                              <use href="#icon_delete"></use>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div>
                              {booking?.missingDocuments?.missing_document && (
                                <>
                                  <p className="fw-700 hn">Missing Documents</p>
                                  <p>
                                    {
                                      booking?.missingDocuments
                                        ?.missing_document
                                    }
                                  </p>
                                </>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}

                {dataFetchedPillsShipDocs && shippingDocList?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No Bookings available</h1>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade pt-4 ${conditionalExp(
                  previousState?.tab == "vendor-supplier",
                  "show active",
                  ""
                )}`}
                id="pills-vendor"
                role="tabpanel"
                aria-labelledby="pills-vendor-documents-tab"
                tabIndex="8"
              >
                <div className="">
                  <h2 className="fw-700 fs-24 text-center py-3">
                    Vendors Details
                  </h2>
                  <div className="d-flex">
                    <div className="w-25">
                      <input
                        type="search"
                        class="form-control rounded-pill"
                        placeholder="Search Vendor Name"
                        id="datatable-search-input"
                        value={vendorSearchInput}
                        onChange={(e) => {
                          setVendorSearchInput(e.target.value);
                          setvendorListDetails([]);
                        }}
                      />
                    </div>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="grd-btn rounded-pill"
                        onClick={() => {
                          setaddNewVendorDetails({
                            customer_id: customerId,
                            company_name: "",
                            origin_country: "",
                            contact_1_name: "",
                            contact_2_name: "",
                            contact_1_email: "",
                            contact_2_email: "",
                            contact_phone: "",
                            communication_mode: "",
                            nearest_sea_port_code: "",
                            nearest_sea_port_name: "",
                            nearest_air_port_code: "",
                            nearest_air_port_name: "",
                            nearest_land_port_code: "",
                            nearest_land_port_name: "",
                          });
                          setselectedOptionCountry(null);
                          setSelectedOptionPort(null);
                          handleShowvendorAdd();
                        }}
                      >
                        Add Vendor
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-100 overflow-auto vendor-tbl">
                  <table class="table text-center my-4 border">
                    <thead>
                      <tr className="border" style={{ background: "#EBEEFF" }}>
                        <th>Vendor Code</th>
                        <th>Company Name</th>
                        <th>Person 1 Name</th>
                        <th>Email 1</th>
                        <th>Person 2 Name</th>
                        <th>Email 2</th>
                        <th>Phone</th>
                        <th>We chat or other communication way</th>
                        <th>Origin Country</th>
                        <th>Nearest Sea Port of Loading</th>
                        <th>Nearest Air Port of Loading</th>
                        <th>Nearest Inland Transport</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendorListDetails?.map((item, index) => (
                        <tr key={item?.vendor_name + item?.county}>
                          <td>{item?.vendor_code}</td>
                          <td>{item?.vendor_name}</td>
                          <td>{item?.contact_1_name}</td>
                          <td>{item?.contact_1_email}</td>
                          <td>{item?.contact_2_name}</td>
                          <td>{item?.contact_2_email}</td>
                          <td>{item?.contact_1_phone1}</td>
                          <td>{item?.communication_mode}</td>
                          <td>{item?.county}</td>
                          <td>{item?.nearest_sea_port_name}</td>
                          <td>{item?.nearest_air_port_name}</td>
                          <td>{item?.nearest_land_port_name}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <button
                                type="button"
                                className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                                onClick={() => {
                                  setaddNewVendorDetails({
                                    customer_id: customerId,
                                    company_name: item?.vendor_name,
                                    origin_country: "",
                                    contact_1_name: item?.contact_1_name,
                                    contact_1_email: item?.contact_1_email,
                                    contact_2_name: item?.contact_2_name,
                                    contact_2_email: item?.contact_2_email,
                                    contact_phone: item?.contact_1_phone1,
                                    communication_mode:
                                      item?.communication_mode,
                                    nearest_sea_port_code: "",
                                    nearest_sea_port_name: "",
                                    nearest_air_port_code:
                                      item?.nearest_air_port_code,
                                    nearest_air_port_name:
                                      item?.nearest_air_port_name,
                                    nearest_land_port_code:
                                      item?.nearest_land_port_code,
                                    nearest_land_port_name:
                                      item?.nearest_land_port_name,
                                    vendor_id: item?.vendor_id,
                                    vendor_code: item?.vendor_code,
                                  });
                                  setselectedOptionCountry({
                                    label: item?.county,
                                    value: item?.county,
                                  });
                                  setSelectedOptionPort({
                                    label: `${item?.nearest_sea_port_name} | ${item?.nearest_sea_port_code}`,
                                    value: `${item?.nearest_sea_port_name} | ${item?.nearest_sea_port_code}`,
                                  });
                                  handleShowvendorAdd();
                                }}
                              >
                                <svg className="icon ms-2 fs-22">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>
                              {conditionalExp(
                                !customer?.everest_type,
                                <button
                                  type="button"
                                  className="grd-btn rounded-pill d-flex align-items-center"
                                  onClick={() => {
                                    navigate(`/${customerId}/apo`, {
                                      state: {
                                        vendor_name: item?.vendor_name,
                                      },
                                    });
                                  }}
                                >
                                  <span>Add PO</span>
                                  <svg className="icon ms-2 fs-18">
                                    <use href="#icon_arrow"></use>
                                  </svg>
                                </button>,
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className={`tab-pane fade pt-4 ${conditionalExp(
                  previousState?.tab == "SKU-Catalogue",
                  "show active",
                  ""
                )}`}
                id="pills-SKU-Catalogue"
                role="tabpanel"
                aria-labelledby="pills-SKU-Catalogue"
                tabIndex="8"
              >
                <div className="">
                  <h2 className="fw-700 fs-24 text-center py-3">SKU Management</h2>
                  <div className="d-flex">
                  <div className="d-flex ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      getSKUListHome(
                        customerId,
                        setskuListDetails,
                        setskuListDetailsRaw,
                        navigate,
                        setIsloading,
                            setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput,'reload'
                      );;
                    }}
                    className="d-flex mt-2"
                  >
                    <input
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      type="search"
                      className="form-control rounded-pill  d-block"
                      style={{ width: "400px", alignSelf: "start" }}
                      placeholder="Search Item Code or Name"
                      id="datatable-search-input"
                    />
                    <button
                      type="submit"
                      className="grd-btn mx-2"
                      disabled={!searchInput}
                    >
                      <CustomIcon name="icon_search" className="fs-5" />
                    </button>
                  </form>
                </div>
                    <div className="ms-auto d-flex">
                     
                      <div className="me-4">
                        <button
                          type="button"
                          className="grd-btn rounded-pill"
                          onClick={() => {
                            xlsxExport('sku_list','skuListTable')
                            }}
                        >
                          Download Exel
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="grd-btn rounded-pill"
                          onClick={() => {
                            setaddSKUDetails({
                              customer_id: customerId,
                              item_code: "",
                              item_name: "",
                              pallet_type: "",
                              tax_code: "",
                              unit_cost: "",
                              boxes_per_pallet: "",
                              package_factor: "",
                              boxes_per_layer: "",
                              pallet_length: "",
                              pallet_width: "",
                              pallet_height: "",
                              c_length: "",
                              c_width: "",
                              c_height: "",
                              item_weight: "",
                              minimum_request_qty: "",
                              stackable_pallet: "",
                              no_of_stack: "",
                              capacity: "",
                              brand: "",
                              brand_classification: "",
                              vendor_code: "",
                              supplier_country: "",
                              item_country: "",
                              group_name: "",
                              units_per_box: "",
                              units_per_pallet: "",
                              purchase_status: "",
                              sales_status: "",
                              inactive_status: "",
                              bar_code: "",
                              alcohal_grad: "",
                              incoterm: "",
                              stock_qty: "",
                            });
                            handleShowSKUAdd();
                          }}
                        >
                          Add SKU
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="grd-btn rounded-pill mx-3"
                          onClick={() => {
                            handleShowSKUBULKAdd();
                          }}
                        >
                          Bulk Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 overflow-auto vendor-tbl">
                  <table id="skuListTable" class="table text-center my-4 border">
                    <thead>
                      <tr className="border" style={{ background: "#EBEEFF" }}>
                      <th></th>
                      <th>Item Code</th>
                    {/* <th>Item Group Code</th> */}
                    <th>Item Name</th>
                    <th>Minimum Request Qty</th>
                    <th>Pallet Type</th>
                    <th>Pallet Height (mm)</th>
                    <th>Pallet Length (mm)</th>
                    <th>Pallet Width (mm)</th>
                    <th>Stackable Pallet</th>
                    <th>No Of Stack</th>
                    <th>Tax Code</th>
                    <th>Capacity</th>
                    <th>Brand</th>
                    <th>Brand Classification</th>
                    <th>Vendor Code</th>
                    <th>Supplier Country</th>
                    <th>Item Country</th>
                    <th>Group Name</th>
                    <th>Boxes Per Layer</th>
                    <th>Boxes Per Pallet</th>
                    <th>Box Height (mm)</th>
                    <th>Box length (mm)</th>
                    <th>Box width (mm)</th>
                    <th>Units Per Box</th>
                    <th>Units Per Pallet</th>
                    <th>Unit Cost (USD)</th>
                    {/* <th>Origin Currency</th> */}
                    <th>Purchase Status</th>
                    <th>Sales Status</th>
                    <th>Inactive Status</th>
                    <th>Barcode</th>
                    <th>Alcohol %</th>
                    <th>Incoterm</th>
                    <th>Stock Qty</th>
                    <th>Unit Weight(grams)</th>
                    <th>Package Factor</th>
                      </tr>
                    </thead>
                    <tbody>
                    {skuListDetails?.map((row, index) => (
                    <tr
                    ref={
                      skuListDetails?.length == index + 1 ? lastBookElementRef : tempRef
                    }
                      key={row?.item_code + "itrems" + index}
                      style={{ overflow: "visible" }}
                    >
                      <td><button
                          onClick={() => {
                            setaddSKUDetails({
                              customer_id: customerId,
                              ...row,
                            });
                            handleShowSKUAdd();
                          }}
                          type="button"
                          className="p-0 m-0 border-0 bg-body me-4 edit-btn"
                        >
                          <svg className="icon">
                            <use href="#icon_edit"></use>
                          </svg>
                        </button></td>
                      <td>{row.item_code}</td>
                      {/* <td>{row.items_group_code}</td> */}
                      <td>{row.item_name}</td>
                      <td>{row.minimum_request_qty}</td>
                      <td>{row.pallet_type}</td>
                      <td>{row.pallet_height}</td>
                      <td>{row.pallet_length}</td>
                      <td>{row.pallet_width}</td>
                      <td>{row.stackable_pallet}</td>
                      <td>{row.no_of_stack}</td>
                      <td>{row.tax_code}</td>
                      <td>{row.capacity}</td>
                      <td>{row.brand}</td>
                      <td>{row.brand_classification}</td>
                      <td>{row.vendor_code}</td>
                      <td>{row.supplier_country}</td>
                      <td>{row.item_country}</td>
                      <td>{row.group_name}</td>
                      <td>{row.boxes_per_layer}</td>
                      <td>{row.boxes_per_pallet}</td>
                      <td>{row.c_height}</td>
                      <td>{row.c_length}</td>
                      <td>{row.c_width}</td>
                      <td>{row.units_per_box}</td>
                      <td>{row.units_per_pallet}</td>
                      <td>{row.unit_cost}</td>
                      {/* <td>{row.origin_currency}</td> */}
                      <td>{row.purchase_status}</td>
                      <td>{row.sales_status}</td>
                      <td>{row.inactive_status}</td>
                      <td>{row.bar_code}</td>
                      <td>{row.alcohal_grad}</td>
                      <td>{row.incoterm}</td>
                      <td>{row.stock_qty}</td>
                      <td>{row.item_weight}</td>
                      <td>{row.package_factor}</td>
                    </tr>
                  ))}

                    </tbody>
                  </table>
                  {conditionalExp(
                isloading == false && skuListDetails.length == 0,
                <p className="w-full  text-center">No SKU found</p>
              )}
              {conditionalExp(
                isloading,
                <div className="w-full text-center">
                  <Loader />
                </div>
              )}
                </div>
              </div>
              <div
                className={`tab-pane fade pt-4 ${conditionalExp(
                  previousState?.tab == "WAREHOUSE",
                  "show active",
                  ""
                )}`}
                id="pills-warehouse"
                role="tabpanel"
                aria-labelledby="pills-warehouse-tab"
                tabIndex="8"
              >
                <div className="">
                  <h2 className="fw-700 fs-24 text-center py-3">
                    Warehouse Details
                  </h2>
                  <div className="d-flex">
                    <div className="w-25">
                      <input
                        type="search"
                        class="form-control rounded-pill"
                        placeholder="Search warehouse name"
                        id="datatable-search-input"
                        onChange={(e) => {
                          let { value } = e.target;
                          setwarehouseListDetails(
                            warehouseListDetailsRaw?.filter((item) =>
                              item?.warehouse_name
                                ?.toLowerCase()
                                ?.includes(value?.toLowerCase())
                            )
                          );
                        }}
                      />
                    </div>
                    <div className="ms-auto">
                      {conditionalExp(
                        customer?.everest_type,
                        <button
                          type="button"
                          disabled={isLoadingWarehoseSync}
                          className="grd-btn rounded-pill"
                          onClick={() => {
                            syncWarehouseFromSap(
                              setIsLoadingWarehoseSync,
                              navigate,
                              () => {
                                getWarehouseList(
                                  customerId,
                                  setwarehouseListDetailsRaw,
                                  setwarehouseListDetails,
                                  navigate,
                                  setIsgettingwarehouselist
                                );
                              },
                              customerId,
                              {
                                setShowSuccess,
                                setsuccessMessagePopup,
                                setShowErrModal,
                                setError,
                              }
                            );
                            console.log("Sync From ERP");
                          }}
                        >
                          {conditionalExp(
                            isLoadingWarehoseSync,
                            <Loader />,
                            "Sync From ERP"
                          )}
                        </button>,
                        <button
                          type="button"
                          className="grd-btn rounded-pill"
                          onClick={() => {
                            setaddNewVendorDetails({
                              customer_id: customerId,
                              company_name: "",
                              warehouse_code: "",
                              warehouse_name: "",
                              city: "",
                              state: "",
                              country: "",
                              zip_cdoe: "",
                            });

                            handleShowwarehouseAdd();
                          }}
                        >
                          Add Warehouse
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100 overflow-auto vendor-tbl">
                  <table class="table text-center my-4 border">
                    <thead>
                      <tr className="border" style={{ background: "#EBEEFF" }}>
                        {conditionalExp(
                          customer?.everest_type,
                          <th>Company Name</th>,
                          ""
                        )}
                        <th>Warehouse Code</th>
                        <th>Warehouse Name</th>
                        <th>City </th>
                        <th>State </th>
                        <th>Country </th>
                        <th>Zip Code</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseListDetails?.map((item, index) => (
                        <tr key={item?.warehouse_code + item?.country}>
                          {conditionalExp(
                            customer?.everest_type,
                            <td>{item?.company_name}</td>,
                            ""
                          )}
                          <td>{item?.warehouse_code}</td>
                          <td>{item?.warehouse_name}</td>
                          <td>{item?.city}</td>
                          <td>{item?.state}</td>
                          <td>{item?.country}</td>
                          <td>{item?.zip_cdoe}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              {conditionalExp(
                                !customer?.everest_type,
                                <button
                                  type="button"
                                  className="p-0 m-0 border-0 bg-body edit-btn"
                                  onClick={() => {
                                    setaddNewWarehouseDetails({
                                      customer_id: customerId,
                                      warehouse_id: item?.warehouse_id,
                                      company_name: item?.company_name,
                                      warehouse_code: item?.warehouse_code,
                                      warehouse_name: item?.warehouse_name,
                                      city: item?.city,
                                      state: item?.state,
                                      country: item?.country,
                                      zip_cdoe: item?.zip_cdoe,
                                    });
                                    handleShowwarehouseAdd();
                                  }}
                                >
                                  <svg className="icon ms-2 fs-22">
                                    <use href="#icon_edit"></use>
                                  </svg>
                                </button>,
                                ""
                              )}
                              <button
                                type="button"
                                className="py-1 mx-2 border-0 bg-body grd-btn rounded-pill"
                                onClick={() => {
                                  navigate(
                                    `/${customerId}/warehouse_users/${item.warehouse_id}/${item?.warehouse_name}`,
                                    {
                                      state: {
                                        previous: {
                                          tab: "WAREHOUSE",
                                          selectedDate,
                                          quoteDetailsFetched,
                                        },
                                      },
                                    }
                                  );
                                }}
                              >
                                <svg className="icon ms-2 fs-22">
                                  <use href="#icon_contact"></use>
                                </svg>
                                &nbsp; Users
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {conditionalExp(isgettingwarehouselist, <Loader />, null)}
                </div>
              </div>
              <div
                className={`tab-pane fade pt-4 ${conditionalExp(
                  previousState?.tab == "tag-calculation",
                  "show active",
                  ""
                )}`}
                id="pills-tag-calculation"
                role="tabpanel"
                aria-labelledby="pills-tag-calculation-tab"
                tabIndex="8"
              >
                <div className="d-flex pt-2">
                  <div className="ms-auto d-flex">
                    <button
                      type="button"
                      className="grd-btn rounded-pill me-4"
                      onClick={handleShowrange}
                    >
                      Add Tag Range
                    </button>
                    <button
                      type="button"
                      className="grd-btn rounded-pill"
                      onClick={handleShowdestroy}
                    >
                      Add Destroy Tags
                    </button>
                  </div>
                </div>
                <div className="d-flex pt-4">
                  <div className="w-25">
                    <RangePicker
                      onChange={(values) => {
                        if (!values) {
                          setselectedDateTagList([]);
                        } else {
                          setselectedDateTagList(
                            values?.map((item) => {
                              return getFormattedDate(item);
                            })
                          );
                        }
                      }}
                      style={{ height: "38px" }}
                      format={dateFormat}
                    />
                  </div>
                  <div className="ms-auto">
                    <button
                      type="button"
                      onClick={handleExportToExel}
                      className="grd-btn rounded-pill"
                    >
                      {conditionalExp(
                        isExportingToExel,
                        <Loader />,
                        "Export In Excel"
                      )}
                    </button>
                  </div>
                </div>
                <div
                  ref={tagCalculationTblRef}
                  className="w-100  overflow-scroll vendor-tbl"
                >
                  <table class="table text-center my-4 border ">
                    <thead>
                      <tr className="border" style={{ background: "#EBEEFF" }}>
                        <th>CLAVE SAP (SKU)</th>
                        <th>PRODUCTO (Description)</th>
                        <th>Numero (Number)</th>
                        <th>A4 (Custom Broker Number)</th>
                        <th>G1</th>
                        <th>PAIS (Origin)</th>
                        <th>CAPA (Capacity)</th>
                        <th>Grad (%)</th>
                        <th>FECHA (Registered Date)</th>
                        <th>SERIE (Series)</th>
                        <th>INICIO (Starting)</th>
                        <th>Final</th>
                        <th>SUMA (SUM) </th>
                        <th>Total</th>
                        <th>LUGAR (Warehouse)</th>
                        <th>OK</th>
                        <th>FECHA DE PAGO (Tax Paid Date)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tagCalculationList?.map((tag, index) => (
                        <tr key={tag?.item_code + tag?.id}>
                          <td>{tag?.item_code}</td>
                          <td>{tag?.item_description}</td>
                          <td>{tag?.number}</td>
                          <td>{tag?.custom_broker_number}</td>
                          <td>{tag?.g1}</td>
                          <td>{tag?.origin_country}</td>
                          <td>{tag?.capacity}</td>
                          <td>{tag?.grad}</td>
                          <td>{tag?.registered_date}</td>
                          <td>{tag?.series}</td>
                          <td>{tag?.starting_tags}</td>
                          <td>{tag?.ending_tags}</td>
                          <td>{tag?.tags_qty}</td>
                          <td>{tag?.total_qty}</td>
                          <td>{tag?.warehouse_name}</td>
                          <td>{tag?.ok}</td>
                          <td>
                            {conditionalExp(
                              tag?.tax_paid_date,
                              moment(tag?.tax_paid_date).format("DD-MM-YYYY"),
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {conditionalExp(
                    !isLoadingTagCalList && !tagCalculationList?.length,
                    <h4
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      No Items
                    </h4>,
                    null
                  )}
                  {conditionalExp(isLoadingTagCalList, <Loader />, null)}
                </div>
              </div>
              <div
                className={`tab-pane fade  ${conditionalExp(
                  previousState?.tab == "sku-footprint",
                  "show active",
                  ""
                )}`}
                id="pills-sku-footprint"
                role="tabpanel"
                aria-labelledby="pills-sku-footprint-tab"
                tabIndex="8"
              >
                <SkuFootprint
                  skuListDetailsRaw={skuListDetailsRaw}
                  setShowErrModal={setShowErrModal}
                  setError={setError}
                />
              </div>
            </div>

            {/* Tabs End */}
          </Col>
        </Row>
      </Container>

      {/* add new tagrange modal */}
      <AddTagRangeModal
        showrange={showrange}
        handleCloserange={() => handleCloserange()}
        tagCalculationSKUList={tagCalculationSKUList}
        isLoadingTagCalSKU={isLoadingTagCalSKU}
        warehouseListDetails={warehouseListDetails}
      />

      {/* add destroed tags modal */}
      <AddDestroyedTagModal
        showdestroy={showdestroy}
        handleClosedestroy={() => handleClosedestroy()}
        tagCalculationSKUList={tagCalculationSKUList}
        isLoadingTagCalSKU={isLoadingTagCalSKU}
      />
      {/* ===Modle */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">{uploadOption}</h4>
          {conditionalExp(
            uploadOption == "International Demand Planning" ||
              uploadOption == "Domestic Demand Planning",
            <p className="text-center pb-3">
              <b>Note:</b> Only excel files are accepted. <br />
              <a
                className="text-center mb-3 "
                href={conditionalExp(
                  uploadOption == "International Demand Planning",
                  "International Demand Planning.xlsx",
                  "Domestic Demand Planning.xlsx"
                )}
                download
              >
                Download Sample File
              </a>
            </p>,
            null
          )}

          {uploadOption == "Add new documents" && (
            <div className="input-box pb-3">
              <label className="fw-600">Document Name</label>
              <input
                type="tel"
                className="w-100"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Enter document name"
              />
            </div>
          )}

          {documentsUploadModalDups("demand")}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={selectedDoc ? handleDocEdit : handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalSKUBULK}
        centered
        onHide={handleCloseSKUBULKAdd}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">BULK Upload</h4>

          <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={"SKU Import Format.xlsx"}
              download
            >
              Download Sample File
            </a>
          </p>

          {documentsUploadModalDups("sku")}
          <div className="mt-1">
            <button
              disabled={isFileUploadingSKU}
              onClick={handleFileUploadSKU}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploadingSKU, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* upload modal */}

      {/* shipping  doc upload modal */}

      <Modal
        show={showShippingDocModal}
        centered
        onHide={handleCloseShippingModal}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">Shipping Document</h4>
          <div className="input-box pb-3">
            {documentName && documentName !== "" && (
              <label className="fw-600 mb-1">Document Name</label>
            )}

            <select
              className="form-select rounded-pill"
              aria-label="Assign Custom Broker"
              value={documentName || ""}
              onChange={(e) => {
                setDocumentName(e.target.value);
                setdocumentNameError(null);
              }}
            >
              <option value="">Choose Document Name*</option>
              <option value="Commercial invoice / Factura comercial">
                Commercial invoice / Factura comercial*
              </option>
              <option value="International Treaty / Tratado">
                International Treaty / Tratado
              </option>
              {conditionalExp(
                customer?.everest_type,
                <>
                  <option value="Relationship number in SAP / Relación de SAP">
                    Relationship number in SAP / Relación de SAP*
                  </option>
                  <option value="Origin inspection / Previo">
                    Origin inspection / Previo
                  </option>
                </>,
                ""
              )}
              <option value="Packing list">Packing list*</option>
              <option value="Analisys certificate / Certificado de análisis">
                Analisys certificate / Certificado de análisis
              </option>
              <option value="Origin Certificate / Certificado de origen">
                Origin Certificate / Certificado de origen
              </option>
              <option value="MBL">MBL (master BL)*</option>
              <option value="HBL">HBL (house BL)*</option>
              <option value="Instructions letter / Carta de instrucciones">
                Instructions letter / Carta de instrucciones
                {conditionalExp(
                  customer?.country?.toLowerCase() == "mexico",
                  "*",
                  ""
                )}
              </option>
            </select>
          </div>

          {documentsUploadModalDups("shipping")}
          {documentNameError && (
            <div className="mb-2 w-100 text-danger text-center">
              {documentNameError}
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={handleUploadShippingDoc}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <SuccessModal
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        successMessagePopup={successMessagePopup}
      />

      {/* ===Modle booking id */}

      <AddBookingModal
        showAddBooking={showAddBookingModal}
        handleClose={handleClose2}
        customerId={customerId}
        getPoList={getPoList}
        getQuotes={() =>
          getQuotes(
            customerId,
            "home",
            null,
            setQuoteDetailsFetched,
            navigate,
            {
              selectedProductType,
              selectedDate,
              selectedShipment,
              clickupData,
              setCurrentClickup: null,
              setdataFetchedPillsQuotes,
              getBookingList,
            }
          )
        }
        getShippingDocuments={getShippingDocuments}
        selectedDocNumber={selectedDocNumber}
        setSelectedDocNumber={setSelectedDocNumber}
        setShowErrModal2={setShowErrModal2}
        setfinalPoError={setfinalPoError}
        quote_id={selecteQuote || null}
        carrier_code={selectedOption}
      />

      {/* add contact modal*/}
      <Modal
        show={showMemberModal}
        centered
        onHide={handleClose3}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {conditionalExp(isOrgEdit, "Edit", "Add New")} {designation} Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <form onSubmit={createOrEditMember} className="p-4">
            {designation == "Demand Planning" && (
              <div className="input-box mb-4">
                {selectedOption && selectedOption != "" && (
                  <label>Choose Member</label>
                )}
                <Select
                  placeholder="Choose Member"
                  required
                  className="w-100"
                  value={selectedOption}
                  isDisabled={isOrgEdit}
                  isClearable={true}
                  onChange={setselectedOption}
                  options={[
                    { label: "Approver", value: "approver" },
                    { label: "New Item Manager", value: "new_item_manager" },
                  ]}
                />
              </div>
            )}
            <div className="input-box mb-4">
              {contact_name && contact_name != "" && <label>Name</label>}
              <input
                required
                value={contact_name}
                onChange={(e) => setContact_name(e.target.value)}
                type="text"
                className="w-100"
                placeholder="Enter Name"
              />
            </div>
            <div className="input-box mb-4">
              {contact_email && contact_email != "" && <label>Email</label>}
              <input
                required
                value={contact_email}
                onChange={(e) => setContact_email(e.target.value)}
                type="email"
                className="w-100"
                placeholder="Enter email"
              />
            </div>
            {designation !== "Demand Planning" && (
              <div className="input-box mb-4">
                {contact_phone && contact_phone != "" && <label>Phone</label>}
                <input
                  required
                  value={contact_phone}
                  onChange={(e) => setContact_phone(e.target.value)}
                  type="number"
                  className="w-100"
                  placeholder="Enter Phone"
                />
              </div>
            )}

            <div className="d-flex justify-content-end">
              <div>
                <p style={{ color: "red" }}>{errormsg}</p>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                >
                  {conditionalExp(isLoading, <Loader />, "Submit")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* pdf modal */}

      <Modal
        show={showimgpop}
        centered
        onHide={() => {
          setShowimgpop(false);
          setImgURL("");
          setSelectedPoItems([]);
        }}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            height: "calc(90vh - 56px)",
            padding: 0,
            overflowY: "scroll",
          }}
        >
          <>
            {(function () {
              if (popupTitle != "Purchase Order") {
                return pdfModalDups();
              } else {
                if (customer?.everest_type && imgURL) {
                  return pdfModalDups();
                } else if (selectedPoItems?.length > 0) {
                  return (
                    <table className="table text-center my-4 border">
                      <thead>
                        <tr
                          className="border"
                          style={{ background: "#EBEEFF" }}
                        >
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Quantity(pcs)</th>
                          <th>Volume CBM</th>
                          <th># Pallets</th>
                          <th>Unit Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPoItems.map((item, index) => (
                          <tr key={"poitem" + item?.item_code}>
                            <td>{item?.item_code}</td>
                            <td>{item?.item_description}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.volume}</td>
                            <td>{item?.pallet}</td>
                            <td>{item?.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  );
                } else {
                  return (
                    <h4
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      No Items
                    </h4>
                  );
                }
              }
            })()}
          </>
        </Modal.Body>
      </Modal>

      {/* confirmation modal */}
      <Modal show={showVendor} onHide={handleCloseVendor} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want change this agent ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendor}
            >
              Close
            </Button>
            <Button
              disabled={isLoadingVendorChange}
              variant="success"
              onClick={() => {
                handleAgentChange();
              }}
            >
              {conditionalExp(!isLoadingVendorChange, "Change", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorModal} onHide={handleCloseVendorModal} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want choose this Modal ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorModal}
            >
              Close
            </Button>
            <Button
              disabled={isLoadingAgentChange}
              variant="success"
              onClick={() => {
                handleAgentChangeModalSet();
              }}
            >
              {conditionalExp(!isLoadingAgentChange, "Choose", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showQuoteSelectModal}
        onHide={handleCloseQuoteSelectModal}
        centered
      >
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want add this quote ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseQuoteSelectModal}
            >
              Close
            </Button>
            <Button
              disabled={isLoadingAddQuote}
              variant="success"
              onClick={() => {
                handleAddQuoteToPo();
              }}
            >
              {conditionalExp(!isLoadingAddQuote, "Add", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShowCustomBrokerModal}
        onHide={handleCloseCustomBrokerModal}
        centered
      >
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to Assign Custom Broker ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseCustomBrokerModal}
            >
              Close
            </Button>
            <Button
              variant="success"
              disabled={submittingBrokerData}
              onClick={() => {
                handleAssignCustomBroker();
              }}
            >
              {conditionalExp(submittingBrokerData, <Loader />, "Assign")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/*doc delete confirmation modal */}
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">Are you sure you want delete ?</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="danger"
              disabled={isLoadingDelete}
              onClick={() => {
                if (isOrgEdit) {
                  handleOrgDelete(isOrgEdit);
                } else if (
                  selectedDoc?.document_type == "shipping" ||
                  selectedDoc?.document_type == "custom"
                ) {
                  handleShippingDocDelete(
                    selectedDoc?.id,
                    selectedDoc?.booking_id
                  );
                } else {
                  handleDocDelete(selectedDoc.doc_id);
                }
              }}
            >
              {conditionalExp(!isLoadingDelete, "Delete", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Vendor Modal */}

      <AddVendorModal
        showvendorAdd={showvendorAdd}
        handleClosevendorAdd={handleClosevendorAdd}
        addNewVendorDetails={addNewVendorDetails}
        setaddNewVendorDetails={setaddNewVendorDetails}
        optionsForCountries={optionsForCountries}
        optionsForPorts={optionsForPorts}
        selectedOptionCountry={selectedOptionCountry}
        setselectedOptionCountry={setselectedOptionCountry}
        selectedOptionPort={selectedOptionPort}
        setSelectedOption={setselectedOption}
        setSelectedOptionPort={setSelectedOptionPort}
        setoptionsForPorts={setoptionsForPorts}
        customerId={customerId}
        getVendorList={() =>
          getVendorList(
            customerId,
            null,
            setvendorListDetailsRaw,
            setvendorListDetails,
            navigate
          )
        }
        setvendorListDetailsRaw={setvendorListDetailsRaw}
      />
      {/* warehouse Modal */}

      <AddWarehouseModal
        showwarehouseAdd={showwarehouseAdd}
        addNewWarehouseDetails={addNewWarehouseDetails}
        handleClosewarehouseAdd={handleClosewarehouseAdd}
        setaddNewWarehouseDetails={setaddNewWarehouseDetails}
        setwarehouseListDetailsRaw={setwarehouseListDetailsRaw}
        customerId={customerId}
        getWarehouseList={() =>
          getWarehouseList(
            customerId,
            setwarehouseListDetailsRaw,
            setwarehouseListDetails,
            navigate,
            setIsgettingwarehouselist
          )
        }
        everest_type={customer?.everest_type}
      />
      {/* Add SKU Modal*/}

      <AddSkuModal
        addSKUDetails={addSKUDetails}
        addSKUDetailsError={addSKUDetailsError}
        setaddSKUDetails={setaddSKUDetails}
        setaddSKUDetailsError={setaddSKUDetailsError}
        customerId={customerId}
        getSKUList={() =>
          getSKUListHome(
            customerId,
            setskuListDetails,
            setskuListDetailsRaw,
            navigate,
            setIsloading,
            setCurrentPage,sethasMore,skuListDetails,currentPage,searchInput,'reload'
          )
        }
        handleCloseSKUAdd={handleCloseSKUAdd}
        showSKUAdd={showSKUAdd}
      />

      {/* add agents cost modal */}

      <Modal
        show={showAddAgentsAdd}
        centered
        onHide={handleCloseAddAgentsAdd}
        // className="tracking-modal"
        className="modal-lg"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>

        <Modal.Body className="px-4">
          <p className="text-center fs-26 fw-bold pb-2">Add Additional Cost </p>
          <Row className="mt-3">
            <Col md={6}>
              <div className="mb-3 input-box">
                {selectedAgentNameFromModal && (
                  <label className="mb-1 ">Agent Name</label>
                )}

                <Select
                  value={selectedAgentNameFromModal}
                  onChange={(row) => {
                    setselectedAgentNameFromModal(row);
                    setaddAddNewCostDetailsError({
                      ...addAddNewCostDetailsError,
                      agent_name: null,
                    });
                  }}
                  options={addNewCostOptions}
                  isSearchable={true}
                  placeholder="Add Agent"
                  isClearable
                  isLoading={!addNewCostOptions?.length}
                />
              </div>
              {addAddNewCostDetailsError.agent_name && (
                <div className="text-danger mb-3">
                  {addAddNewCostDetailsError.agent_name}
                </div>
              )}

              <div className="input-box mb-3">
                {addAddNewCostDetails.cost &&
                  addAddNewCostDetails.cost != "" && (
                    <label htmlFor="cost">Cost *</label>
                  )}
                <input
                  type="number"
                  className="w-100"
                  id="cost"
                  required
                  value={addAddNewCostDetails.cost}
                  onChange={(e) => {
                    setaddAddNewCostDetails({
                      ...addAddNewCostDetails,
                      cost: e.target.value,
                    });
                    setaddAddNewCostDetailsError({
                      ...addAddNewCostDetailsError,
                      cost: null,
                    });
                  }}
                  placeholder="Enter Cost*"
                />
              </div>
              {addAddNewCostDetailsError.cost && (
                <div className="text-danger mb-3">
                  {addAddNewCostDetailsError.cost}
                </div>
              )}

              <div className="d-flex">
                <div className="ms-auto">
                  <button
                    className="grd-btn rounded-pill"
                    onClick={() => {
                      handleAddAgentCost();
                    }}
                    disabled={isLoading}
                  >
                    {conditionalExp(isLoading, <Loader />, "Submit")}
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="col">
                {bookingAgentsWithCostArray?.length == 0 && (
                  <p className="text-center fs-26  pb-2">
                    No agents & cost added yet
                  </p>
                )}

                {bookingAgentsWithCostArray &&
                  bookingAgentsWithCostArray?.length > 0 && (
                    <div className="fw-600 ">
                      <div className="w-100 overflow-auto vendor-tbl">
                        <table class="table text-center  border">
                          <thead>
                            <tr
                              className="border"
                              style={{ background: "#EBEEFF" }}
                            >
                              <th>Agent Name</th>
                              <th>Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {conditionalExp(
                              bookingAgentsWithCostArray?.length,
                              bookingAgentsWithCostArray?.map((item, index) => (
                                <tr key={"item" + item?.broker_id + item?.cost}>
                                  <td>{getAgentName(item?.broker_id)}</td>
                                  <td>{item?.cost}</td>
                                </tr>
                              )),
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* error show modal */}
      <Modal show={showErrModal} onHide={() => setShowErrModal(false)} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center text-danger">
            <b>{error?.title}</b>
          </p>
          <p className="fs-4 text-center text-danger">{error?.err}</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={() => setShowErrModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showErrModal2}
        onHide={() => setShowErrModal2(false)}
        centered
      >
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center text-danger pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <h3>Error</h3>
          <h4>{finalPoError}</h4>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="primary"
              className=""
              onClick={() => setShowErrModal2(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Drawer
        title="Arrived Containers"
        placement="right"
        closable={true}
        onClose={() => setDrVisible(false)}
        open={drVisible}
      >
        <div className="overflow-auto">
          {conditionalExp(
            arrivedContainers?.length,
            arrivedContainers?.map((container) => (
              <>
                <div>
                  <p>
                    <span className="fw-700">Container Number:</span>{" "}
                    {container.container_id}
                  </p>
                  <p>
                    <span className="fw-700">Arrival Date:</span>{" "}
                    {container.arrival_date}
                  </p>
                  <div>
                    {container?.proofs?.map((item, index) => (
                      <>
                        <div
                          className="bg-gray border  px-3 rounded-3 mt-1 d-flex align-items-center"
                          key={item?.document_name + "proof"}
                        >
                          <div className="d-flex align-items-center">
                            {getTypeDoc(item?.document_path)}
                            {/* <div>
                                 <svg className="icon fs-22">
                                   <use href="#icon_pdf"></use>
                                 </svg>
                               </div> */}
                            <div className="ms-3">
                              <p className="fw-400 fs-16 py-2">
                                {item.document_name}
                              </p>
                              {/* <span className='text-gray fw-400' style={{fontSize: "10px"}}>
                                 246 KB
                                 </span> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>{" "}
                <hr className=" border border-1 opacity-100" />
              </>
            )),
            <p>No Containers Arrived</p>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default Home;
