import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { CustomerContext } from "../context/customerContext";
import { CustomIcon } from "../components/Common";
import AssignedUsersModal from "../components/AssignedOpsModal";

function Customers_list() {
  const [customersList, setCustomersList] = useState([]);
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const {  updateCustomer } = useContext(CustomerContext);
  const [assignedList, setAssignedList] = useState([])
  const [showAssignedModal, setShowAssignedModal] = useState(false)
const [selectedCustomer, setSelectedCustomer] = useState()
  async function fetchCustomerList() {
    const token = localStorage.getItem("mtm_user_token");
    const user = JSON.parse(localStorage.getItem("mtm_user_data"));
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customers/${
          user.user_type == 1 ? "admin" : user.id
        }`,
        {
          page: currentPage,
        },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        const newCustomers = response.data.customers.filter((newCust) => {
          return !customersList.some(
            (existingCust) => existingCust.customer_id === newCust.customer_id
          );
        });

        setIsLoading(false);
        setCustomersList([...customersList, ...newCustomers]);
        setHasMore(response.data.hasMore);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }finally{
      setIsLoading(false)
    }
  }

  // Function to handle scroll and fetch more data
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      hasMore
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [hasMore]);

 
 

 async function searchCustomer(e) {
    e.preventDefault()
   setCurrentPage(0)
   setHasMore(false)
   setCustomersList([])
   
   const token = localStorage.getItem("mtm_user_token");
   const user = JSON.parse(localStorage.getItem("mtm_user_data"));
   setIsLoading(true);
   try {
     const response = await axios.post(
       `${process.env.REACT_APP_BASE_URL}/mtmclick/customers/${
         user.user_type == 1 ? "admin" : user.id
       }`,
       {
         searchInput: searchInput,
       },
       {
         headers: {
           jwt_token: token,
         },
       }
     );

     if (!response?.data?.error) {
       setSearchedCustomers(response?.data?.customers)
       
     }else{
      setSearchedCustomers([])
     }
   } catch (error) {
     if (error.response.status == 401) {
       localStorage.removeItem("mtm_user_token");
       localStorage.removeItem("mtm_user_data");
       navigate("/login");
       return;
     }
     console.log(error);
   }finally{
    setIsLoading(false);
   }
 
  }

  function getFormattedDate(dateStr) {
    const date = new Date(dateStr);

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  function handleView(customer) {
    updateCustomer(customer);
    navigate(`/${customer?.customer_id}`, {
      state: {
        previous: {
          tab: "organization",
        },
      },
    });
  }

 

  

  function mapCustomers(array){
    return array?.map((customer, index) => (
      <tr key={customer?.customer_name}>
        <td>
          <div style={{ maxWidth: "450px" }}>
            {customer?.organization_name}
          </div>
        </td>
        <td>{getFormattedDate(customer?.createdAt)}</td>
        <td>
          {JSON.parse(localStorage.getItem("mtm_user_data"))
            .user_type == 1 && <button
            onClick={() => {
              setAssignedList(customer?.ops_users)
              setSelectedCustomer(customer)
              setShowAssignedModal(true)
            }}
            type="button"
            className="quotes-btn d-flex justify-content-between px-3"
          >
            <span>Assigned Users</span>
            <svg className="icon fs-22">
              <use href="#icon_rightarrow"></use>
            </svg>
          </button>}
        </td>
        <td width="200px">
          <button
            onClick={() => handleView(customer)}
            type="button"
            className="quotes-btn d-flex justify-content-between px-3"
          >
            <span>View</span>
            <svg className="icon fs-22">
              <use href="#icon_rightarrow"></use>
            </svg>
          </button>
        </td>
      </tr>
    ))
  }

  useEffect(() => {
    let token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } 
  }, []);

  useEffect(() => {
    if(!searchInput){
      setHasMore(false)
      setCurrentPage(0)
      setSearchedCustomers([])
      fetchCustomerList()
    }
  }, [searchInput]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    fetchCustomerList();
  }, [currentPage]);

  return (
    <>
    {isLoading && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              zIndex: 100,
            }}
          >
            <Loader />
          </div>
        )}
      <NavBar />
      <Container>
        
        <Row>
          <Col md={12}>
            <div className="wrapper-box w-100 d-flex flex-column align-items-center ">
              <h2 className="fw-700 fs-30 text-center">List of Customers</h2>

              <div className="d-flex justify-content-between align-items-center w-100 ">
              <form onSubmit={searchCustomer} className="d-flex mt-2">
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  className="form-control rounded-pill  d-block"
                  style={{ width: "400px", alignSelf: "start" }}
                  placeholder="Search Organization Name"
                  id="datatable-search-input"
                />
                  <button
                        
                        type="submit"
                        className="grd-btn mx-2"
                       disabled={!searchInput}
                      >
                       <CustomIcon name="icon_search" className="fs-5" />
                      </button>
                </form>
                {JSON.parse(localStorage.getItem("mtm_user_data"))?.user_type ==
                  1 && (
                  <>
                    <div className="ms-auto">
                      <div className="row">
                        <div className="col">
                          <button
                            type="button"
                            className="grd-btn rounded-pill"
                            
                            onClick={() => navigate("/modals")}
                          >
                            Manage Modal
                          </button>
                        </div>
                        <div className="col">
                          <button
                            onClick={() => navigate("/agents")}
                            type="button"
                            className="grd-btn rounded-pill"
                            // style={{justifySelf:"flex-end"}}
                          >
                            Manage Agents
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="repeat-table">
              <table className="table text-left my-4">
                <thead>
                  <tr className="border" style={{ background: "#EBEEFF" }}>
                    <th>Organization Name</th>
                    <th>On Boarding Date</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {searchedCustomers.length
                    ? mapCustomers(searchedCustomers)
                    : mapCustomers(customersList)}
                </tbody>
              </table>
            </div>

            { searchedCustomers.length == 0 && customersList.length == 0 && !isLoading ? (
              <h2 className="  text-center">No customer found! Please contact your manager</h2>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
      <AssignedUsersModal assinedUsersList={assignedList} handleCloseAssigned={()=>{
        setAssignedList([]);
        setSelectedCustomer();
        setShowAssignedModal(false)
      } } setAssignedList={setAssignedList}  setSearchedCustomers={setSearchedCustomers} customer_id={selectedCustomer?.customer_id} organization_name={selectedCustomer?.organization_name} showAssigned={showAssignedModal}customersList={customersList}setCurrentPage={setCurrentPage}setHasMore={setHasMore}setCustomersList={setCustomersList}setIsLoading={setIsLoading}setSearchInput={setSearchInput}/>
    </>
  );
}

export default Customers_list;
