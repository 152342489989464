import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
function AddDestroyedTagModal({
  showdestroy,
  handleClosedestroy,
  tagCalculationSKUList,
  isLoadingTagCalSKU,
}) {
  const [selectedOption, setSelectedOption] = useState();
  const [skuWiseItemList, setSkuWiseItemList] = useState([]);
  const [skuWiseItemListChanged, setSkuWiseItemListChanged] = useState([]);
  const [submittingAddDestroyedTags, setsubmittingAddDestroyedTags] =
    useState(false);
  const [isLoadingSkuItems, setIsLoadingSkuItems] = useState(false);
  const [submitAddNewWarehouseError, setsubmitAddNewWarehouseError] =
    useState(null);

    function handleCloseModal() {
      setSkuWiseItemList([])
      setSelectedOption()
      setIsLoadingSkuItems(false)
      setsubmittingAddDestroyedTags(false)
      handleClosedestroy()
    }
 

  const navigate = useNavigate();

  const onSubmitDestroy = async (e) => {
    e.preventDefault()
    console.log(skuWiseItemList);
  
      const token = localStorage.getItem("mtm_user_token");
      setsubmittingAddDestroyedTags(true);
      setsubmitAddNewWarehouseError(null);
      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/addDestroyQty`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
          data: {
            item_code:selectedOption?.value,
            items:skuWiseItemListChanged?.map(i=> ({id:i?.id,destroy_tag_qty:i?.destroy_tag_qty}))?.filter(i=> i.destroy_tag_qty != "")
          },
        });
        if (response.status == 200) {
          if (!response.data.error) {
            setTimeout(() => {
              handleCloseModal();
            }, 2000);
          } else {
            setsubmitAddNewWarehouseError(response.data.message);
          }
        }
      } catch (error) {
       
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        console.log(error);
      }finally{
        setsubmittingAddDestroyedTags(false);
      }
  };

  async function getSKUWiseItems() {
    try {
      setIsLoadingSkuItems(true);
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/destroy/tagsList`,
        method: "POST",
        data: { item_code: selectedOption?.value },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setSkuWiseItemList(response.data.tagList);
          setSkuWiseItemListChanged(response.data.tagList?.map(i=>({...i,destroy_tag_qty:""})));
        } else {
          setSkuWiseItemList([]);
          setSkuWiseItemListChanged([]);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoadingSkuItems(false);
    }
  }

  useEffect(() => {
    if (selectedOption?.value) {
      getSKUWiseItems();
    }
  }, [selectedOption]);

  return (
    <Modal
      size="xl"
      show={showdestroy}
      centered
      onHide={handleCloseModal}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4 ">
        <p className="text-center fs-26 fw-bold pb-2">Add Destroy Tag</p>
        <form onSubmit={onSubmitDestroy}>
        <div className="mb-2 d-flex justify-content-center">
          <div className="w-50">
            <Select
              value={selectedOption}
              onChange={setSelectedOption}
              options={tagCalculationSKUList?.map((tag) => ({
                label: `${tag?.item_code} || ${tag?.item_description}`,
                value: tag?.item_code,
              }))}
              isSearchable={true}
              placeholder="Choose SKU"
              isClearable
              isLoading={isLoadingTagCalSKU}
            />
          </div>
        </div>
        <div
          style={{ minHeight: "25vh" }}
          className="w-100  overflow-auto vendor-tbl"
        >
          {selectedOption ? (
            <table class="table text-center my-4 border">
              <thead>
                <tr className="border" style={{ background: "#EBEEFF" }}>
                  <th>Registered Date</th>
                  <th>Tax Paid Date</th>
                  <th>Starting Number</th>
                  <th>Final Number</th>
                  <th>Total Quantity</th>
                  <th>Used Quantity <br /> <small style={{fontWeight:'lighter'}}>(Tags + Destroyed + Damaged)</small></th>
                  <th>Enter Destroy Quantity</th>
                </tr>
              </thead>
              <tbody style={{maxHeight:"100%",overflowX:"auto"}}>
                {skuWiseItemList?.map((item) => (
                  <tr key={item?.item_code + item?.id + "destroed"}>
                    <td>{moment(item?.registered_date).format("DD-MM-YYYY")}</td>
                    <td>{moment(item?.tax_paid_date).format("DD-MM-YYYY")}</td>
                    <td>{item?.starting_tags}</td>
                    <td>{item?.ending_tags}</td>
                    <td>{item?.approved_tags_qty}</td>
                    <td>{parseInt(item?.tags_qty + item?.destroy_tag_qty + item?.damage_tag_qty) }</td>
                    <td>
                      <div className="d-flex flex-column align-items-center justify-content-center">
                       
                        <input
                          disabled={parseInt(item?.approved_tags_qty) - parseInt(item?.tags_qty + item?.destroy_tag_qty + item?.damage_tag_qty ) <= 0}
                          type="number"
                          value={skuWiseItemListChanged.find(i=> i.id == item?.id)?.destroy_tag_qty}
                          style={{ width: "100px" }}
                          min={0}
                          className="form-control"
                          onChange={(e) => {
                            setSkuWiseItemListChanged((pre) =>
                              pre?.map((i) => {
                                if (i.id == item?.id) {
                                  return {
                                    ...i,
                                    destroy_tag_qty: e.target.value ,
                                  };
                                } else {
                                  return i;
                                }
                              })
                            );
                          }}
                        />
                        { parseInt(skuWiseItemListChanged.find(i=> i.id == item?.id)?.destroy_tag_qty) != ( item?.approved_tags_qty - parseInt(item?.tags_qty + item?.destroy_tag_qty + item?.damage_tag_qty)) && skuWiseItemListChanged.find(i=> i.id == item?.id)?.destroy_tag_qty ? (
                         <small className="text-danger mb-3">Destroyed tag qty cannot be more or less than { ( item?.approved_tags_qty - parseInt(item?.tags_qty + item?.destroy_tag_qty + item?.damage_tag_qty))}</small>
                        ) : ""}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4
              style={{
                height: "100%",
                width: "100%",
                textAlign: "center",
              }}
            >
              Please select SKU
            </h4>
          )}
          {selectedOption?.value &&
          !isLoadingSkuItems &&
          !skuWiseItemList.length ? (
            <h4
              style={{
                height: "100%",
                width: "100%",
                textAlign: "center",
              }}
            >
              No Tags Found
            </h4>
          ) : null}

          {
            isLoadingSkuItems ? <Loader/> : null
          }
        </div>
        <div className="d-flex">
        <p className="text-danger">{submitAddNewWarehouseError}</p>
          <div className="ms-auto d-flex">
            <button
              className="grd-btn rounded-pill py-1 me-4"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
            disabled={submittingAddDestroyedTags || !selectedOption?.value || skuWiseItemListChanged.every(i=> i?.destroy_tag_qty == "")}
              className="grd-btn rounded-pill py-1"
              type="submit"
            >
              {submittingAddDestroyedTags? <Loader/> : "Submit"}
            </button>
          </div>
        </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddDestroyedTagModal;
