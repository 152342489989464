import React  from "react";
import { Col, Container, Form, Navbar, Row } from "react-bootstrap";
import save from "./srcimages/save.png";
import { useLocation, useParams } from "react-router-dom";

function Save2() {
  const params = useParams();
  console.log(params, "params");
  const location = useLocation();
  console.log(location, "location");

  return (
    <>
      <Container>
        <Row>
        <Navbar expand="lg" style={{ zIndex: 100 }}>
        <Container>
          <Navbar.Brand  className="d-flex">
            <img
              src={window.location.origin + "/images/logo.png"}
              style={{ width: "140px", height: "40px", maxHeight: "52px" }}
            ></img>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex ms-auto">
             
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-center">
                <div style={{ width: "420px" }}>
                  <img className="w-100" src={save} />
                </div>
              </div>
              <p className="fw-bold fs-3 text-center py-4">
                {location.state.message}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Save2;
