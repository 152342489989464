import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Accordion } from "react-bootstrap";
import { CustomIcon } from "../components/Common";
import axios from "axios";
import { ContainerContext } from "../context/containerContext";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  PolylineF,
} from "@react-google-maps/api";

import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Loader from "../components/Loader";
import moment from "moment/moment";
import { AddBookingModal } from "../components/AddBookingModal";
import { conditionalExp } from "../utils/commonFn";
function Booking_number() {
  const [isLoading, setisLoading] = useState(false);
  const [containerNumber, setContainerNumber] = useState("");
  const [containerDetails, setContainerDetails] = useState({});
  const [trackingDetails, setTrackingDetails] = useState();
  const [markers, setMarkers] = useState([]);
  const [showAddBookingModal, setShowAddBookingModal] = useState(false);
  const handleClose = () => setShowAddContainer(false);

  const [errormsg, setErrormsg] = useState("");
  const { customerId } = useParams();
  const navigate = useNavigate();
  const {
    containers,
    updateContainers,
    selectedContainer,
    updateSelectedContainer,
  } = useContext(ContainerContext);

  const { showAddContainer, setShowAddContainer } = useOutletContext();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const handleClose2 = () => {
    setShowAddBookingModal(false);
    setErrormsg("");
  };

  const handleShow = () => {
    setShowAddBookingModal(true);
  };



  const { state } = useLocation();

  async function addContainer(e) {
    e.preventDefault();
    setisLoading(true);
  }

  async function getContainers() {
    updateSelectedContainer({});
    updateContainers([]);
    setTrackingDetails();
    const token = localStorage.getItem("mtm_user_token");
    setisLoading(true);
    try {
      let containers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipment/container`,
        { shipment_id: state?.quote?.shipment_id },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!containers?.data?.error) {
        updateContainers(containers?.data?.shipment_container);
        updateSelectedContainer(containers?.data?.shipment_container[0]);
        setTrackingDetails(containers?.data?.quote_shipment);
        setisLoading(false);
      } else {
        setisLoading(false);
        updateSelectedContainer();
        updateContainers([]);
        setTrackingDetails();
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
      setisLoading(false);
    }
  }

  async function fetchContainerDetails() {
    const token = localStorage.getItem("mtm_user_token");
    console.log(
      "fetch container called",
      selectedContainer?.container_tracking_id
    );
    setisLoading(true);
    try {
      let containers = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipment/states`,
        { container_tracking_id: selectedContainer?.container_tracking_id },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!containers?.data?.error) {
        console.log(containers?.data?.shipmentcontainerstates);
        setContainerDetails(containers?.data?.shipmentcontainerstates);
        setMarkers(containers?.data?.shipmentcontainerstates?.positions);
        setisLoading(false);
        let stops = containers?.data?.shipmentcontainerstates?.stops;
        setTrackingDetails({
          expected_delivery_date: stops
            .find((stop) => stop.stop_type == "PORT_OF_DISCHARGE")
            ?.event_details?.find((event) => event.event_type == "DISCHARGE")
            ?.planned_date_time || stops
            .find((stop) => stop.stop_type == "PORT_OF_DISCHARGE")
            ?.event_details?.find((event) => event.event_type == "DISCHARGE")
            ?.estimated_date_time,
          booking_date: stops.find((stop) => stop.stop_type == "ORIGIN")
            ? stops.find((stop) => stop.stop_type == "ORIGIN")?.event_details[0]
                ?.date_time
            : stops.find((stop) => stop.stop_type == "PORT_OF_LOADING")
                ?.event_details[0]?.date_time,
          product_type_name: "Ocean",
          destination_country: getLocation(
            stops.find((stop) => stop.stop_type == "PORT_OF_DISCHARGE")
              ?.location
          )?.address?.country,
          discharge_port_name: getLocation(
            stops.find((stop) => stop.stop_type == "PORT_OF_DISCHARGE")
              ?.location
          )?.address?.city,
          origin_country: stops.find((stop) => stop.stop_type == "ORIGIN")
            ? getLocation(
                stops.find((stop) => stop.stop_type == "ORIGIN")?.location
              )?.address?.country
            : getLocation(
                stops.find((stop) => stop.stop_type == "PORT_OF_LOADING")
                  ?.location
              )?.address?.country,
          loading_port_name: stops.find((stop) => stop.stop_type == "ORIGIN")
            ? getLocation(
                stops.find((stop) => stop.stop_type == "ORIGIN")?.location
              )?.address?.city
            : getLocation(
                stops.find((stop) => stop.stop_type == "PORT_OF_LOADING")
                  ?.location
              )?.address?.city,
          booking_id: trackingDetails.booking_id,
        });
      }
    } catch (err) {
      if (err.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
      setisLoading(false);
    }
  }

  function getLocation(location) {
    if (location) return JSON.parse(location);
  }

  function getMomentDate(date,withTime){
    return moment(date || null).format(
      "MMM DD YYYY"
    ) == "Invalid date"
      ? "Not Provided"
      : <>
      <p className="">
        {moment(date || null).format(
          "MMM DD YYYY"
        )}
      </p>
      {withTime && <p>
        {moment(date || null).format(
          "LT"
        )}
      </p>}
    </>
  }

  useEffect(() => {
    (async function () {
      await getContainers();
    })();
  }, []);

  useEffect(() => {
    selectedContainer && fetchContainerDetails();
  }, [selectedContainer]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Container fluid className="booking-box">
          {containers.length && containerDetails?.stops?.length ? (
            <>
              <Row>
                <Col className="col-12 py-3">
                  <div className="d-flex align-items-center justify-content-between pb-3">
                    {state?.previous?.tab == "quotes" ? (
                      <button
                        onClick={handleShow}
                        style={{ maxWidth: "fit-content" }}
                        className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center "
                      >
                        Change Booking Number
                      </button>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                    <div className="d-flex align-items-center">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_clock"></use>
                      </svg>
                      <strong>Last Update: &nbsp; </strong>
                      {containerDetails?.stops &&
                        moment(
                          containerDetails?.stops[
                            containerDetails?.stops?.length - 1
                          ]?.event_details[
                            containerDetails?.stops[
                              containerDetails?.stops?.length - 1
                            ]?.event_details?.length - 1
                          ]?.updatedAt 
                        )
                          .utcOffset("GMT-06:00")
                          .format()}
                    </div>
                  </div>
                  <h2 className="fw-700 fs-30 text-center">
                    Booking Number: {trackingDetails?.booking_id}
                  </h2>
                  {/* <h2 className=" text-center">
               for dev check is tabs working: {selectedContainer?.container_id}
             </h2> */}
                </Col>
              </Row>

              <Row className="border-left py-4 border-bottom">
                <Col className="d-flex justify-content-center left-border">
                  <div className="d-flex">
                    <div className="track-icon">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_origin"></use>
                      </svg>
                    </div>
                    <div>
                      <h6>Origin</h6>
                      <p>
                        {trackingDetails?.loading_port_name},
                        {trackingDetails?.origin_country}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center left-border">
                  <div className="d-flex ">
                    <div className="track-icon  ">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_location"></use>
                      </svg>
                    </div>
                    <div>
                      <h6>Destination</h6>
                      <p>
                        {trackingDetails?.discharge_port_name},
                        {trackingDetails?.destination_country}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center left-border">
                  <div className="d-flex ">
                    <div className="track-icon">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_type"></use>
                      </svg>
                    </div>
                    <div>
                      <h6>Type</h6>
                      <p>{trackingDetails?.product_type_name || "Ocean "}</p>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center left-border">
                  <div className="d-flex">
                    <div className="track-icon">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_calender"></use>
                      </svg>
                    </div>
                    <div>
                      <h6>Booking Date</h6>
                      <p>
                        {getMomentDate(trackingDetails?.booking_date,false)}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center left-border">
                  <div className="d-flex">
                    <div className="track-icon">
                      <svg className="icon me-2 fs-20">
                        <use href="#icon_calender"></use>
                      </svg>
                    </div>
                    <div>
                      <h6>Delivery Date</h6>
                      <p>
                        {getMomentDate(trackingDetails?.expected_delivery_date,false)}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pt-5">
                <Col className="col-6 number-grid">
                  {containerDetails?.stops?.map((stop, index) => (
                    <>
                      {" "}
                      <div>
                        <div key={stop?.stop_type} className="row d-flex ">
                          <div className="col-6">
                            <div className="icon-wrapper">
                              {stop?.stop_type == "ORIGIN" && (
                                <CustomIcon
                                  name="icon_origin"
                                  className="fs-4"
                                />
                              )}
                              {stop?.stop_type == "PORT_OF_LOADING" && (
                                <CustomIcon
                                  name="icon_loading"
                                  className="fs-4"
                                />
                              )}
                              {stop?.stop_type == "TRANSSHIPMENT_PORT" && (
                                <CustomIcon
                                  name="icon_transit"
                                  className="fs-4"
                                />
                              )}
                              {stop?.stop_type == "PORT_OF_DISCHARGE" && (
                                <CustomIcon name="icon_port" className="fs-4" />
                              )}
                              {stop?.stop_type == "DESTINATION" && (
                                <CustomIcon
                                  name="icon_delivered"
                                  className="fs-4"
                                />
                              )}
                            </div>
                            <div className="" style={{ paddingLeft: "80px" }}>
                              <h6>{stop?.stop_type.replaceAll("_", " ")} </h6>
                              <p className="pt-2">
                                {getLocation(stop?.location)?.name}
                                <br />
                                {getLocation(stop?.location)?.timeZone}
                              </p>
                            </div>
                          </div>
                        </div>
                        <Accordion
                          key={"Accordian" + stop?.stop_type}
                          style={{ marginLeft: "80px", marginTop: "2px" }}
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>See Events</Accordion.Header>
                            <Accordion.Body>
                              <table>
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        borderRight: "1px solid #e0e0e0",

                                        borderBottom: "1px dashed #e0e0e0",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      Event
                                    </th>
                                    <th
                                      style={{
                                        borderRight: "1px solid #e0e0e0",

                                        borderBottom: "1px dashed #e0e0e0",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      Expected Date
                                    </th>
                                    <th
                                      style={{
                                        borderBottom: "1px dashed #e0e0e0",
                                        paddingLeft: "8px",
                                      }}
                                    >
                                      Real Date
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {stop?.event_details?.map((event, index) => (
                                    <tr key={event?.event_type} style={{}}>
                                      <td
                                        style={{
                                          borderRight: "1px solid #e0e0e0",

                                          borderBottom: "1px dashed #e0e0e0",
                                          paddingRight: "8px",
                                        }}
                                      >
                                        {event?.event_type?.replaceAll(
                                          "_",
                                          " "
                                        )}
                                      </td>

                                      <td
                                        style={{
                                          borderRight: "1px solid #e0e0e0",
                                          paddingLeft: "4px",
                                          borderBottom: "1px dashed #e0e0e0",
                                          paddingRight: "8px",
                                        }}
                                      >
                                        {getMomentDate(
                                          event?.planned_date_time || event?.estimated_date_time
                                        ,true)}
                                      </td>
                                      <td
                                        style={{
                                          paddingLeft: "8px",
                                          borderBottom: "1px dashed #e0e0e0",
                                        }}
                                      >
                                        {getMomentDate(event?.date_time,true)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      {containerDetails?.routesegment?.map((segment) => {
                        if (
                          segment.from_stop_id == stop.stop_id &&
                          segment?.transportation_mode !== "UNKNOWN"
                        ) {
                          return (
                            <div key={segment?.from_stop_id} className="col-12">
                              <div className="icon-wrapper">
                                <CustomIcon name="icon_type" className="fs-4" />
                              </div>
                              <div className="" style={{ paddingLeft: "80px" }}>
                                <h6>
                                  Transportation Mode :{" "}
                                  {segment?.transportation_mode}
                                </h6>
                                <Accordion
                                  key={"Accordian" + stop?.stop_type}
                                  style={{ marginTop: "2px" }}
                                >
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      Carrier Details
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {getLocation(segment.identifiers)?.map(
                                        (identifier) => (
                                          <p key={identifier?.value}>
                                            <b>
                                              {identifier.type.replaceAll(
                                                "_",
                                                " "
                                              )}
                                            </b>{" "}
                                            : {identifier.value}
                                          </p>
                                        )
                                      )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  ))}
                </Col>
                <Col className="col-6">
                  <div>
                  Marker colors :
                    <ul>
                    <li>Origin/Port of origin = Yellow🟡</li>
  <li>Trans Shipment/Current Position = Red🔴</li>
  <li>Destination/Port of Discharge = Green🟢</li>

                    </ul>
                  </div>
                  <div className="">
                  {isLoaded &&  (
                    
                    <GMap
                      loadError={loadError}
                      markerPoints={markers}
                      stopes={containerDetails?.stops}
                    />
                  )}

                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col className="d-flex justify-content-center left-border">
                <h4>
                  {!containers.length
                    ? "No Containers Found"
                    : "No Stops Data Found"}
                </h4>
              </Col>
            </Row>
          )}
        </Container>
      )}

      {/* ===Modle */}
      <Modal
        show={showAddContainer}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Add Container</h2>
          <form onSubmit={addContainer} className="p-4">
            <div className="input-box mb-4">
              <input
                required
                value={containerNumber}
                onChange={(e) => setContainerNumber(e.target.value)}
                type="text"
                className="w-100"
                placeholder="Enter Your Container Number*"
              />
            </div>

            <div className="d-flex justify-content-end">
              <div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* ===Modle booking id */}

      <AddBookingModal
        selectedDocNumber={null}
        showAddBooking={showAddBookingModal}
        handleClose={handleClose2}
        customerId={customerId}
        page={"booking"}
        quote_id={state?.quote?.quote_id || null}
        state={state}
        errormsg={errormsg}
        setErrormsg={setErrormsg}
      />
    </>
  );
}

export default Booking_number;

function GMap({ loadError, markerPoints, stopes }) {
  let stops = stopes?.map((stop) => ({
    coordinates: stop.location ? JSON.parse(stop.location)?.coordinates : null,
    type: stop.stop_type,
  }));
  console.log(stopes);
  if (loadError) {
    return <div>Error while loading maps</div>;
  }

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 0,

    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1",
          strokeOpacity: 1,
          scale: 4,
        },
        offset: "0",
        repeat: "20px",
      },
    ],
    zIndex: 1,
  };

  return (
    <GoogleMap
      zoom={5}
      center={{
        lat: (function () {
          if (stops.length) {
            return parseFloat(stops[0].coordinates?.latitude);
          } else {
            return markerPoints.length ? parseFloat(markerPoints[0].lat) : 0.0;
          }
        })(),
        lng: (function () {
          if (stops.length) {
            return parseFloat(stops[0].coordinates?.longitude);
          } else {
            return markerPoints.length ? parseFloat(markerPoints[0].long) : 0.0;
          }
        })(),
      }}
      mapContainerStyle={{
        width: "100%",
        height: "60vh",
        borderRadius: "10px",
      }}
    >
      {stops?.map((marker, marker_index) => (
        <MarkerF
          key={marker?.coordinates?.latitude}
          icon={{
            url: `https://maps.google.com/mapfiles/ms/icons/${
              conditionalExp((marker.type == "PORT_OF_LOADING" || marker.type == "ORIGIN" || marker.type == 'PORT_OF_DISCHARGE' || marker.type == 'DISCHARGE') , conditionalExp(marker.type == 'PORT_OF_DISCHARGE' || marker.type == 'DISCHARGE','green',"yellow") , "red")
            }-dot.png`,
            scaledSize: new window.google.maps.Size(32, 32), // Set the size of the marker icon
          }}
          position={{
            lat: parseFloat(marker.coordinates?.latitude),
            lng: parseFloat(marker.coordinates?.longitude),
          }}
        />
      ))}

      <PolylineF
        path={markerPoints.map((pin) => ({
          lat: parseFloat(pin?.lat),
          lng: parseFloat(pin?.long),
        }))}
        options={options}
      />
    </GoogleMap>
  );
}

