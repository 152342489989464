import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import { useEffect, useState } from "react";

function LayoutOutlet() {
  const [showAddContainer, setShowAddContainer] = useState(false);
  const [showAddBooking, setShowAddBooking] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
      if (!localStorage.getItem("mtm_user_token") || !localStorage.getItem("mtm_user_data")) {
          navigate("/login");
        }
  }, [])
  return (
    <>
      <AsideBar
        showAddContainer={showAddContainer}
        setShowAddContainer={setShowAddContainer}
        showAddBooking={showAddBooking}
        setShowAddBooking={setShowAddBooking}
      ></AsideBar>
      <NavBar></NavBar>
      <div className="wrapper">
        <Outlet
          context={{
            showAddContainer,
            setShowAddContainer,
            showAddBooking,
            setShowAddBooking,
          }}
        ></Outlet>
      </div>
    </>
  );
}
export default LayoutOutlet;