import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "../pages/Login";
import Selectcard from "../pages/Selectcard";
import LayoutOutlet from "./LayoutOutlet";
import Home from "../pages/Home";
import Crm from "../pages/Crm";
import Customers_list from "../pages/Customers_list";
import Tracking from "../pages/Tracking";
import { CustomerProvider } from "../context/customerContext";
import Booking_number from "../pages/Booking_number";
import { ContainerProvider } from "../context/containerContext";
import Quote_details from "../pages/Quote_details";
import Apo from "../pages/Apo";
import Agents from "../pages/Agents";
import Modals from "../pages/Modals";
import Save2 from "../pages/Save2";
import Unauthorized from "../pages/Unauthorized";
import Warehouse_users from "../pages/Warehouse_user";

function Routing() {
  return (
    <>
      <CustomerProvider>
        <ContainerProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/selectcard" element={<Selectcard />}></Route>
              <Route path="/crm" element={<Crm />}></Route>
              <Route path="/" element={<Customers_list />}></Route>
              <Route path="/agents" element={<Agents />}></Route>
              <Route path="/modals" element={<Modals />}></Route>
              <Route path="/unauthorized" element={<Unauthorized />}></Route>
              <Route
                path="/:customerId/tracking"
                element={<Tracking />}
              ></Route>
              <Route
                path="/:customerId/quote/:quote_id"
                element={<Quote_details />}
              ></Route>
              <Route path="/saved" element={<Save2 />}></Route>
              <Route path="/:customerId/*" element={<LayoutOutlet />}>
                <Route path="" element={<Home />} />
                <Route path="booking_number" element={<Booking_number />} />
                <Route path="apo" element={<Apo />} />
                <Route path="warehouse_users/:warehouse_id/:warehouse_name" element={<Warehouse_users />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ContainerProvider>
      </CustomerProvider>
    </>
  );
}

export default Routing;
