import axios from "axios";

export const getCountriesFn = async (
  setoptionsForCountries,
  setallCountries,
  navigate,
  customerId
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/list`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    console.log(response, "result");
    if (response.data.status) {
      let optionsForCountries = [];
      let countries = response?.data?.country;
      for (let i of countries) {
        const element = i;
        let obj = {};
        obj["label"] = element?.country;
        obj["value"] = element?.country;
        optionsForCountries.push(obj);
      }
      setoptionsForCountries && setoptionsForCountries(optionsForCountries);

      setallCountries && setallCountries(response?.data?.country);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getPortDetails = async (
  countryCode,
  setoptionsForPorts,
  setallPortsForCountries,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/port/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        country_sort_code: countryCode,
      },
    });

    console.log(response, "port details");
    if (response.data.status) {
      let optionsForPorts = [];
      let ports = response?.data?.port_details;
      for (let i of ports) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_name} | ${element?.global_port_code}`;
        obj["value"] = `${element?.port_name} | ${element?.global_port_code}`;
        optionsForPorts.push(obj);
      }
      setoptionsForPorts && setoptionsForPorts(optionsForPorts);
      setallPortsForCountries &&
        setallPortsForCountries(response?.data?.port_details);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};
export const getPOL_PODPorts = async (
  setOptionsForPortsOrigin,
setOptionsForPortsDestination,
  navigate
) => {

  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/everest/getport`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
     
    });

    if (!response.data.error) {
      let  portloadings = []
      let portdischarge = []
      let pol = response?.data?.portloadings;
      let pod = response?.data?.portdischarge;
      for (let i of pol) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_loading}`;
        obj["value"] = `${element?.port_of_loading}`;
        obj["country"] = `${element?.origin_country}`;
        portloadings.push(obj);
      }
      for (let i of pod) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_of_discharge}`;
        obj["value"] = `${element?.port_of_discharge}`;
        portdischarge.push(obj);
      }

      setOptionsForPortsOrigin && setOptionsForPortsOrigin(portloadings);
      setOptionsForPortsDestination &&
        setOptionsForPortsDestination(portdischarge);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getSKUListFootprint = async (
  setSkuListDetailsRaw,
  navigate
) => {

  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/everest/item/details`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
     
    });

    if (!response.data.error) {
      setSkuListDetailsRaw(response.data.items)
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getCountryCode = (allCountries, selectedOptionCountry) => {
  let countryCode = "";
  for (let i = 0; i < allCountries?.length; i++) {
    const element = allCountries[i];
    if (element?.country == selectedOptionCountry?.label) {
      countryCode = element?.country_sort_code;
      break;
    }
  }
  return countryCode;
};

export async function getCarrier(setCarrierList, navigate) {
  const token = localStorage.getItem("mtm_user_token");
  try {
    let carriers = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/career/dropdowns`,
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );
    setCarrierList &&
      setCarrierList(
        carriers?.data?.career_details?.map((option) => {
          return {
            value: option.career_scac,
            label: `${option?.career_name} (${option.career_scac})`,
          };
        })
      );
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    console.log(err);
  }
}

export const getVendorList = async (
  customerId,
  setoptionsModified,
  setvendorListDetailsRaw,
  setvendorListDetails,
  navigate
) => {
  console.log(customerId, "getVendorList customer_id");
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/vendor/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        let data = response.data.vendors?.map((item) => {
          return {
            label: item.vendor_name,
            value: item.vendor_name,
          };
        });
        setoptionsModified &&
          setoptionsModified([
            {
              label: "Add Vendor",
              value: "Add Vendor",
            },
            ...data,
          ]);
        // console.log(data,"data")
        setvendorListDetails && setvendorListDetails(response.data.vendors);
        setvendorListDetailsRaw &&
          setvendorListDetailsRaw(response.data.vendors);
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};
export const getWarehouseList = async (
  customerId,
  setwarehouseListDetailsRaw,
  setwarehouseListDetails,
  navigate,
  setIsgettingwarehouselist
) => {
  try {
    setIsgettingwarehouselist && setIsgettingwarehouselist(true)
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/getall`,
      method: "POST",
      data:{ customer_id: customerId,},
      headers: {
        "Content-Type": "application/json",
        jwt_token: token
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        setwarehouseListDetails && setwarehouseListDetails(response.data.warehouses);
        setwarehouseListDetailsRaw &&
          setwarehouseListDetailsRaw(response.data.warehouses);
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }finally{
    setIsgettingwarehouselist && setIsgettingwarehouselist(false)
  }
};

export const getSKUList = async (
  customerId,
  setskuListDetails,
  setskuListDetailsRaw,
  navigate,
  setskuListOptions
) => {
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        setskuListDetails && setskuListDetails(response.data.skuItems);
        let options = response.data.skuItems?.map((item) => {
          return {
            label: item?.item_code,
            value: item?.item_code,
          };
        });
        setskuListOptions &&
          setskuListOptions([
            ...options,
            {
              label: "Add SKU",
              value: "Add SKU",
            },
          ]);
        setskuListDetailsRaw && setskuListDetailsRaw(response.data.skuItems);
      }
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};

export async function getQuotes(
  customer_id,
  page,
  setoptionsModifiedQuote,
  setQuoteDetailsFetched,
  navigate,
  {selectedProductType,
  selectedDate,
  selectedShipment,
  clickupData,
  setCurrentClickup,
  setdataFetchedPillsQuotes,
  getBookingList}
) {

  setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(false)
  const token = localStorage.getItem("mtm_user_token");
  let data = { customer_id: customer_id };

  if (page === "home") {
    let clickupId = clickupData?.find(
      (clickup) =>
        clickup.dropdown_type == "product" &&
        clickup.clickup_product_name == selectedProductType
    );
    setCurrentClickup && setCurrentClickup(clickupId);

    data = {
      ...data,
      from_date: selectedDate[0],
      to_date: selectedDate[1],
      booking_status: selectedShipment,
      product_type: clickupId?.clickup_product_id,
    };
  }
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/customer/opportunity`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: data,
    });

    if (!response.data.error) {
      const filteredQuotes = await response?.data?.quotes
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      let data = filteredQuotes?.map((item) => {
        return {
          label: `${item?.opportunity_name} | ${item?.custom_task_id}`,
          value: item?.quote_id,
        };
      });
      setoptionsModifiedQuote && setoptionsModifiedQuote(data);
      setQuoteDetailsFetched && setQuoteDetailsFetched(filteredQuotes);
      setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(true);
      getBookingList && getBookingList(filteredQuotes);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
    setdataFetchedPillsQuotes && setdataFetchedPillsQuotes(true);
  }
}


export async function getCustomerDetails(setIsLoading,customerId,navigate,setCustomerData,updateCustomer,setRfcNumber,setVatRegistrationNumber) {
  setIsLoading && setIsLoading(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/single`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        customer_id: customerId,
      },
    });

    if (!response.data.error) {
      const userData = JSON.parse(localStorage.getItem('mtm_user_data'))
      if(!response?.data?.ops_users?.find(e=> e.user_id == userData?.id) && userData?.user_type != 1){
        console.log('not authorized',response?.data?.ops_users);
        navigate('/unauthorized')
        return 
      }
      setCustomerData && setCustomerData(response.data?.custumer);
      updateCustomer && updateCustomer(response.data?.custumer);
      setRfcNumber && setRfcNumber(response.data?.custumer?.rfc_number);
      setVatRegistrationNumber && setVatRegistrationNumber(response.data?.custumer?.vat_registration_number);
      setIsLoading && setIsLoading(false);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
    setIsLoading && setIsLoading(false);
  }
}

export async function getTagCalculationList(
  selectedDateTagList,
  setTagCalculationList,
  setIsLoadingTagCalList,
  navigate,
  customerId
) {
  setIsLoadingTagCalList && setIsLoadingTagCalList(true)
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/getlist`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        from_date: selectedDateTagList[0] || null,
        to_date: selectedDateTagList[1] || null,
        customer_id:customerId
      },
    });

    if (!response.data.error) {
      setTagCalculationList && setTagCalculationList(response?.data?.tagList)
    }else{
      setTagCalculationList && setTagCalculationList([])
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  }finally{
    setIsLoadingTagCalList && setIsLoadingTagCalList(false)
  }
}

export async function getTagSKUList(
  setTagCalculationSKUList,
  setIsLoadingTagCalSKU,
  navigate,
  customerId
) {
  setIsLoadingTagCalSKU && setIsLoadingTagCalSKU(true)
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/getSKUlist`,
      method: "POST",
      data:{
        customer_id:customerId
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    if (!response.data.error) {
      setTagCalculationSKUList && setTagCalculationSKUList(response?.data?.tagList)
    }else{
      setTagCalculationSKUList && setTagCalculationSKUList([])
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  }finally{
    setIsLoadingTagCalSKU && setIsLoadingTagCalSKU(false)
  }
}

export const getFormattedDate = (item) => {
  const currentDate = item["$d"];
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export async function syncWarehouseFromSap(
  setIsLoadingWarehoseSync,
  navigate,
  getWarehouseList,
  customerId,
  {setShowSuccess,
  setsuccessMessagePopup,
  setShowErrModal,
  setError}
) {
  setIsLoadingWarehoseSync && setIsLoadingWarehoseSync(true)
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/syncfromsap`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id:customerId
      },
    });

    if (!response.data.error) {
      getWarehouseList && await getWarehouseList ()
      setsuccessMessagePopup && setsuccessMessagePopup("Warehouse Synced Successfully")
      setShowSuccess && setShowSuccess(true)
    }else{
      setError && setError({title:"Error",err:response?.data?.message})
      setShowErrModal && setShowErrModal(true)
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
  }finally{
    setIsLoadingWarehoseSync && setIsLoadingWarehoseSync(false)
  }
}

export function conditionalExp(condition,a,b){
  if(condition){
    return a
  }else{
    return b
  }
}

export const getSKUListHome = async (
  customerId,
  setRows,
  setRowsRaw,
  navigate,
  setIsloading,
  setCurrentPage,
  sethasMore,
  rows,
  currentPage,
  searchInput,
  from
) => {
  try {
    setIsloading(true);
    let token = localStorage.getItem("mtm_user_token");
    if(from == "reload") {
      setCurrentPage(0);
    }
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/get_sku_items`,
      method: "POST",
      data: {
        customer_id: customerId,
        page: from == "reload" ? 0 : currentPage,
        searchInput: searchInput,
      },
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    setIsloading(false);
    sethasMore(false);

    if (!response.data.error) {
      const newList = response.data.items.filter((newList) => {
        return !rows.some((existingList) => existingList.id === newList.id);
      });
      if (from == "reload") {
        setRows(response.data.items);
        setRowsRaw(response.data.items);
      } else {
        setRows([...rows, ...newList]);
        setRowsRaw([...rows, ...newList]);
      }
      sethasMore(newList.length > 0);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
    setCurrentPage(0);
  } finally {
    setIsloading(false);
    
  }
};

export async function getDemandForcastType(
  setDemandforcasttypes,navigate
) {
  try {
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtm/demandforcasttypes`,
      method: "GET",
      headers: {
        jwt_token: localStorage.getItem("mtm_user_token"),
      },
    });
    if (response.status == 200 && !response.data.error) {
     setDemandforcasttypes(response.data?.result?.map((item) => {
        return {
          value: item?.type_name,
          label: item?.type_name,
        };
      }) || [])

    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  } 
}