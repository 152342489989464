import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getPOL_PODPorts, getSKUListFootprint } from "../utils/commonFn";
import axios from "axios";
import Loader from "../components/Loader";

function SkuFootprint({ setShowErrModal, setError }) {
  const [pol, setPol] = useState();
  const [pod, setPod] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [optionsForPortsOrigin, setOptionsForPortsOrigin] = useState([]);
  const [optionsForPortsDestination, setOptionsForPortsDestination] = useState(
    []
  );
  const [selectedContainer, setSelectedContainer] = useState();
  const [skuListDetailsRaw, setSkuListDetailsRaw] = useState([]);
  const [rows, setRows] = useState([
    {
      item_code: null,
      buying_qty: 0,
      unit_cost: 0,
      logistics_unit_cost: 0,
      tax_per_unit: 0,
      final_unit_cost: 0,
      volume_cover: 0,
      total_buying_cost: 0,
      total_tax: 0,
      tax_code:"",
      isChanged: false,
      container_cost:0
    },
  ]);

  const containersList = [
    { label: "40GP", value: "40GP" },
    { label: "20GP", value: "20GP" },
  ];
  const navigate = useNavigate();

  function checkFnForSubmit(row) {
    if(!row?.item_code?.value){
        return "Please select sku."
    }else if(!selectedContainer){
        return "Please select container size."
    }else if(!pol){
        return "Please select port of loading."
    }else if (!pod){
        return "Please select port of discharge."
    }else if(row?.buying_qty == "" || row?.buying_qty == 0 ){
        return "Please enter buying quantity ."
    }
  }


  const handleSubmit = async (row, index) => {
    setIsLoading(true);
let error = checkFnForSubmit(row)
  if(error){
     setError({ title: error });
        setShowErrModal(true);
        setIsLoading(false)
        return 
  }

    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/everest/itemcost/calculation`,
        method: "POST",
        data: {
          item_code: row?.item_code?.value,
          qty: row?.buying_qty,
          container_type: selectedContainer?.value,
          pol: pol?.value,
          pod: pod?.value,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (!response.data.error) {
        let respData = response.data;
        setRows((pre) => {
          let update = [...pre];
          update[index] = {
            ...update[index],
            isChanged: false,
            unit_cost: respData?.unit_cost,
            logistics_unit_cost: respData?.logistic_unit_cost,
            volume_cover: respData?.volum_used_percent,
            total_buying_cost: respData?.total_buy_cost,
            tax_per_unit: respData?.tax_per_unit,
            total_tax: respData?.total_tax,
            tax_code:respData?.tax_code,
            final_unit_cost: respData?.final_cost_per_bottale,
            container_cost: respData?.container_cost
          };
          return [...update];
        });
      } else {
        setRows((pre) => {
          let update = [...pre];
          update[index] = {
            ...update[index],
            isChanged: false,
          };
          return [...update];
        });

        setError({ title: response?.data?.message });
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log(parseFloat(rows[0]?.container_cost / (rows?.reduce((a,b)=> a + parseInt(b.buying_qty) ,0))).toFixed(2) );

  useEffect(() => {
    getPOL_PODPorts(
      setOptionsForPortsOrigin,
      setOptionsForPortsDestination,
      navigate
    );
    getSKUListFootprint(setSkuListDetailsRaw, navigate);
  }, []);

  return (
    <>
      <Container style={{ minWidth: "100%" }}>
        <Row>
          <Col>
            <div className="wrapper-box">
              <h2 className="fw-700 fs-30 text-center">
                SKU Cost Footprint (MXN)
              </h2>
              <div className="row row-cols-auto pt-4">
                <div className="col">
                  <p>Country Of Origin</p>
                  <h6 className="fw-700 fs-30">{pol?.country || "Select POL"}</h6>
                </div>
                <div className="col">
                  <p>Country Of Destination</p>
                  <h6 className="fw-700 fs-30">Mexico</h6>
                </div>
                <div className="col">
                  <p>Total SKU Unit</p>
                  <h6 className="fw-700 fs-30">{rows?.reduce((a,b)=> a + parseInt(b.buying_qty) ,0) || 0}</h6>
                </div>
                <div className="col">
                  <p>Logistics Cost Per SKU unit</p>
                  <h6 className="fw-700 fs-30">${parseFloat(rows[0]?.container_cost / (rows?.reduce((a,b)=> a + parseInt(b.buying_qty) ,0))).toFixed(2) || 0 }</h6>
                </div>
              
              </div>
              <div className="d-flex pt-4 pb-2">
                <div className="me-5">
                  <label
                    for="ContainerSize"
                    className="form-label fs-20 fw-600"
                  >
                    Container Size
                  </label>
                  <Select
                    value={selectedContainer}
                    onChange={(option) => setSelectedContainer(option)}
                    options={containersList}
                    isSearchable={true}
                    placeholder="Choose Container Size"
                  />
                </div>
                <div className="me-5">
                  <label for="ChoosePol" className="form-label fs-20 fw-600">
                    POL
                  </label>
                  
                  <Select
                    value={pol}
                    onChange={(option) => setPol(option)}
                    options={optionsForPortsOrigin}
                    isSearchable={true}
                    placeholder="Choose POL"
                    isLoading={!optionsForPortsOrigin?.length}
                  />
                </div>
                <div>
                  <label for="ChoosePod" className="form-label fs-20 fw-600">
                    POD
                  </label>
                 
                  <Select
                    value={pod}
                    onChange={(option) => setPod(option)}
                    options={optionsForPortsDestination}
                    isSearchable={true}
                    placeholder="Choose POD"
                    isLoading={!optionsForPortsDestination?.length}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className=" ms-auto">
                  <button

                  disabled={ rows?.reduce((a,b)=> a + parseFloat(b?.volume_cover) ,0) >= 100}
                    onClick={() =>
                      setRows((pre) => [
                        ...pre,
                        {
                          item_code: null,
                          buying_qty: 0,
                          unit_cost: 0,
                          logistics_unit_cost: 0,
                          tax_per_unit: 0,
                          final_unit_cost: 0,
                          volume_cover: 0,
                          total_buying_cost: 0,
                          total_tax: 0,
                          tax_code: "",
                          isChanged: false,
                          container_cost:0
                        },
                      ])
                    }
                    type="button"
                    className="grd-btn rounded-pill py-1"
                  >
                    + Add More SKU
                  </button>
                </div>
              </div>
            </div>
            <div className="sku-footprint">
              <table class="table text-centerl my-4">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Buying&nbsp;Quantity</th>
                    <th>Unit&nbsp;Cost</th>
                    <th>Logistic&nbsp;Unit&nbsp;Cost</th>
                    <th>Tax&nbsp;Per&nbsp;Unit</th>
                    <th>Final&nbsp;Unit&nbsp;Cost</th>
                    <th>Volume&nbsp;Cover&nbsp;(%)</th>
                    <th>Total&nbsp;Buying&nbsp;Cost</th>
                    <th>Total&nbsp;Tax</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr key={row?.item_code + 'itrems'} style={{ overflow: "visible" }}>
                      <td style={{ overflow: "visible", minWidth: "250px" }}>
                        <Select
                          required
                          value={row?.item_code}
                          onChange={(option) => {
                            setRows((pre) => {
                              let update = [...pre];
                              update[index] = {
                                ...update[index],
                                item_code: option,
                                isChanged: true,
                              };
                              return [...update];
                            });
                          }}
                          options={skuListDetailsRaw?.map((tag) => ({
                            label: `${tag?.item_code} | ${tag?.item_name}`,
                            value: tag?.item_code
                          }))}
                          isSearchable={true}
                          placeholder="Choose SKU"
                          blurInputOnSelect={true}
                        />
                        <i>{row?.tax_code || "Tax Code"}</i>
                      </td>
                      <td >
                        <div>
                          <input
                            style={{ minWidth: "95px" }}
                            type="number"
                            className="form-control"
                            placeholder="Enter Quantity"
                            defaultValue={row?.buying_qty}
                            value={row?.buying_qty}
                            onChange={(e) => {
                              setRows((pre) => {
                                let update = [...pre];
                                update[index] = {
                                  ...update[index],
                                  buying_qty: e.target.value,
                                  isChanged: true,
                                };
                                return [...update];
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Unit Cost"
                            value={row?.unit_cost}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Logistic Unit Cost"
                            value={row?.logistics_unit_cost}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Tax Per Unit"
                            value={row?.tax_per_unit}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Final Unit Cost"
                            value={row?.final_unit_cost}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Volume Cover "
                            value={row?.volume_cover}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Total Buying Cost"
                            value={row?.total_buying_cost}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Total Tax"
                            value={row?.total_tax}
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex ">
                        
                        {row?.isChanged && (
                            <button
                              disabled={isLoading}
                              type="button"
                              className=" grd-btn"
                              onClick={() => handleSubmit(row, index)}
                            >
                              {isLoading ? <Loader /> : "Submit"}
                            </button>
                          )}
                          {index != 0 && (
                          <button
                            type="button"
                            className="p-0 mx-2 border-0"
                            style={{ background: "transparent" }}
                            onClick={() => {
                              let removed = rows;
                              removed.splice(index, 1);
                              setRows([...removed]);
                              console.log(rows);
                            }}
                          >
                            <svg className="icon fs-22">
                              <use href="#icon_minus"></use>
                            </svg>
                          </button>
                        )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SkuFootprint;
