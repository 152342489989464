import React, { useEffect, useRef, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import axios from "axios";
import Loader from "../components/Loader";
import Select from "react-select";
import { getCountriesFn } from "../utils/commonFn";
function Agents() {
  const [brokerList, setBrokerList] = useState([]);
  const [searchedBrokers, setSearchedBrokers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [isEditMode, setIsEditMode] = useState();
  const [
    company_name_ref,
    contact_name_ref,
    contact_email_ref,
    contact_phone_ref,
  ] = [useRef(), useRef(), useRef(), useRef()];
  const [company_name, setCompanyName] = useState("");
  const [type, setType] = useState("");
  const [contact_name, setContactName] = useState("");
  const [contact_email, setContactEmail] = useState("");
  const [contact_phone, setContactPhone] = useState("");
  const [origin_country, setOriginCountry] = useState();
  const [destination_country, setDestinationCountry] = useState();
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const navigate = useNavigate();
  const agentTypes = [
    { label: "Agent at Origin", value: "origin" },
    {
      label: "Agent at Destination",
      value: "destination",
    },
    { label: "Custom Broker", value: "custom_broker" },
  ];

  const getLabel = (value) => {
    let item = agentTypes.find((item) => item.value == value);
    if (item) {
      return item?.label;
    } else {
      return "";
    }
  };

  async function getBrokers() {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
      if (response.status == 200 && !response.data.error) {
        setBrokerList(response?.data?.brokers);
      } else {
        setBrokerList([]);
        alert("Error: " + response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoading(false);
    }
  }

  function searchAgent() {
    setIsLoading(true);
    if (searchInput) {
      const searchedList = brokerList.filter((broker) => {
        if (
          broker?.company_name
            ?.toLowerCase()
            ?.includes(searchInput.toLowerCase()) ||
          getLabel(broker?.type)
            ?.toLowerCase()
            ?.includes(searchInput.toLowerCase())
        ) {
          return broker;
        }
      });
      setSearchedBrokers(searchedList);
      setIsLoading(false);
    } else {
      setSearchedBrokers([]);
      setIsLoading(false);
    }
  }

  async function handleAddAgent(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      let data = {
        company_name: company_name,
        type: type?.value,
        contact_person: contact_name,
        phone: contact_phone,
        email: contact_email,
        origin_country: origin_country?.value,
        destination_country: destination_country?.value,
      };
      if (type?.value == "origin") {
        data["destination_country"] = "";
      }
      if (type?.value == "destination") {
        data["origin_country"] = "";
      }
      if (type?.value == "custom_broker") {
        data["origin_country"] = "";
        data["destination_country"] = "";
      }

      let url = `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/add`;

      if (isEditMode) {
        data.broker_id = isEditMode?.broker_id;
        url = `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/edit`;
      }

      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: url,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });
      if (response.status == 200 && !response.data.error) {
        await getBrokers();
      } else {
        alert("Error: " + response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setIsLoading(false);
      setIsEditMode();
      setCompanyName("");
      setType("");
      setContactName("");
      setContactPhone("");
      setContactEmail("");
      setOriginCountry("");
      setDestinationCountry("");
      setShowadd(false);
      setSearchInput("")
    }
  }

  function handleEdit(broker) {
    setIsEditMode({ broker_id: broker?.broker_id });
    setCompanyName(broker?.company_name);
    setType(agentTypes.find((option) => option.value == broker?.type));
    setContactName(broker?.contact_person);
    setContactPhone(broker?.phone);
    setContactEmail(broker?.email);
    setOriginCountry(
      optionsForCountries.find(
        (option) => option.value == broker?.origin_country
      )
    );
    setDestinationCountry(
      optionsForCountries.find(
        (option) => option.value == broker?.destination_country
      )
    );
    setShowadd(true);
  }

  function mapAgents(array){
    return array?.map((broker, index) => (
      <tr key={`broker${index + 1}`}>
        <td>{broker?.company_name}</td>
        <td>{getLabel(broker?.type)}</td>
        <td>{broker?.contact_person}</td>
        <td>{broker?.phone}</td>
        <td>{broker?.email}</td>
        <td>{broker?.origin_country}</td>
        <td>{broker?.destination_country}</td>
        <td>
          <button
            type="button"
            className="p-0 m-0 border-0 bg-body me-4 edit-btn"
            onClick={() => {
              handleEdit(broker);
            }}
          >
            <svg className="icon ms-2 fs-22">
              <use href="#icon_edit"></use>
            </svg>
          </button>
        </td>
      </tr>
    ))
  }

  useEffect(() => {
    getBrokers();
    getCountriesFn(setoptionsForCountries,null,navigate);
  }, []);

  useEffect(() => {
    searchAgent();
  }, [searchInput]);

  return (
    <>
      <NavBar />
      <Container fluid className="wrapper">
        <div className="">
          <div className="py-3">
            <button
              type="button"
              className="back-btn d-flex alig-items-center"
              onClick={() => {
                navigate("/");
              }}
            >
              <svg className="icon me-2 fs-22">
                <use href="#icon_leftarrow"></use>
              </svg>
              <strong className="fs-14">Back</strong>
            </button>
            <h2 className="fw-700 fs-24 text-center ">Manage Agents</h2>
          </div>
          <div className="d-flex">
            <div className="w-25">
              <input
                required
                type="search"
                class="form-control rounded-pill"
                placeholder="Search Agent "
                id="datatable-search-input"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </div>

            <div className="ms-auto">
              <div className="row">
                <div className="col">
                  <button
                    type="button"
                    className="grd-btn rounded-pill"
                    onClick={() => {
                      setShowadd(true);
                    }}
                  >
                    Add Agent
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {brokerList.length && !isLoading ? (
          <div className="w-100 overflow-auto vendor-tbl">
            <table class="table text-center my-4 border">
              <thead>
                <tr className="border" style={{ background: "#EBEEFF" }}>
                  <th>Company Name</th>
                  <th>Type</th>
                  <th>Contact Person</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Origin Country</th>
                  <th>Destination Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(function(){
                  if(searchedBrokers?.length){
                    return mapAgents(searchedBrokers)
                  }else{
                   return searchInput == ""
                  ? mapAgents(brokerList)
                  : ""
                  }
                })()}
                
              </tbody>
            </table>
            {searchInput !== "" && searchedBrokers.length == 0 ? (
              <h2 className="  text-center">No agent found</h2>
            ) : (
              ""
            )}
          </div>
        ) : (
          <Loader />
        )}
      </Container>
      {/* Add Agent Modal */}
      <Modal
        show={showadd}
        centered
        onHide={() => {
          setIsLoading(false);
          setIsEditMode();
          setCompanyName("");
          setType("");
          setContactName("");
          setContactPhone("");
          setContactEmail("");
          setOriginCountry("");
          setDestinationCountry("");
          setShowadd(false);
        }}
        scrollable={true}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-4">
          <form onSubmit={handleAddAgent} className="p-4">
            <p className="text-center fs-26 fw-bold pb-2">
              {isEditMode ? "Edit Agent" : "Add Agent"}
            </p>
            <div className="input-box mb-3">
              {company_name && company_name != "" && (
                <label htmlFor="">Company Name*</label>
              )}
              <input
                ref={company_name_ref}
                value={company_name}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                type="text"
                className="w-100"
                id="company_name"
                placeholder="Enter Company Name*"
              />
            </div>

            <div className="input-box mb-3">
              {type && (
                <label htmlFor="" className="mb-1">
                  Agent Type*
                </label>
              )}
              <Select
                required
                value={type}
                onChange={(option) => setType(option)}
                options={agentTypes}
                isSearchable={true}
                placeholder="Select Agent Type"
                isClearable
                isLoading={!agentTypes?.length}
              />
            </div>
            <div className="input-box mb-3">
              {contact_name && contact_name != "" && (
                <label htmlFor="">Contact Person Name*</label>
              )}
              <input
                value={contact_name}
                onChange={(e) => setContactName(e.target.value)}
                ref={contact_name_ref}
                required
                type="text"
                className="w-100"
                id="contact_name"
                placeholder="Enter Contact Person Name*"
              />
            </div>

            <div className="input-box mb-3">
              {contact_email && contact_email != "" && (
                <label htmlFor="">Contact Person Email*</label>
              )}
              <input
                value={contact_email}
                onChange={(e) => setContactEmail(e.target.value)}
                ref={contact_email_ref}
                required
                type="email"
                id="contact_email"
                className="w-100"
                placeholder="Enter Contact Person Email*"
              />
            </div>

            <div className="input-box mb-3">
              {contact_phone && contact_phone != "" && (
                <label htmlFor="">Contact Person Phone*</label>
              )}
              <input
                value={contact_phone}
                onChange={(e) => setContactPhone(e.target.value)}
                ref={contact_phone_ref}
                required
                type="text"
                className="w-100"
                id="contact_phone"
                placeholder="Enter Contact Person Phone*"
              />
            </div>
            {type?.value != "custom_broker" && type?.value != "" && (
              <>
                {type?.value == "origin" && (
                  <div className="input-box mb-3">
                    {origin_country && (
                      <label htmlFor="" className="mb-1">
                        Origin Country*
                      </label>
                    )}
                    <Select
                      required
                      value={origin_country}
                      onChange={(option) => setOriginCountry(option)}
                      options={optionsForCountries}
                      isSearchable={true}
                      placeholder="Select Origin Country"
                      isClearable
                      isLoading={!optionsForCountries?.length}
                    />
                  </div>
                )}
                {type?.value == "destination" && (
                  <div className="input-box mb-3">
                    {destination_country && (
                      <label htmlFor="" className="mb-1">
                        Destination Country*
                      </label>
                    )}
                    <Select
                      required
                      value={destination_country}
                      onChange={(option) => setDestinationCountry(option)}
                      options={optionsForCountries}
                      isSearchable={true}
                      placeholder="Select Destination Country"
                      isClearable
                      isLoading={!optionsForCountries?.length}
                    />
                  </div>
                )}
              </>
            )}

            <div className="d-flex">
              <div className="ms-auto">
                <button
                  type="submit"
                  className="grd-btn rounded-pill"
                  onClick={(e) => {
                    handleAddAgent(e);
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Agents;
