import React, {  useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Loader from "../components/Loader";
import logo from "../images/logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "../components/NavBar";
import { Breadcrumb } from "antd";
import { getCustomerDetails } from "../utils/commonFn";
import Ckeditor from "../components/Ckeditor";

function Quote_details() {
  const [selectedQuote, setSelectedQuote] = useState({});
  const [quotationDetails, setQuotationDetails] = useState({});
  const [editSave, setEditSave] = useState(false);
  const [rateTypeCodes, setrateTypeCodes] = useState([]);
  const [grossTotalCurrencies, setgrossTotalCurrencies] = useState({
    USD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    EUR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    GBP: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CAD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    AUD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    JPY: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    INR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    NZD: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CHF: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    ZAR: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    RUB: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    CNY: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    MXN: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
    BRL: {
      other: "",
      other_original: "",
      "20GP": "",
      "40GP": "",
    },
  });
  const [grossTotalCurrenciesIndidual, setgrossTotalCurrenciesIndividual] =
    useState({});
  const [commentData, setCommentData] = useState("");
  const [showCustomerComments, setshowCustomerComments] = useState(false);
  const handleCloseCustomerComments = () => setshowCustomerComments(false);
  const handleShowCustomerComments = () => setshowCustomerComments(true);
  const params = useParams();
  const quote_id = params.quote_id;
  const customer_id = params.customerId;
  const { state } = useLocation();
  const navigate = useNavigate();
  
  const getValue = (req_key,obj) => {
    if(obj)
      return obj[req_key];
    else
    return quotationDetails[req_key];
  };


  const inputFn = (value, custom = "normal") => {
    if (!value) return "";
    let val = custom == "normal" ? getValue(value) : value;
    return (
      <input
        type="text"
        value={val}
        style={{
          border: "none",
          outline: "none",
          background: "none",
        }}
      ></input>
    );
  };

  const checkOriginAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[0] == "Door";
  };
  const checkDestinationAddressDisplay = () => {
    let service_type = getValue("service_type");
    let split = service_type?.split(" ");
    return split[2] == "Door";
  };

  const getPickUpData = () => {
    let data = "";
    data += getValue("pickup_address1") && getValue("pickup_address1") + ", ";
    data += getValue("pickup_state") && getValue("pickup_state") + ", ";
    data += getValue("pickup_zip_code") && getValue("pickup_zip_code") + ".";
    return data;
  };

  const getDestinationData = () => {
    let data = "";
    data +=
      getValue("delivery_address1") && getValue("delivery_address1") + ", ";
    data += getValue("delivery_state") && getValue("delivery_state") + ", ";
    data +=
      getValue("delivery_zip_code") && getValue("delivery_zip_code") + ".";
    return data;
  };

  const getFormattedDate = (quote_date_validFromAndTo) => {
    const dateStr = quote_date_validFromAndTo;
    const date = new Date(dateStr);

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    const year = date.getFullYear().toString().substring(-2);

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  const getFrieghtRates = (rate_type_code, cont) => {
    let allRrieghtDetails = selectedQuote?.quote_price_breackup?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;
    for (const element of allRrieghtDetails) {
      if (element?.amount_usd) {
        sum += parseFloat(element?.amount_usd);
      }
    }
    return sum;
  };

  const getFrieghtRatesOriginal = (rate_type_code, cont) => {
    let allRrieghtDetails = selectedQuote?.quote_price_breackup?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element?.actual_amount_usd) {
        sum += parseFloat(element?.actual_amount_usd);
      }
    }
    return sum;
  };


  


  async function fetchQuote(quote_id) {
    const token = localStorage.getItem("mtm_user_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/single`,
        { quote_id },
        {
          headers: {
            jwt_token: token,
          },
        }
      );

      if (response.status === 200 && !response.data?.error) {
        setSelectedQuote(response.data);
        setQuotationDetails(response.data?.qutoe);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  }

  

  const getDifferentCurrencies = (cont) => {
    let currencies = ["USD"];
    let quotePriceDetails = [];
    if (cont && cont != "other") {
      quotePriceDetails = selectedQuote?.quote_price_breackup?.filter(
        (item) => item?.container_type === cont
      );
    } else {
      quotePriceDetails = selectedQuote?.quote_price_breackup;
    }
    for (let i = 0; i < quotePriceDetails.length; i++) {
      const element = quotePriceDetails[i];
      if (!currencies?.includes(element.rate_currency)) {
        currencies.push(element?.rate_currency);
      }
    }
    return currencies;
  };

  const getCargoMesurements = (type) => {
    let value = quotationDetails?.cargo_measurements;
    if (!value) return "";
    let splitMeasurements = value?.split("X");
    let measurement = {};
    measurement["length"] = splitMeasurements[0];
    measurement["width"] = splitMeasurements[1];
    measurement["height"] = splitMeasurements[2];
    return measurement[type];
  };

  const calculatePrice = async () => {
    let obj_individual = {};
    let obj = {};

    if (selectedQuote?.qutoe?.quote_status == 2) {
      let blockPrices = selectedQuote?.blockprices;
      if (blockPrices?.length > 0) {
        let blockPricesGround = blockPrices?.filter(
          (item) => item?.rate_type_code == "GROUND_TOTAL"
        );
        if (quotationDetails?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            if (element.container_type == "20GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else if (element.container_type == "40GP") {
              obj[element?.rate_currency] = {
                ...obj[element?.rate_currency],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesGround.length; i++) {
            const element = blockPricesGround[i];
            obj[element?.rate_currency] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              "20GP": "",
              "40GP": "",
            };
          }
        }
        let blockPricesUp = blockPrices?.filter(
          (item) => item?.rate_type_code != "GROUND_TOTAL"
        );
        if (quotationDetails?.container_type == "20GP + 40GP") {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];
            if (element.container_type == "20GP") {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "20GP": element?.amount_usd,
                "20GP_original": element?.actual_amount_usd,
              };
            } else {
              obj_individual[element?.rate_type_code] = {
                ...obj_individual[element?.rate_type_code],
                other: element?.amount_usd,
                other_original: element?.actual_amount_usd,
                rate_currency: element?.rate_currency,
                "40GP": element?.amount_usd,
                "40GP_original": element?.actual_amount_usd,
              };
            }
          }
        } else {
          for (let i = 0; i < blockPricesUp.length; i++) {
            const element = blockPricesUp[i];

            obj_individual[element?.rate_type_code] = {
              other: element?.amount_usd,
              other_original: element?.actual_amount_usd,
              rate_currency: element?.rate_currency,
              actual_amount_usd: "",
              amount_usd: "",
            };
          }
        }
      }
    }

    setgrossTotalCurrencies({
      ...grossTotalCurrencies,
      ...obj,
    });
    setgrossTotalCurrenciesIndividual({
      ...grossTotalCurrenciesIndidual,
      ...obj_individual,
    });
  };
  const getAirRates = (rate_type_code, type) => {
    let allRrieghtDetails = selectedQuote?.quote_price_breackup?.filter(
      (quote) => {
        return quote?.rate_type_code?.includes(rate_type_code);
      }
    );
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[type]) {
        sum += parseFloat(element[type]);
      }
    }
    return sum;
  };
  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const getPrice = (rate_type_code) => {
    let sumAmountUsd = getAirRates(rate_type_code, "amount_usd");
    let sumrateUsd = getAirRates(rate_type_code, "rate_usd");
    if (parseInt(sumAmountUsd) == 0 && parseInt(sumrateUsd) == 0) {
      return 0;
    } else {
      return 1;
    }
  };

  const getBlockCurrency = (rate_type_code, cont) => {
    const getforCondition = (element) => {
      if (cont && cont != "other") {
        return (
          element?.rate_type_code === rate_type_code &&
          element?.container_type === cont
        );
      } else {
        return element?.rate_type_code === rate_type_code;
      }
    };
    for (let i = 0; i < selectedQuote?.quote_price_breackup?.length; i++) {
      const element = selectedQuote?.quote_price_breackup[i];
      if (getforCondition(element)) {
        return element?.rate_currency;
      }
    }
    return "";
  };
  const getTruckRates = (rate_type_code, cont, key) => {
    let allRrieghtDetails = selectedQuote?.quote_price_breackup?.filter((quote) => {
      if (cont == "other") {
        return quote?.rate_type_code?.includes(rate_type_code);
      } else {
        return (
          quote?.rate_type_code?.includes(rate_type_code) &&
          quote?.container_type == cont
        );
      }
    });
    let sum = 0;

    for (const element of allRrieghtDetails) {
      if (element[key]) {
        sum += parseFloat(element[key]);
      }
    }
    return sum;
  };
  const getIndividualRates = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other
    ) {
      return "0.00";
    }
    let value;
    if (cont == "20GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["20GP"] || 0
      );
    } else if (cont == "40GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]["40GP"] || 0
      );
    } else {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]?.other || 0
      );
    }
    return value.toFixed(2);
  };
  const getIndividualRatesOriginal = (rate_type_code, cont) => {
    if (
      Object.keys(grossTotalCurrenciesIndidual || {}).length == 0 ||
      !grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]
        ?.other_original
    ) {
      return "0.00";
    }
    let value;
    if (cont == "20GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
          "20GP_original"
        ] || 0
      );
    } else if (cont == "40GP") {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()][
          "40GP_original"
        ] || 0
      );
    } else {
      value = parseFloat(
        grossTotalCurrenciesIndidual[rate_type_code?.toUpperCase()]
          ?.other_original || 0
      );
    }
    return value.toFixed(2);
  };
  const grossTotalDisplay = (cont, currency) => {
    let value = parseFloat(grossTotalCurrencies[currency][cont] || 0);
    return value.toFixed(2);
  };
  const grossTotalDisplayOriginal = (cont, currency) => {
    let value;
    if (cont == "other" || cont == "other_original") {
      value = parseFloat(grossTotalCurrencies[currency]["other_original"] || 0);
    } else {
      value = parseFloat(
        grossTotalCurrencies[currency][cont + "_original"] || 0
      );
    }

    return value.toFixed(2);
  };
  const getMappingFn = () => {
    if (getValue("container_type") == "20GP + 40GP") {
      return ["20GP", "40GP"];
    } else {
      return ["other"];
    }
  };
  useEffect(() => {
    if (Object.keys(selectedQuote || {})?.length > 0) {
      calculatePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuote]);
  useEffect(() => {
    if (Object.keys(selectedQuote || {}).length > 0) {
      let quote_prices = selectedQuote?.quote_price_breackup;
      let rate_type_codes = [];
      for (const element of quote_prices) {
        if (!rate_type_codes.includes(element?.rate_type_code)) {
          rate_type_codes.push(element?.rate_type_code);
        }
      }
      if (quotationDetails?.product_type_name == "Air") {
        let rate_type_codes_modified = [];
        for (let i = 0; i < rate_type_codes.length; i++) {
          const element = rate_type_codes[i];
          let priceOfRateTypeCode = getPrice(element);
          if (priceOfRateTypeCode != 0) {
            rate_type_codes_modified.push(element);
          }
        }
        setrateTypeCodes(rate_type_codes_modified);
      } else {
        setrateTypeCodes(rate_type_codes);
      }

      
    }
  }, [selectedQuote, quotationDetails]);
  useEffect(() => {
    getCustomerDetails(null,customer_id,navigate)
    fetchQuote(quote_id);
  }, []);

  return (
    <>
      <NavBar />

      <Container fluid >
        <Row className="sticky-top bg-white " style={{ zIndex: 5 }}>
          <Col md={12}>
            <div className="wrapper-box ">
              <Breadcrumb
                separator=">"
                className="fs-5"
                items={[
                  {
                    title: (
                      <Link
                        className="text-decoration-none "
                        to={`/${customer_id}`}
                        state={state}
                      >
                        Quotes
                      </Link>
                    ),
                    key: "home",
                  },
                  {
                    title: state?.quote?.custom_task_id || "Quote Id",
                  },
                ]}
              />
              {/* <Breadcrumb className="">
                <Breadcrumb.Item
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/${customer?.customer_id}`, { state: state })
                  }
                  linkAs={"span"}
                >
                  Quotes
                </Breadcrumb.Item>
                <Breadcrumb.Item style={{ cursor: "pointer" }} linkAs={"span"}>
                  {state?.quote?.custom_task_id || "Quote Id"}
                </Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </Col>
        </Row>
        {Object.keys(selectedQuote).length ? (
          <Row style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <Col className="col-12 py-3">
              <div
                style={{
                  width: "100%",
                  background: "#ffff",
                  padding: "20px 10px",
                }}
              >
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr style={{ background: "#002981", color: "#ffff" }}>
                      <td style={{ width: "100%", textAlign: "center" }}>
                        <h4 className="py-2">Ocean Freight Import Quotation</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <td colSpan={3} style={{ width: "60%" }}>
                        <img
                          src={logo}
                          style={{ width: "180px", height: "60px" }}
                        />
                        <h4 className="pt-2" style={{ color: "#0c1a57" }}>
                          MTM Logix, S.A. de C. V
                        </h4>
                        <p className="py-2">Palmas 525, Lomas de Chapultepec</p>
                        <p className="pb-1">C.P 11000 Miguel Hidalgo</p>
                      </td>
                      <td style={{ width: "40%", margin: "0", padding: "0" }}>
                        <div
                          style={{
                            display: "inline-block",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            background: "#002981",
                            color: "#ffff",
                          }}
                        >
                          <div>
                            <input
                              type="text"
                              value="Quote Number"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Date"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Validity"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Pricing Analyst"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Contact"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Sales Person"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value="Contact"
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                color: "white",
                              }}
                            ></input>
                          </div>
                          {quotationDetails?.product_type_name ==
                              "Air" && (
                              <div>
                                <input
                                  type="text"
                                  value="CBM"
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                    color: "white",
                                  }}
                                ></input>
                              </div>
                            )}
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                          }}
                        >
                          <div>
                            <input
                              type="text"
                              value={quotationDetails?.custom_task_id}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={
                                selectedQuote?.quote_prices?.valid_from
                                  ? getFormattedDate(
                                      selectedQuote?.quote_prices?.valid_from
                                    )
                                  : ""
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>

                          <div>
                            <input
                              type="text"
                              value={
                                selectedQuote?.quote_prices?.valid_to
                                  ? getFormattedDate(
                                      selectedQuote?.quote_prices?.valid_to
                                    )
                                  : ""
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={
                                quotationDetails?.analyst_name
                                  ? quotationDetails?.analyst_name
                                  : " - "
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={
                                quotationDetails?.analyst_email
                                  ? quotationDetails?.analyst_email
                                  : " - "
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                width: "auto",
                              }}
                            ></input>
                          </div>
                          <div>
                            <input
                              type="text"
                              value={
                                quotationDetails?.contact_person
                                  ? quotationDetails?.contact_person
                                  : " - "
                              }
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                              }}
                            ></input>
                          </div>

                          <div>
                            {quotationDetails?.contact_person_email ? (
                              <a href="#">
                                {quotationDetails?.contact_person_email}
                              </a>
                            ) : (
                              <input
                                value={" - "}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            )}
                          </div>
                          {quotationDetails?.product_type_name ==
                              "Air" && (
                              <div>
                                <input
                                value={quotationDetails?.cbm || " - "}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                              </div>
                            )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="100%" style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <td>
                        <div
                          style={{
                            textAlign: "center",
                            display: "inline-block",
                            background: "#002981",
                            color: "#ffff",
                            padding: "10px 40px",
                            margin: "0",
                          }}
                        >
                          <h5 style={{ padding: "0", margin: "0" }}>
                            Customer Name
                          </h5>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            display: "inline-block",
                            background: "#f6f5e3",
                            padding: "10px 40px",
                            margin: "0",
                          }}
                        >
                          <h5 style={{ padding: "0", margin: "0" }}>
                            {quotationDetails?.opportunity_name}
                          </h5>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="py-3">
                          <strong>Dear Customer:</strong> We are grateful for
                          your request and for trusting us to move your cargo
                          around the world .
                          <strong>Thanks for working with us!</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: "center",
                          background: "#002981",
                          color: "#ffff",
                          padding: "5px",
                        }}
                      >
                        <p style={{ padding: "0", margin: "0" }}>
                          Shipment Information
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                  {!selectedQuote?.qutoe?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <>
                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Service</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("product_type_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Modal Type</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <div>{inputFn("service_type")}</div>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Origin Country</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("origin_country")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Country</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  value={getValue("destination_country")}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              {checkOriginAddressDisplay() && (
                                <p>Origin Address</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              {checkOriginAddressDisplay() && (
                                <div style={{ paddingLeft: "2px" }}>
                                  {getPickUpData()}
                                </div>
                              )}
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {checkDestinationAddressDisplay() && (
                                <p>Destination Address</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {checkDestinationAddressDisplay() && (
                                <div style={{ paddingLeft: "2px" }}>
                                  {getDestinationData()}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Origin Port</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{getValue("loading_port_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Port</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>{getValue("discharge_port_name")}</div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Transit Time</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="transit_time_in_days"
                                  value={selectedQuote?.quote_prices?.transit_time_in_days}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails
                                ?.product_type_name == "Air" && (
                                <p>Stackable Cargo</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails
                                ?.product_type_name == "Air" && (
                                <div>
                                  <input
                                    type="text"
                                    value={
                                      quotationDetails
                                        ?.stackable_cargo
                                    }
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                  ></input>
                                </div>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              colSpan={6}
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                padding: "5px",
                              }}
                            >
                              <p style={{ padding: "0", margin: "0" }}>
                                Cargo Information
                              </p>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Commodity Description</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="cargo_description"
                                  value={
                                    quotationDetails
                                      ?.cargo_description
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails
                                ?.product_type_name == "Air" ? (
                                <p>General cargo</p>
                              ) : (
                                <p>Container Type</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  value={
                                    quotationDetails
                                      ?.product_type_name != "Air"
                                      ? getValue("container_type")
                                      : getValue("cargo_type")
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Customs Clearance</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("customer_clearance")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {quotationDetails
                                ?.product_type_name == "Air" ? (
                                <p>Air Service</p>
                              ) : (
                                <p>Free Days</p>
                              )}
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails
                                ?.product_type_name == "Air" ? (
                                <div>
                                  <input
                                    type="text"
                                    id="air_service"
                                    value={selectedQuote?.qutoe?.air_service}
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                  ></input>
                                </div>
                              ) : (
                                <p className="text-decoration-underline">
                                  {selectedQuote?.quote_prices?.free_days}
                                  {/* days at destination */}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>
                                {quotationDetails
                                  ?.product_type_name == "Air" && "Via"}
                              </p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  value={selectedQuote?.qutoe?.route_via}
                                  id="route_via"
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Insurance</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <span>
                                <strong>{getValue("require_insurance")}</strong>
                              </span>
                              {getValue("require_insurance")?.toLowerCase() ==
                                "yes" && (
                                <span
                                  style={{
                                    background: "#e8e8e8",
                                    padding: "5px 50px 5px 10px",
                                    marginLeft: "50px",
                                  }}
                                >
                                  {getValue(
                                    "require_insurance"
                                  )?.toLowerCase() == "yes" &&
                                    getValue("incase_insurance_required")}
                                </span>
                              )}
                            </td>
                          </tr>
                          {quotationDetails?.no_of_qty && (
                            <tr
                              style={{
                                width: "100%",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <td
                                style={{
                                  width: "20%",
                                  paddingLeft: "5px",
                                  background: "#e8e8e8",
                                  borderLeft: "1px solid #92a9bc",
                                  borderRight: "1px solid #92a9bc",
                                }}
                              >
                                <p>Number of units</p>
                              </td>

                              <td colSpan={2} style={{ width: "32%" }}>
                                <div>
                                  <input
                                    type="number"
                                    value={
                                      quotationDetails?.no_of_qty
                                        ? quotationDetails
                                            ?.no_of_qty
                                        : ""
                                    }
                                    id="route_via"
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                    disabled={!editSave}
                                    onChange={(e) => {
                                      // setno_of_qty(e.target.value);
                                      setQuotationDetails({
                                        ...quotationDetails,
                                        quote: {
                                          ...quotationDetails,
                                          no_of_qty: e.target.value,
                                        },
                                      });
                                    }}
                                  ></input>
                                </div>
                              </td>

                              <td
                                style={{
                                  width: "16%",
                                  background: "#e8e8e8",
                                  paddingLeft: "5px",
                                }}
                              >
                                {quotationDetails?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <p className="py-2">Cost per unit (20GP)</p>
                                    <p>Cost per unit (40GP)</p>
                                  </>
                                ) : (
                                  <p>Cost per unit</p>
                                )}
                              </td>
                              <td colSpan={2} style={{ width: "32px" }}>
                                {quotationDetails?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      className="py-2"
                                    >
                                      {quotationDetails
                                        ?.no_of_qty &&
                                        quotationDetails
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "20GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["20GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      // className="py-2"
                                    >
                                      {quotationDetails
                                        ?.no_of_qty &&
                                        quotationDetails
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "40GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["40GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                    }}
                                  >
                                    {quotationDetails?.no_of_qty &&
                                      quotationDetails?.no_of_qty !=
                                        "" && (
                                        <>
                                          {getDifferentCurrencies()?.map(
                                            (currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["other"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  {selectedQuote?.qutoe?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <>
                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Pickup Address</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{getPickUpData()}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Destination Address</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <div>{getDestinationData()}</div>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Service Type</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>{inputFn("product_type_name")}</div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Crossing Border</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <div>
                                <input
                                  type="text"
                                  id="border"
                                  value={selectedQuote?.qutoe?.border}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Transit Time</p>
                            </td>
                            <td colSpan={2} style={{ width: "32%" }}>
                              <p>
                                <input
                                  type="text"
                                  id="transit_time_in_days"
                                  value={selectedQuote?.quote_prices?.transit_time_in_days}
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </p>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              <p>Transport</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              <input
                                type="text"
                                id="transporter"
                                value={selectedQuote?.qutoe?.transporter}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                              ></input>
                            </td>
                          </tr>

                          {/* <tr
                          style={{
                            width: "100%",
                            borderRight: "1px solid #92a9bc",
                          }}
                        >
                          <td
                            style={{
                              width: "20%",
                              paddingLeft: "5px",
                              background: "#e8e8e8",
                              borderLeft: "1px solid #92a9bc",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <p>Transit Time</p>
                          </td>
                          <td colSpan={2} style={{ width: "32%" }}>
                            <div>
                              <input
                                type="text"
                                id="transit_time_in_days"
                                value={airObject?.transit_time_in_days}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  background: "none",
                                }}
                                onChange={(e)=>{
                                  handleAirObject(e);
                                }}
                              ></input>
                            </div>
                          </td>

                          <td
                            style={{
                              width: "16%",
                              background: "#e8e8e8",
                              paddingLeft: "5px",
                            }}
                          >
                            <p></p>
                          </td>
                          <td colSpan={2} style={{ width: "32px" }}></td>
                        </tr> */}
                        </tbody>
                      </table>

                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              colSpan={6}
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                padding: "5px",
                              }}
                            >
                              <p style={{ padding: "0", margin: "0" }}>
                                Cargo Information
                              </p>
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Commodity Description</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                <input
                                  type="text"
                                  id="cargo_description"
                                  value={
                                    quotationDetails
                                      ?.cargo_description
                                  }
                                  style={{
                                    border: "none",
                                    outline: "none",
                                    background: "none",
                                  }}
                                ></input>
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              Dangerous Goods
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.dangerous_goods}
                            </td>
                          </tr>
                          <tr
                            style={{
                              width: "100%",
                              borderRight: "1px solid #92a9bc",
                            }}
                          >
                            <td
                              style={{
                                width: "20%",
                                paddingLeft: "5px",
                                background: "#e8e8e8",
                                borderLeft: "1px solid #92a9bc",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <p>Stackable</p>
                            </td>

                            <td colSpan={2} style={{ width: "32%" }}>
                              <div>
                                {quotationDetails?.stackable_cargo}
                              </div>
                            </td>

                            <td
                              style={{
                                width: "16%",
                                background: "#e8e8e8",
                                paddingLeft: "5px",
                              }}
                            >
                              {" "}
                              <p>Insurance</p>
                            </td>
                            <td colSpan={2} style={{ width: "32px" }}>
                              {quotationDetails?.require_insurance}
                            </td>
                          </tr>
                          {quotationDetails?.no_of_qty && (
                            <tr
                              style={{
                                width: "100%",
                                borderRight: "1px solid #92a9bc",
                              }}
                            >
                              <td
                                style={{
                                  width: "20%",
                                  paddingLeft: "5px",
                                  background: "#e8e8e8",
                                  borderLeft: "1px solid #92a9bc",
                                  borderRight: "1px solid #92a9bc",
                                }}
                              >
                                <p>Number of units</p>
                              </td>

                              <td colSpan={2} style={{ width: "32%" }}>
                                <div>
                                  <input
                                    type="number"
                                    value={
                                      quotationDetails?.no_of_qty
                                        ? quotationDetails
                                            ?.no_of_qty
                                        : ""
                                    }
                                    id="route_via"
                                    style={{
                                      border: "none",
                                      outline: "none",
                                      background: "none",
                                    }}
                                    disabled={!editSave}
                                    onChange={(e) => {
                                      // setno_of_qty(e.target.value);
                                      setQuotationDetails({
                                        ...quotationDetails,
                                        quote: {
                                          ...quotationDetails,
                                          no_of_qty: e.target.value,
                                        },
                                      });
                                    }}
                                  ></input>
                                </div>
                              </td>

                              <td
                                style={{
                                  width: "16%",
                                  background: "#e8e8e8",
                                  paddingLeft: "5px",
                                }}
                              >
                                {quotationDetails?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <p className="py-2">Cost per unit (20GP)</p>
                                    <p>Cost per unit (40GP)</p>
                                  </>
                                ) : (
                                  <p>Cost per unit</p>
                                )}
                              </td>
                              <td colSpan={2} style={{ width: "32px" }}>
                                {quotationDetails?.container_type ==
                                "20GP + 40GP" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      className="py-2"
                                    >
                                      {quotationDetails
                                        ?.no_of_qty &&
                                        quotationDetails
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "20GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["20GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                      }}
                                      // className="py-2"
                                    >
                                      {quotationDetails
                                        ?.no_of_qty &&
                                        quotationDetails
                                          ?.no_of_qty != "" && (
                                          <>
                                            {getDifferentCurrencies(
                                              "40GP"
                                            )?.map((currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["40GP"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "5px",
                                    }}
                                  >
                                    {quotationDetails?.no_of_qty &&
                                      quotationDetails?.no_of_qty !=
                                        "" && (
                                        <>
                                          {getDifferentCurrencies()?.map(
                                            (currency, index, arr) => (
                                              <div>
                                                <span>
                                                  {(
                                                    grossTotalCurrencies[
                                                      currency
                                                    ]["other"] /
                                                    parseInt(
                                                      quotationDetails
                                                        ?.no_of_qty || 1
                                                    )
                                                  )?.toFixed(2) || ""}
                                                </span>
                                                <span
                                                  className="fw-bold"
                                                  style={{ paddingLeft: "2px" }}
                                                >
                                                  ({currency})
                                                </span>
                                                <span>
                                                  {index !== arr.length - 1 &&
                                                    ","}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </>
                                      )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  {selectedQuote?.qutoe?.product_type_name == "Air" && (
                    <div className="d-flex ">
                      <table
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                        className="air_case mt-3 me-3"
                      >
                        <tr>
                          <th style={{ background: "#e8e8e8" }}>
                            {quotationDetails?.pallet_description}
                          </th>
                          <th style={{ background: "#e8e8e8" }}>Length CM</th>
                          <th style={{ background: "#e8e8e8" }}>Width CM</th>
                          <th style={{ background: "#e8e8e8" }}>Height CM</th>
                          <th style={{ background: "#e8e8e8" }}>
                            Weight Per Pallet in Kg
                          </th>
                          <th style={{ background: "#e8e8e8" }}>
                            Gross Weight
                          </th>
                          <th style={{ background: "#e8e8e8" }}>
                            Volume Weight
                          </th>
                        </tr>
                        <tr>
                          <td>{quotationDetails?.no_of_pallet}</td>
                          <td>{getCargoMesurements("length")}</td>
                          <td>{getCargoMesurements("width")}</td>
                          <td>{getCargoMesurements("height")}</td>
                          <td>
                            <input
                              id="weight_per_pallet"
                              value={quotationDetails?.total_weight}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          <td>
                            <input
                              id="gross_weight_tons"
                              value={quotationDetails?.gross_weight}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          <td>
                            <input
                              id="volume_weight"
                              value={quotationDetails?.total_volume}
                              style={{
                                border: "none",
                                outline: "none",
                                background: "none",
                                textAlign: "center",
                              }}
                            ></input>
                          </td>
                          {/* <span> +1000 KG</span> */}
                        </tr>
                      </table>
                      <table
                        style={{ borderCollapse: "collapse" }}
                        className="air_case mt-3"
                      >
                        <tr>
                          {" "}
                          <th style={{ background: "#e8e8e8" }}>Basis Quote</th>
                        </tr>
                        <tr>
                          <td style={{ background: "#002981", color: "#ffff" }}>
                            {Math.max(
                              parseFloat(
                                quotationDetails?.total_volume
                              ) || 0,
                              parseFloat(
                                quotationDetails?.gross_weight
                              ) || 0
                            ) || ""}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                  {/* Part Two */}
                  {/* In case of air */}
                  {quotationDetails?.product_type_name == "Air" && (
                    <Row>
                      <Col md={10}>
                      <div>
                        <table style={{ borderCollapse: "collapse" }}>
                          <tbody>
                            <tr>
                              <td colSpan={6} className="">
                                <p>&nbsp;</p>
                              </td>
                            </tr>
                            {rateTypeCodes?.map(
                              (rate_type_code, rate_type_code_index) => (
                                <>
                                  <tr key={rate_type_code}>
                                    <td
                                      style={{
                                        background: "#002981",
                                        color: "#ffff",
                                        borderBottom: "4px solid #e9b217",
                                        padding: "5px 0",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                      }}
                                    >
                                      <div>
                                        {rate_type_code?.toUpperCase()} RATES
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    {rate_type_code_index == 0 && (
                                      <div className="w-100">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "29%",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          CONCEPT
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          CURRENCY
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          RATE PER KG
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          MARKUP RATE PER KG
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          AMOUNT
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            fontWeight: "bold",
                                            width: "13%",
                                          }}
                                        >
                                          MARKUP AMOUNT
                                        </div>
                                      </div>
                                    )}

                                    {/* Freight */}
                                    {rate_type_code?.toUpperCase() ==
                                      "FREIGHT" && (
                                      <>
                                        {selectedQuote?.quote_price_breackup
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "29%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.actual_rate_usd
                                                  ? quote?.actual_rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.rate_usd
                                                  ? quote?.rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "13%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {/* origin */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ORIGIN" && (
                                      <>
                                        {selectedQuote?.quote_price_breackup
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_rate_usd
                                                  ? quote?.actual_rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.rate_usd
                                                  ? quote?.rate_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.rate_description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {/* destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "DESTINATION" && (
                                      <>
                                        {selectedQuote?.quote_price_breackup
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                            </div>
                                          ))}
                                      </>
                                    )}
                                    {rate_type_code?.toUpperCase() ==
                                      "ADDON" && (
                                      <>
                                        {selectedQuote?.quote_price_breackup
                                          ?.filter((quote) => {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          })
                                          ?.map((quote, quote_index) => (
                                            <div
                                              className="w-100"
                                              key={quote?.description}
                                            >
                                              <div
                                                style={{
                                                  display: "inline-block",
                                                  width: "20%",
                                                }}
                                              >
                                                {quote?.description}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  value={
                                                    quote?.rate_currency
                                                      ? quote?.rate_currency
                                                      : ""
                                                  }
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.actual_amount_usd
                                                  ? quote?.actual_amount_usd
                                                  : ""}
                                              </div>
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  width: "10.8%",
                                                }}
                                              >
                                                {quote?.amount_usd
                                                  ? quote?.amount_usd
                                                  : ""}
                                              </div>
                                            </div>
                                          ))}
                                      </>
                                    )}

                                    {/* Frieght */}

                                    {rate_type_code?.toUpperCase() ==
                                      "FREIGHT" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "29%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "13%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "13%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "13%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Origin */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ORIGIN" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "rate_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "20%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={""}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "DESTINATION" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                    {/* Destination */}
                                    {rate_type_code?.toUpperCase() ==
                                      "ADDON" && (
                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "20%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            Total{" "}
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}{" "}
                                            Rates
                                          </p>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value="USD"
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "10.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "10.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getAirRates(
                                              rate_type_code,
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                  </tr>
                                </>
                              )
                            )}

                            {/* total */}
                            <tr className="w-100">
                              <td
                                style={{
                                  textAlign: "center",
                                  background: "#002981",
                                  color: "#ffff",
                                  borderTop: "4px solid #e9b217",
                                  padding: "5px 0",
                                }}
                              >
                                TOTAL
                              </td>
                            </tr>
                            
                                <tr className="w-100">
                                  <td>
                                    <div className="w-100">
                                      <div
                                        className="bg-gray"
                                        style={{
                                          display: "inline-block",
                                          width: "31%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <p className="p-0 m-0">
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}
                                                Rates
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "10.8%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                // value={finalRates(rate_type_code)}
                                                value={getIndividualRatesOriginal(
                                                  rate_type_code,
                                                  "other_original"
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "15%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value={getIndividualRates(
                                                  rate_type_code,
                                                  "other"
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                      <div
                                        className="table-div"
                                        style={{
                                          display: "inline-block",
                                          width: "13%",
                                        }}
                                      >
                                        {rateTypeCodes?.map(
                                          (
                                            rate_type_code,
                                            rate_type_code_index
                                          ) => (
                                            <div key={rate_type_code}>
                                              <input
                                                type="text"
                                                value="USD"
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                {getDifferentCurrencies()?.map(
                                  (currency, index, arr) => (
                                    <tr className="w-100">
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                        }}
                                      >
                                        <div className="w-100">
                                          <div
                                            style={{
                                              display: "inline-block",
                                              width: "31%",
                                            }}
                                          >
                                            <p>GROSS TOTAL {currency}</p>
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "10.8%",
                                            }}
                                          >
                                            <p>
                                              {grossTotalDisplayOriginal(
                                                "other_original",
                                                currency
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              width: "15%",
                                            }}
                                          >

                                            <p>
                                              {grossTotalDisplay(
                                                "other",
                                                currency
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              width: "13%",
                                              display: "inline-block",
                                            }}
                                          >
                                            <p>{currency}</p>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              
                          </tbody>
                        </table>
                      </div>
                    </Col>

                      <Col md={2} className="mt-4">
                        

                        {selectedQuote?.quote_prices?.comment && (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 4px",
                                width: "100%",
                                marginTop: editSave && "10px",
                              }}
                            >
                              COMMENTS
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "fit-content",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCommentData(selectedQuote?.quote_prices?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn px-2 w-100 "
                                >
                                  View Comments
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>

                    </Row>
                  )}
                  {/* In case of truck */}
                  {quotationDetails?.product_type_name?.includes(
                    "TRUCK"
                  ) && (
                    <Row>
                      {getMappingFn()?.map((cont, cont_index) => (
                      <Col md={10}>
                        <div className="mt-4">
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "80%",
                            }}
                          >
                            <tbody>
                              {rateTypeCodes?.map(
                                (rate_type_code, rate_type_code_index) => (
                                  <>
                                    <tr key={rate_type_code}>
                                      <td
                                        style={{
                                          background: "#002981",
                                          color: "#ffff",
                                          borderBottom: "4px solid #e9b217",
                                          padding: "5px 0",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          alignContent: "center",
                                        }}
                                      >
                                        <div>
                                          {rate_type_code?.toUpperCase()} RATES
                                        </div>
                                      </td>
                                    </tr>

                                    <tr>
                                      {rate_type_code_index == 0 && (
                                        <div className="w-100">
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "16%",
                                            }}
                                          >
                                            Concept
                                          </div>

                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            Currency
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            Original
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "11.8%",
                                            }}
                                          >
                                            Markup
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10.9%",
                                            }}
                                          >
                                            #Palletes
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10.9%",
                                            }}
                                          >
                                            #Pieces
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10%",
                                            }}
                                          >
                                            Cost per piece
                                          </div>
                                          <div
                                            className="table-div"
                                            style={{
                                              display: "inline-block",
                                              fontWeight: "bold",
                                              width: "10%",
                                            }}
                                          >
                                            Markup Cost per piece
                                          </div>
                                        </div>
                                      )}

                                      {selectedQuote?.quote_price_breackup
                                        ?.filter((quote) => {
                                          if (cont == "other") {
                                            return quote?.rate_type_code?.includes(
                                              rate_type_code
                                            );
                                          } else {
                                            return (
                                              quote?.rate_type_code?.includes(
                                                rate_type_code
                                              ) && quote?.container_type == cont
                                            );
                                          }
                                        })
                                        ?.map((quote, quote_index) => (
                                          <div
                                            className="w-100"
                                            key={quote?.description}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "16%",
                                              }}
                                            >
                                              {quote?.description}
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.rate_currency}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.actual_amount_usd
                                                ? quote?.actual_amount_usd
                                                : ""}
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "11.8%",
                                              }}
                                            >
                                              {quote?.amount_usd
                                                ? quote?.amount_usd
                                                : ""}
                                            </div>
                                            {rate_type_code?.toLowerCase() !=
                                              "addon" && (
                                              <>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10.9%",
                                                  }}
                                                >
                                                  {quote?.pallet
                                                    ? quote?.pallet
                                                    : ""}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10.9%",
                                                  }}
                                                >
                                                  {quote?.qty
                                                    ? parseInt(quote?.qty || 0)
                                                    : ""}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10%",
                                                  }}
                                                >
                                                  {(
                                                    parseFloat(
                                                      quote?.actual_amount_usd
                                                    ) / parseFloat(quote?.qty)
                                                  )?.toFixed(2)}
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "10%",
                                                  }}
                                                >
                                                  {(
                                                    parseFloat(
                                                      quote?.amount_usd
                                                    ) / parseFloat(quote?.qty)
                                                  )?.toFixed(2)}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        ))}

                                      <div className="w-100 bg-gray">
                                        <div
                                          style={{
                                            display: "inline-block",
                                            width: "15.8%",
                                          }}
                                        >
                                          <p className="p-0 m-0">
                                            {capitalizeFirstLetter(
                                              rate_type_code
                                            )}
                                            &nbsp; Rates
                                          </p>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "11.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getBlockCurrency(
                                              rate_type_code
                                            )}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "11.8%",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getTruckRates(
                                              rate_type_code,
                                              "other",
                                              "actual_amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            width: "15%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            value={getTruckRates(
                                              rate_type_code,
                                              "other",
                                              "amount_usd"
                                            )?.toFixed(2)}
                                            style={{
                                              border: "none",
                                              outline: "none",
                                              background: "none",
                                            }}
                                          ></input>
                                        </div>
                                      </div>
                                    </tr>
                                  </>
                                )
                              )}

                              {/* total */}
                              <tr className="w-100">
                                <td
                                  style={{
                                    textAlign: "center",
                                    background: "#002981",
                                    color: "#ffff",
                                    borderTop: "4px solid #e9b217",
                                    padding: "5px 0",
                                  }}
                                >
                                  TOTAL
                                </td>
                              </tr>
                              
                                  <tr className="w-100">
                                    <td>
                                      <div className="w-100">
                                        <div
                                          className="bg-gray"
                                          style={{
                                            display: "inline-block",
                                            width: "15.5%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <p className="p-0 m-0">
                                                  {capitalizeFirstLetter(
                                                    rate_type_code
                                                  )}
                                                  &nbsp; Rates
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "12%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getBlockCurrency(
                                                    rate_type_code
                                                  )}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "11.8%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getTruckRates(
                                                    rate_type_code,
                                                    cont,
                                                    "actual_amount_usd"
                                                  )?.toFixed(2)}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            width: "11.8%",
                                            display: "inline-block",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value={getTruckRates(
                                                    rate_type_code,
                                                    cont,
                                                    "amount_usd"
                                                  )?.toFixed(2)}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {getDifferentCurrencies()?.map(
                                    (currency, index, arr) => (
                                      <tr className="w-100">
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                          }}
                                        >
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "27.5%",
                                              }}
                                            >
                                              <p>GROSS TOTAL {currency}</p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "12%",
                                              }}
                                            >
                                              {/* <p>{grossTotalTruck()}</p> */}
                                              <p>
                                                {grossTotalDisplayOriginal(
                                                  "other_original",
                                                  currency
                                                )}
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                width: "15%",
                                                display: "inline-block",
                                              }}
                                            >
                                              <p>
                                                {grossTotalDisplay(
                                                  "other",
                                                  currency
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    ))}
                      <Col md={2} className="mt-4">

                        {selectedQuote?.quote_prices?.comment && (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                background: "#002981",
                                color: "#ffff",
                                borderBottom: "4px solid #e9b217",
                                padding: "5px 4px",
                                width: "100%",
                                marginTop: editSave && "10px",
                              }}
                            >
                              COMMENTS
                            </div>
                            <div
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "fit-content",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    setCommentData(selectedQuote?.quote_prices?.comment);
                                    handleShowCustomerComments();
                                  }}
                                  className="tracking-btn d-flex justify-content-center px-2 w-100"
                                >
                                  <span>View Comments</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                  {/* In case of ocean */}
                  {quotationDetails?.product_type_name != "Air" &&
                    !quotationDetails?.product_type_name?.includes(
                      "TRUCK"
                    ) && (
                      <Row>
                        {getMappingFn()?.map((cont, cont_index) => (
                          <Col md={6}>
                            <div>
                              {cont != "other" && (
                                <h4 className="text-center pt-3 pb-2">
                                  CONTAINER TYPE {cont}
                                </h4>
                              )}
                              <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                  {cont == "other" && (
                                    <tr>
                                      <td colSpan={6} className="">
                                        <p>&nbsp;</p>
                                      </td>
                                    </tr>
                                  )}
                                  {rateTypeCodes?.map(
                                    (rate_type_code, rate_type_code_index) => (
                                      <>
                                        <tr key={rate_type_code}>
                                          <td
                                            style={{
                                              textAlign: "center",
                                              background: "#002981",
                                              color: "#ffff",
                                              borderBottom: "4px solid #e9b217",
                                              padding: "5px 0",
                                              // position: "relative",
                                              alignItems: "center",
                                              alignContent: "center",
                                            }}
                                          >
                                            <div></div>
                                            {rate_type_code?.toUpperCase()}{" "}
                                            RATES
                                          </td>
                                        </tr>

                                        <tr>
                                        {rate_type_code_index == 0 && (
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "45%",
                                              }}
                                            >
                                              <p className="p-0 m-0"></p>
                                            </div>

                                           
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  fontWeight: "bold",
                                                  width: "15%",
                                                }}
                                              >
                                                Markup Price
                                              </div>
                                            
                                            
                                              <div
                                                className="table-div"
                                                style={{
                                                  display: "inline-block",
                                                  fontWeight: "bold",
                                                  width: "15%",
                                                }}
                                              >
                                                Original Price
                                              </div>
                                            
                                          </div>
                                        )}

                                          {selectedQuote?.quote_price_breackup
                                            ?.filter((quote) => {
                                              if (cont == "other") {
                                                return quote?.rate_type_code?.includes(
                                                  rate_type_code
                                                );
                                              } else {
                                                return (
                                                  quote?.rate_type_code?.includes(
                                                    rate_type_code
                                                  ) &&
                                                  quote?.container_type == cont
                                                );
                                              }
                                            })
                                            ?.map((quote, quote_index) => (
                                              <div
                                                className="w-100"
                                                // style={{ position: "relative" }}
                                                key={quote?.description}
                                              >
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                    width: "45%",
                                                  }}
                                                >
                                                  {quote?.description}
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "15%",
                                                  }}
                                                >
                                                  <input
                                                    type="number"
                                                    value={
                                                      quote?.amount_usd
                                                        ? quote?.amount_usd
                                                        : ""
                                                    }
                                                    className="arrows_hide"
                                                    // onChange={(e) =>
                                                    //   modifyRates(e, quote)
                                                    // }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "15%",
                                                  }}
                                                >
                                                  <input
                                                    type="number"
                                                    value={
                                                      quote?.actual_amount_usd
                                                        ? quote?.actual_amount_usd
                                                        : ""
                                                    }
                                                    className="arrows_hide"
                                                    // onChange={(e) =>
                                                    //   modifyRates(e, quote)
                                                    // }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>

                                                <div
                                                  className="table-div"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "15%",
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={
                                                      quote?.rate_currency
                                                        ? quote?.rate_currency
                                                        : ""
                                                    }
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              </div>
                                            ))}

                                          <div className="w-100 bg-gray">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "45%",
                                              }}
                                            >
                                              <p className="p-0 m-0">
                                                Total{" "}
                                                {capitalizeFirstLetter(
                                                  rate_type_code
                                                )}{" "}
                                                Rates
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "15%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={getFrieghtRates(
                                                  rate_type_code,
                                                  cont
                                                )?.toFixed(2)}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                            <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display: "inline-block"
                                            }}
                                          >
                                            <input
                                              type="text"
                                              value={getFrieghtRatesOriginal(
                                                rate_type_code,
                                                cont
                                              ).toFixed(2)}
                                              style={{
                                                border: "none",
                                                outline: "none",
                                                background: "none",
                                              }}
                                            ></input>
                                          </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "15%",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                value={getBlockCurrency(
                                                  rate_type_code,
                                                  cont
                                                )}
                                                style={{
                                                  border: "none",
                                                  outline: "none",
                                                  background: "none",
                                                }}
                                              ></input>
                                            </div>
                                          </div>
                                        </tr>
                                      </>
                                    )
                                  )}

                                  {/* total */}
                                  <tr className="w-100">
                                    <td
                                      style={{
                                        textAlign: "center",
                                        background: "#002981",
                                        color: "#ffff",
                                        borderTop: "4px solid #e9b217",
                                        padding: "5px 0",
                                      }}
                                    >
                                      TOTAL
                                    </td>
                                  </tr>
                                  <tr className="w-100">
                                    <td>
                                      <div className="w-100">
                                        <div
                                          className="bg-gray"
                                          style={{
                                            display: "inline-block",
                                            width: "45%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <p className="p-0 m-0">
                                                  {capitalizeFirstLetter(
                                                    rate_type_code
                                                  )}
                                                  Rates
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>

                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "15%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  // value={getFrieghtRates(
                                                  //   rate_type_code,
                                                  //   cont
                                                  // )?.toFixed(2)}
                                                  value={getIndividualRates(
                                                    rate_type_code,
                                                    "other"
                                                  )}
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        <div
                                            className="table-div"
                                            style={{
                                              width: "15%",
                                              display:"inline-block"
                                            }}
                                          >
                                            {rateTypeCodes?.map(
                                              (
                                                rate_type_code,
                                                rate_type_code_index
                                              ) => (
                                                <div key={rate_type_code}>
                                                  <input
                                                    type="text"
                                                    value={getIndividualRatesOriginal(
                                                      rate_type_code,
                                                      cont
                                                    )}
                                                    // value={""}
                                                    style={{
                                                      border: "none",
                                                      outline: "none",
                                                      background: "none",
                                                    }}
                                                  ></input>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        <div
                                          className="table-div"
                                          style={{
                                            display: "inline-block",
                                            width: "15%",
                                          }}
                                        >
                                          {rateTypeCodes?.map(
                                            (
                                              rate_type_code,
                                              rate_type_code_index
                                            ) => (
                                              <div key={rate_type_code}>
                                                <input
                                                  type="text"
                                                  value="USD"
                                                  style={{
                                                    border: "none",
                                                    outline: "none",
                                                    background: "none",
                                                  }}
                                                ></input>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {getDifferentCurrencies(cont)?.map(
                                    (currency, index, arr) => (
                                      <tr className="w-100">
                                        <td
                                          style={{
                                            background: "#002981",
                                            color: "#ffff",
                                            borderBottom: "4px solid #e9b217",
                                            padding: "5px 0",
                                          }}
                                        >
                                          <div className="w-100">
                                            <div
                                              style={{
                                                display: "inline-block",
                                                width: "45%",
                                              }}
                                            >
                                              <p>GROSS TOTAL {currency}</p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "15%",
                                              }}
                                            >
                                              {/* <p>{grossTotal(cont)}</p> */}
                                              <p>
                                                {grossTotalDisplay(
                                                  cont,
                                                  currency
                                                )}
                                              </p>
                                            </div>
                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "15%",
                                              }}
                                            >
                                              <p>
                                                {grossTotalDisplayOriginal(
                                                  cont,
                                                  currency
                                                )}
                                              </p>
                                            </div>

                                            <div
                                              className="table-div"
                                              style={{
                                                display: "inline-block",
                                                width: "15%",
                                              }}
                                            >
                                              <p>{currency}</p>
                                            </div>
                                          </div>
                                        </td>
                                        
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        ))}
                        <Col md={6} className="mt-4">
                          {selectedQuote?.quote_prices?.comment && (
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  background: "#002981",
                                  color: "#ffff",
                                  borderBottom: "4px solid #e9b217",
                                  padding: "5px 4px",
                                  width: "max-content",
                                }}
                              >
                                COMMENTS
                              </div>
                              <div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "30%",
                                  }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setCommentData(selectedQuote?.quote_prices?.comment);
                                      handleShowCustomerComments();
                                    }}
                                    className="tracking-btn d-flex justify-content-center px-2 w-100"
                                  >
                                    <span>View Comments</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                        {getValue("container_type") == "20GP + 40GP" && (
                          <Col md={6} className="mt-4 d-flex flex-column">
                            <div className="mt-auto ms-auto text-right">
                              <img src={logo} alt="Logo" />
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
              </div>
            </Col>
          </Row>
        ) : (
          <div className="w-100 d-flex justify-content-center aligin-items-center mt-5">
            <Loader />
          </div>
        )}
        
      </Container>
      <Modal
        size="xl"
        backdrop="static"
        show={showCustomerComments}
        centered
        scrollable
        onHide={() => {
          // setCommentData("")
          handleCloseCustomerComments();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }} onClick={() => {}}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Comments</h2>
          <form className="p-4">
            {/* <div className="input-box mb-4">
              <textarea
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Add Comment"
                id="comments"
                value={selectedLabel?.comment}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    comment: e.target.value,
                  });
                }}
              />
            </div> */}
            <Ckeditor
              commentData={commentData}
              setCommentData={setCommentData}
              editSave={editSave}
              quotestatus={selectedQuote?.qutoe?.quote_status}
              from="filter"
            />
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Quote_details;
