import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import { Container, Modal, Row, Col } from "react-bootstrap";
import axios from "axios";
import Loader from "../components/Loader";
import Select from "react-select";
function Modals() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [showaddModal, setShowaddModal] = useState(false);
  const [allModals, setallModals] = useState([]);
  const [modalNames, setmodalNames] = useState({
    modal_name: "",
    modal_description: "",
    transport_type:null
  });
 
  const navigate = useNavigate();


 

  const getAllModals = async () => {
    setIsLoading(true)
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getModals`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        // console.log(response.data)
        setallModals(response.data.modals);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }finally{
        setIsLoading(false)
    }
  };

  const addModal = async (e) => {
    e.preventDefault();
    setIsLoadingModal(true)
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/admin/modal/add`,
        method: "POST",
        data:{
            modal_name:modalNames.modal_name,
            modal_description:modalNames.modal_description,
            transport_type:modalNames?.transport_type?.value
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        getAllModals()
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }finally{
        setIsLoadingModal(false)
        
        setShowaddModal(false)
    }
  };

 

  useEffect(() => {
    getAllModals();
  }, []);

  return (
    <>
      <NavBar />
      <Container fluid className="wrapper">
        <div className="">
          <div className="py-3">
            <button
              type="button"
              className="back-btn d-flex alig-items-center"
              onClick={() => {
                navigate("/");
              }}
            >
              <svg className="icon me-2 fs-22">
                <use href="#icon_leftarrow"></use>
              </svg>
              <strong className="fs-14">Back</strong>
            </button>
            <h2 className="fw-700 fs-24 text-center ">Manage Modals</h2>
          </div>
          <div className="d-flex">
            <div className="w-25"></div>

            <div className="ms-auto">
              <div className="row">
                
                <div className="col">
                  <button
                    type="button"
                    className="grd-btn rounded-pill"
                    onClick={() => {
                      setShowaddModal(true);
                    }}
                  >
                    Add Modal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {allModals?.length && !isLoading ? (
          <div className="fw-600 ">
            <div className="w-100 overflow-auto vendor-tbl d-flex justify-content-center">
              <table style={{width:'fit-content'}} class="table text-center my-4 border ">
                <thead>
                  <tr className="border" style={{ background: "#EBEEFF" }}>
                    <th>Sr</th>
                    <th>Modal Name</th>
                    <th>Modal Description</th>
                    <th>Transport Type</th>
                  </tr>
                </thead>
                <tbody>
                  {allModals?.length
                    ? allModals?.map((item, index) => (
                        <tr key={"item" + item?.modal_name}>
                          <td>{index+1}</td>
                          <td>{item?.modal_name}</td>
                          <td>{item?.modal_description}</td>
                          <td>{item?.transport_type}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Container>

      <Modal
        show={showaddModal}
        centered
        onHide={() => {
          setShowaddModal(false);
        }}
        className="w-100"
        scrollable={true}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-4">
          <p className="text-center fs-26 fw-bold pb-2">Add Modal</p>
          <Row>
            <Col md={12}>
              <div className="col">
                <form onSubmit={addModal} className="p-4">
                  <div className="input-box mb-3">
                    {modalNames.modal_name && modalNames.modal_name != "" && (
                      <label htmlFor="modal_name">Modal Name*</label>
                    )}
                    <input
                      value={modalNames.modal_name}
                      onChange={(e) => {
                        setmodalNames({
                          ...modalNames,
                          modal_name: e.target.value,
                        });
                      }}
                      required
                      type="text"
                      className="w-100"
                      id="modal_name"
                      placeholder="Enter Modal Name*"
                    />
                  </div>

                  <div className="input-box mb-3">
                    {modalNames.modal_description &&
                      modalNames.modal_description != "" && (
                        <label htmlFor="modal_description">
                          Modal Description*
                        </label>
                      )}
                    <input
                      value={modalNames.modal_description}
                      onChange={(e) => {
                        setmodalNames({
                          ...modalNames,
                          modal_description: e.target.value,
                        });
                      }}
                      required
                      type="text"
                      id="modal_description"
                      className="w-100"
                      placeholder="Enter Modal Description*"
                    />
                  </div>
                  <div className="input-box mb-3">
                    {modalNames.transport_type &&
                      !modalNames.transport_type && (
                        <label htmlFor="transport_type">
                          Transport Type*
                        </label>
                      )}
                    
                    <Select
              placeholder="Select Transport Type"
              required
              value={modalNames.transport_type}
              onChange={(e)=>setmodalNames({...modalNames,transport_type:e})}
              options={[{label:'Air',value:'Air'},{label:'Land',value:'Land'},{label:'Ocean',value:'Ocean'}]}
            />
                  </div>

                  <div className="d-flex">
                    <div className="ms-auto fw-600"></div>
                  </div>

                  <div className="d-flex">
                    <div className="ms-auto">
                      <button
                        type="submit"
                        className="grd-btn rounded-pill"
                        
                        disabled={isLoadingModal}
                      >
                        {isLoadingModal ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modals;
