import {React} from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import NavBar from '../components/NavBar'


function Crm() {
  return (
    <>
    <NavBar/>
    <Container>
      <Row>
        <Col md={12}>
          <div className='wrapper-box mt-5'>
           
          </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Crm