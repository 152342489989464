import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useContext, useEffect, useRef, useState } from "react";
import { CustomerContext } from "../context/customerContext";
import { ContainerContext } from "../context/containerContext";
import { Breadcrumb } from "antd";
import axios from "axios";
import Loader from "./Loader";
import { conditionalExp, getCustomerDetails, getDemandForcastType } from "../utils/commonFn";

function AsideBar() {
  const [customerData, setCustomerData] = useState();
  const [editEnable, setEditEnable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rfcLength, setrfcLength] = useState(0)
  // const [vatRegistrationNumber, setVatRegistrationNumber] = useState("");
  const [rfcNumber, setRfcNumber] = useState("");
  const [vatRegistrationError, setVatRegistrationError] = useState("");
  const [rfcError, setrfcError] = useState("");
  const [demandforcasttypes, setDemandforcasttypes] = useState([{
    label:'weekly',
    value:'weekly'
  },
  {
    label:'monthly',
    value:'monthly'
  },
  {
    label:'quarterly',
    value:'quarterly'
  }
])
  const [
    organization_name,
    web_url,
    industry,
    opportunity_size,
    credit_amount_limit,
    customer_name,
    customer_email,
    customer_phone,
    address1,
    address2,
    city,
    staate,
    country,
    zip_code,
    cfdi_purpose,
sat_tax_tegime_classification,
vat_registration_no,
rfc_no,
genbus_posting_group,
vatbus_posting_group,
tax_identifier_type,
customer_posting_group,
currency,
payment_terms_code,
payment_method_code,
demand_forcast_type_ref
  ] = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const { customer, updateCustomer } = useContext(CustomerContext);
  const { containers, selectedContainer, updateSelectedContainer } =
    useContext(ContainerContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { customerId,warehouse_id,warehouse_name } = useParams();
  const { state } = location;
  let brItems = [
    {
      title: (
        <Link
          className="text-decoration-none "
          to={`/${customerId}`}
          state={state}
        >
          <svg className="icon me-2 fs-22">
            <use href="#icon_leftarrow"></use>
          </svg>
          {state?.previous?.tab?.toUpperCase()}
        </Link>
      ),
      key: "home",
    },
  ];
  if (state?.previous?.tab == "quotes") {
    brItems.push({
      title: state?.quote?.custom_task_id,
    });
  }
  
  function checkValue(newValue,oldValue,oldValue2) {
    if(newValue == oldValue || newValue == oldValue2){
      return null;
    }else{
      return newValue
    }
  }

  async function handleSave() {
    setIsLoading(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let data = {
        customer_id: customerId,
        organization_name: checkValue(organization_name.current.value,customer?.organization_name,customerData?.organization_name) ,
        demand_forcast_type: checkValue(demand_forcast_type_ref.current.value,customer?.demand_forcast_type,customerData?.demand_forcast_type) ,
        web_url: checkValue(web_url.current.value,customer?.web_url,customerData?.web_url) ,
        industry: checkValue(industry.current.value,customer?.industry,customerData?.industry) ,
        opportunity_size: checkValue(opportunity_size.current.value,customer?.opportunity_size,customerData?.opportunity_size) || null ,
        credit_amount_limit: checkValue(credit_amount_limit.current.value,customer?.credit_amount_limit,customerData?.credit_amount_limit) || null ,
        customer_name: checkValue(customer_name.current.value,customer?.customer_name,customerData?.customer_name) ,
        customer_email: checkValue(customer_email.current.value,customer?.customer_email,customerData?.customer_email) ,
        customer_phone: checkValue(customer_phone.current.value,customer?.customer_phone,customerData?.customer_phone) ,
        address1: checkValue(address1.current.value,customer?.address1,customerData?.address1) ,
        address2: checkValue(address2.current.value,customer?.address2,customerData?.address2) ,
        city: checkValue(city.current.value,customer?.city,customerData?.city) ,
        state: checkValue(staate.current.value,customer?.state,customerData?.state) ,
        country: checkValue(country.current.value,customer?.country,customerData?.country) ,
        zip_code: checkValue(zip_code.current.value,customer?.zip_code,customerData?.zip_code) ,
        cfdi_purpose: checkValue(cfdi_purpose.current.value,customer?.cfdi_purpose,customerData?.cfdi_purpose) ,
        sat_tax_tegime_classification: checkValue(sat_tax_tegime_classification.current.value,customer?.sat_tax_tegime_classification,customerData?.sat_tax_tegime_classification) ,

        vat_registration_no: checkValue(vat_registration_no.current.value,customer?.vat_registration_no,customerData?.vat_registration_no) ,
        rfc_no: checkValue(rfc_no?.current.value,customer?.rfc_no,customerData?.rfc_no) ,

        genbus_posting_group: checkValue(genbus_posting_group.current.value,customer?.genbus_posting_group,customerData?.genbus_posting_group) ,
        vatbus_posting_group: checkValue(vatbus_posting_group.current.value,customer?.vatbus_posting_group,customerData?.vatbus_posting_group) ,
        tax_identifier_type: checkValue(tax_identifier_type.current.value,customer?.tax_identifier_type,customerData?.tax_identifier_type) ,
        customer_posting_group: checkValue(customer_posting_group.current.value,customer?.customer_posting_group,customerData?.customer_posting_group) ,
        currency: checkValue(currency.current.value,customer?.currency,customerData?.currency) ,
        payment_terms_code: checkValue(payment_terms_code.current.value,customer?.payment_terms_code,customerData?.payment_terms_code) ,
        payment_method_code: checkValue(payment_method_code.current.value,customer?.payment_method_code,customerData?.payment_method_code) ,
      }
      let filteredObj = {};
for (const key in data) {
  if (data[key] !== null && data[key] !== undefined) {
    filteredObj[key] = data[key];
  }
}

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/info/update`,
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: filteredObj,
      });

      if (!response.data.error) {
        await getCustomerDetails(setIsLoading,customerId,navigate,setCustomerData,updateCustomer,setRfcNumber);
        setIsLoading(false);
      }else{
        let errmsg = response.data?.message;
        if(typeof response.data?.err == 'object' && response.data?.err?.error?.message){
          errmsg = response.data?.err?.error?.message
        }
        alert(errmsg)
        setIsLoading(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      setIsLoading(false);
    } finally {
      setEditEnable(true);
      setrfcLength(0)
      // setRfcNumber(customer?.rfc_no || customerData?.rfc_no)
      // setVatRegistrationNumber(customer?.vat_registration_no || customerData?.vat_registration_no)
        setVatRegistrationError("")
        setrfcError("")
    }
  }

  async function handleCancel (){
    
    await getCustomerDetails(setIsLoading,customerId,navigate,setCustomerData,updateCustomer,setRfcNumber,);
    setEditEnable(true)
  }

  useEffect(() => {
    (async () => {
      await getCustomerDetails(setIsLoading,customerId,navigate,setCustomerData,updateCustomer,setRfcNumber,);
      await getDemandForcastType(setDemandforcasttypes,navigate)
    })();
  }, []);

  return (
    <>
      <aside className="aside-form">
        {conditionalExp(location.pathname == `/${customer?.customer_id || customerId}` || location.pathname == `/${customer?.customer_id || customerId}/apo ` || location.pathname == `/${customer?.customer_id || customerId}/warehouse_users/${warehouse_id}/${warehouse_name}` , (
          <div>
            {conditionalExp(isLoading , (
              <Loader />
            ) , (
              <>
                <div
                  className="w-100 py-3 d-flex align-items-center justify-content-between"
                  style={{ padding: "18px" }}
                >
                  <div
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  >
                    <button type="button" className="back-btn">
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_leftarrow"></use>
                      </svg>
                    </button>
                    <strong className="fs-14">Back</strong>
                  </div>
                  <div className="">
                    {editEnable ? (
                      <button
                        onClick={() => {setEditEnable(false);
                          if(customer?.tax_identifier_type == 'Natural Person' || customerData?.tax_identifier_type == 'Natural Person'){
                            setrfcLength(13);
                          }else if(customer?.tax_identifier_type == 'Legal Entity' || customerData?.tax_identifier_type == 'Legal Entity'){
                            setrfcLength(12);
                          }else{
                            setrfcLength(0);
                          }
                        }}
                        type="button"
                        className="upload-btn"
                      >
                        Edit
                      </button>
                    ) : (
                      <div className="">
                        <button
                        onClick={handleSave}
                        type="submit"
                        disabled={vatRegistrationError || rfcError}
                        className="upload-btn "
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancel}
                        type="button"
                        
                        className="cancel-btn "
                      >
                        Cancel
                      </button>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between pt-4"
                  style={{ padding: "0px 18px" }}
                >
                  <div className="aside-img">
                    <img
                      src={window.location.origin + "/images/aside-logo.png"}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="mx-2">
                      <input
                      disabled={editEnable}
                        type="text"
                        className="fw-700 fs-20 w-100"
                        ref={organization_name}
                        defaultValue={
                          customer?.organization_name ||
                          customerData?.organization_name
                        }
                        id="brand"
                        placeholder="Brand Name"
                      />
                      <input
                      disabled={editEnable}
                        ref={web_url}
                        type="text"
                        className="fw-700 fs-16 w-100"
                        defaultValue={
                          customer?.web_url || customerData?.web_url
                        }
                        id="website"
                        placeholder="Brand Url"
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Customer type</label>
                  <input
                    type="text"
                    className="w-100"
                    defaultValue={[`${customer?.customer_type || customerData?.customer_type }` , `${conditionalExp((customer?.everest_type || customerData?.everest_type) , '5PL' , 'Non 5PL')}`].filter(i=> i != 'null').join(' | ')}
                    id="Beverages1"
                    placeholder="not available"
                    readOnly
                  />
                </div>
                <hr />
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Clickup Space Name</label>
                  <input
                    type="text"
                    className="w-100"
                    defaultValue={customer?.clickup_space_name || customerData?.clickup_space_name }
                    id="Beverages1"
                    placeholder="not available"
                    readOnly
                  />
                </div>
                <hr />
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Forecast Period</label>
                  <select
                  disabled={editEnable}
                  ref={demand_forcast_type_ref}
                    className="w-100"
                    defaultValue={customer?.demand_forcast_type || customerData?.demand_forcast_type }
                    id="Beverages1"
                    placeholder="not available"
                  >
                    <option value="">Select</option>
                      {demandforcasttypes?.map((item, index) => {
                        return (
                          <option key={index} value={item?.value}>
                            {item?.label?.toUpperCase()}
                          </option>
                        );
                      })} 
                  </select>
                </div>
                <hr />
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Industry type</label>
                  <input
                    ref={industry}
                    disabled={editEnable}
                    type="text"
                    className="w-100"
                    defaultValue={customer?.industry || customerData?.industry}
                    id="Beverages1"
                    placeholder="not available"
                  />
                </div>
                <hr />
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Opportunity Size</label>
                  <p className="fw-700 fs-20 d-flex ">
                    {" "}
                    $
                    <input
                      ref={opportunity_size}
                      disabled={editEnable}
                      type={"number"}
                      onWheel={event => { event.preventDefault(); }}
                      onScroll={event => { event.preventDefault(); }}
                      className="fw-700 fs-20 "
                      style={{ maxWidth: "fit-content" }}
                      defaultValue={
                        customer?.opportunity_size ||
                        customerData?.opportunity_size
                      }
                      id="amount1"
                      placeholder="not available"
                    />
                  </p>
                </div>
                <hr />
                <div className="" style={{ padding: "0px 18px" }}>
                  <label className="form-label fw-600">Approved Credit</label>
                  <p className="fw-700 fs-20 d-flex ">
                
                    $
                    <input
                      ref={credit_amount_limit}
                      disabled={editEnable}
                      type={"number"}
                      onWheel={event => { event.preventDefault(); }}
                      onScroll={event => { event.preventDefault(); }}
                      className="fw-700 fs-20 w-100"
                      defaultValue={
                        customer?.credit_amount_limit ||
                        customerData?.credit_amount_limit
                      }
                      id="amount2"
                      placeholder="not available"
                    />
                  </p>
                </div>
                <hr />

                {/* ====Accordian */}
                <div className="accodion-btn">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <svg className="icon me-2 fs-20">
                          <use href="#icon_contact"></use>
                        </svg>
                        Contact Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-3">
                          <label className="form-label fw-600">Name</label>
                          <input
                            ref={customer_name}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.customer_name ||
                              customerData?.customer_name
                            }
                            id="name1"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Email</label>
                          <input
                            ref={customer_email}
                            disabled={editEnable}
                            type="email"
                            className="d-block w-100"
                            defaultValue={
                              customer?.customer_email ||
                              customerData?.customer_email
                            }
                            id="email"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Phone</label>
                          <input
                            ref={customer_phone}
                            disabled={editEnable}
                            type="tel"
                            className="d-block w-100"
                            defaultValue={
                              customer?.customer_phone ||
                              customerData?.customer_phone
                            }
                            id="phone"
                            placeholder="not available"
                          />
                        </div>
                      </Accordion.Body>
                      <hr />
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <svg className="icon me-2 fs-20">
                          <use href="#icon_location"></use>
                        </svg>
                        Office Address
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-3">
                          <label className="form-label fw-600">Address 1</label>
                          <input
                            ref={address1}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.address1 || customerData?.address1
                            }
                            id="add1"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Address 2</label>
                          <input
                            disabled={editEnable}
                            ref={address2}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.address2 || customerData?.address2
                            }
                            id="add2"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">City</label>
                          <input
                            ref={city}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={customer?.city || customerData?.city}
                            id="city"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">State</label>
                          <input
                            ref={staate}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.state || customerData?.state
                            }
                            id="state"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Country</label>
                          <input
                            disabled={editEnable}
                            type="text"
                            ref={country}
                            className="d-block w-100"
                            defaultValue={
                              customer?.country || customerData?.country
                            }
                            id="country"
                            placeholder="not available"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Zip Code</label>
                          <input
                            ref={zip_code}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.zip_code || customerData?.zip_code
                            }
                            placeholder="not available"
                            id="zip"
                          />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr />
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      <svg className="icon me-2 fs-20">
                          <use href="#icon_cash"></use>
                        </svg>
                        Tax Related
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-3">
                          <label className="form-label fw-600">Cfdi Purpose</label>
                          {/* <input
                            ref={cfdi_purpose}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.cfdi_purpose || customerData?.cfdi_purpose
                            }
                            id="cfdi_purpose"
                            placeholder="not available"
                          /> */}
                          <select ref={cfdi_purpose}
                            disabled={editEnable}
                            className="d-block w-100"
                            defaultValue={
                              customer?.cfdi_purpose || customerData?.cfdi_purpose
                            }
                            id="cfdi_purpose"
                            placeholder="not available">
                            <option value="">not available</option>
                            <option value="D01">D01</option>
                            <option value="D02">D02</option>
                            <option value="D03">D03</option>
                            <option value="D04">D04</option>
                            <option value="D05">D05</option>
                            <option value="D06">D06</option>
                            <option value="D07">D07</option>
                            <option value="D08">D08</option>
                            <option value="D09">D09</option>
                            <option value="D10">D10</option>
                            <option value="G01">G01</option>
                            <option value="G02">G02</option>
                            <option value="G03">G03</option>
                            <option value="I01">I01</option>
                            <option value="I02">I02</option>
                            <option value="I03">I03</option>
                            <option value="I04">I04</option>
                            <option value="I05">I05</option>
                            <option value="I06">I06</option>
                            <option value="I07">I07</option>
                            <option value="I08">I08</option>
                            <option value="P01">P01</option>
                            <option value="S01">S01</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Sat tax regime classification</label>
                          {/* <input
                            ref={sat_tax_tegime_classification}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.sat_tax_tegime_classification || customerData?.sat_tax_tegime_classification
                            }
                            id="sat_tax_tegime_classification"
                            placeholder="not available"
                          /> */}
                        <select ref={sat_tax_tegime_classification}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.sat_tax_tegime_classification || customerData?.sat_tax_tegime_classification
                            }
                            id="sat_tax_tegime_classification"
                            placeholder="not available">
                            <option value="">not available</option>
                            <option value="601">601</option>
                            <option value="603">603</option>
                            <option value="605">605</option>
                            <option value="606">606</option>
                            <option value="607">607</option>
                            <option value="608">608</option>
                            <option value="609">609</option>
                            <option value="610">610</option>
                            <option value="611">611</option>
                            <option value="612">612</option>
                            <option value="614">614</option>
                            <option value="615">615</option>
                            <option value="616">616</option>
                            <option value="620">620</option>
                            <option value="621">621</option>
                            <option value="622">622</option>
                            <option value="623">623</option>
                            <option value="624">624</option>
                            <option value="625">625</option>
                            <option value="628">628</option>
                            <option value="629">629</option>
                            <option value="630">630</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Tax identifier type</label>
                          {/* <input
                            ref={tax_identifier_type}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.tax_identifier_type || customerData?.tax_identifier_type
                            }
                            id="tax_identifier_type"
                            placeholder="not available"
                          /> */}
                          <select 
                             ref={tax_identifier_type}
                             disabled={editEnable}
                             className="d-block w-100"
                             defaultValue={
                               customer?.tax_identifier_type || customerData?.tax_identifier_type
                             }
                             id="tax_identifier_type"
                             placeholder="not available"
                             onChange={e=>{
                              // setVatRegistrationNumber("")
                              setRfcNumber("")
                              if(e.target.value == 'Natural Person'){
                                setrfcLength(13);
                              }else if(e.target.value == 'Legal Entity'){
                                setrfcLength(12);
                              }else{
                                setrfcLength(0);
                              }
                            }}
                            >
                            <option value="">not available</option>
                            <option value="Natural Person">Natural Person</option>
                            <option value="Legal Entity">Legal Entity</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Vat registration no</label>
                          <input
                            ref={vat_registration_no}
                            disabled={editEnable}
                            type="text"
                            // onChange={e=>
                              
                            //   {
                            //     setVatRegistrationNumber(e.target.value)
                            //     // if(e.target?.value?.length < rfcLength){
                            //     //   setVatRegistrationError('VAT registration number should be '+rfcLength+' digits long.')
                            //     // }else{
                            //     //   setVatRegistrationError('')
                            //     // }
                            //   }
                            // }
                            className="d-block w-100"
                            defaultValue={
                              customer?.vat_registration_no || customerData?.vat_registration_no
                            }
                            id="vat_registration_no"
                            placeholder="not available"
                            
                          />
                          {/* {conditionalExp(vatRegistrationError &&!editEnable, <p className="text-danger"><small>{vatRegistrationError}</small></p>)} */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">RFC no</label>
                          {conditionalExp(!editEnable && !tax_identifier_type.current?.value,<p><small><i>note:Pick <b>tax identifier</b> before <b>RFC number</b>.</i></small></p>)}
                          <input
                            ref={rfc_no}
                            disabled={editEnable && !rfcLength}
                            type="text"
                            maxLength={rfcLength}
                            max={rfcLength}
                            minLength={rfcLength}
                            min={rfcLength}
                            value={rfcNumber}
                            onChange={e=>
                              
                              {
                                setRfcNumber(e.target.value)
                                if(e.target?.value?.length < rfcLength){
                                  setrfcError('RFC number should be '+rfcLength+' digits long.')
                                }else{
                                  setrfcError('')
                                }
                              }
                            }
                            className="d-block w-100"
                            defaultValue={
                              customer?.rfc_no || customerData?.rfc_no
                            }
                            id="rfc_no"
                            placeholder="not available"
                            
                          />
                          {conditionalExp(rfcError &&!editEnable, <p className="text-danger"><small>{rfcError}</small></p>)}
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Genbus posting group</label>
                          {/* <input
                            ref={genbus_posting_group}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.genbus_posting_group || customerData?.genbus_posting_group
                            }
                            id="genbus_posting_group"
                            placeholder="not available"
                          /> */}
                          <select ref={genbus_posting_group}
                            disabled={editEnable}  className="d-block w-100"  defaultValue={
                              customer?.genbus_posting_group || customerData?.genbus_posting_group
                            } id="genbus_posting_group"
                            placeholder="not available">
                            <option value="">not available</option>
                            <option value="EXT">EXT</option>
                            <option value="NAC">NAC</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Vatbus posting group</label>
                          {/* <input
                            ref={vatbus_posting_group}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.vatbus_posting_group || customerData?.vatbus_posting_group
                            }
                            id="vatbus_posting_group"
                            placeholder="not available"
                          /> */}
                          <select 
                            ref={vatbus_posting_group}
                            disabled={editEnable} 
                            className="d-block w-100"
                            defaultValue={
                              customer?.vatbus_posting_group || customerData?.vatbus_posting_group
                            }
                            id="vatbus_posting_group"
                            placeholder="not available"
                            >
                            <option value="">not available</option>
                            <option value="EXT">EXT</option>
                            <option value="NAC">NAC</option>
                          </select>
                        </div>
                        
                        <div className="mb-3">
                          <label className="form-label fw-600">Customer posting group</label>
                          {/* <input
                            ref={customer_posting_group}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.customer_posting_group || customerData?.customer_posting_group
                            }
                            id="customer_posting_group"
                            placeholder="not available"
                          /> */}
                           <select ref={customer_posting_group}
                            disabled={editEnable}
                            className="d-block w-100"
                            defaultValue={
                              customer?.customer_posting_group || customerData?.customer_posting_group
                            }
                            id="customer_posting_group"
                            placeholder="not available">
                            <option value="">not available</option>
                            <option value="EXT">EXT</option>
                            <option value="NAC">NAC</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Currency Code</label>
                          {/* <input
                            ref={currency}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.currency || customerData?.currency
                            }
                            id="currency"
                            placeholder="not available"
                          /> */}
                          <select ref={currency}
                            disabled={editEnable}
                            className="d-block w-100"
                            defaultValue={
                              customer?.currency || customerData?.currency
                            }
                            id="currency"
                            placeholder="not available">
                                                          <option value="">not available</option>

                            <option value="AED">Dírham Emi. Árabes Unidos</option>
                            <option value="AUD">Dólar australiano</option>
                            <option value="BGN">Leva búlgaro</option>
                            <option value="BND">Dólar Sultan. de Brunei</option>
                            <option value="BRL">Real brasileño</option>
                            <option value="CAD">Dólar canadiense</option>
                            <option value="CHF">Franco suizo</option>
                            <option value="CZK">Corona checa</option>
                            <option value="DKK">Corona danesa</option>
                            <option value="DZD">Dinar argelino</option>
                            <option value="EUR">Euro</option>
                            <option value="FJD">Dólar Islas Fidji</option>
                            <option value="GBP">Libra esterlina</option>
                            <option value="HKD">Dólar Hong Kong</option>
                            <option value="HRK">Kuna croata</option>
                            <option value="HUF">Florín húngaro</option>
                            <option value="IDR">Rupia indonesia</option>
                            <option value="INR">Rupia india</option>
                            <option value="ISK">Corona islandesa</option>
                            <option value="JPY">Yen japonés</option>
                            <option value="KES">Chelín keniata</option>
                            <option value="MAD">Dírham marroquí</option>
                            <option value="MYR">Ringgit malayos</option>
                            {/* <option value="MZN">Metical mozambiqueño</option> */}
                            <option value="NGN">Nairas nigerianos</option>
                            <option value="NOK">Corona noruega</option>
                            <option value="NZD">Dólar neozelandés</option>
                            <option value="PHP">Peso filipino</option>
                            <option value="PLN">Zloty polaco</option>
                            <option value="RON">Lei rumano</option>
                            <option value="RSD">Dinar serbio</option>
                            <option value="RUB">Rublo ruso</option>
                            <option value="SAR">Ryal saudí</option>
                            <option value="SBD">Dólar Islas Salomón</option>
                            <option value="SEK">Corona sueca</option>
                            <option value="SGD">Dólar Singapur</option>
                            <option value="SZL">Lilangeni Swazil.</option>
                            <option value="THB">Baht tailandés</option>
                            <option value="TND">Dinar tunecino</option>
                            <option value="TOP">Pa'anga tongano</option>
                            <option value="TRY">Nueva libra turca</option>
                            {/* <option value="UGX">Chelín ugandés</option> */}
                            <option value="USD">Dólar de EE.UU.</option>
                            <option value="VUV">Vato Vanuatu</option>
                            <option value="WST">Tala Samoa Oeste</option>
                            <option value="XPF">Franco CFP</option>
                            <option value="ZAR">Rand sudafricano</option>
                            <option value="MXN">Mexican peso</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Payment terms code</label>
                          {/* <input
                            ref={payment_terms_code}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.payment_terms_code || customerData?.payment_terms_code
                            }
                            id="payment_terms_code"
                            placeholder="not available"
                          /> */}
                          <select  ref={payment_terms_code}
                            disabled={editEnable}
                            className="d-block w-100"
                            defaultValue={
                              customer?.payment_terms_code || customerData?.payment_terms_code
                            }
                            id="payment_terms_code"
                            placeholder="not available">
                            <option value="">not available</option>

                            <option value="10 DÍAS">10D</option>
                            <option value="15 DÍAS">15D</option>
                            <option value="30 DÍAS">30D</option>
                            <option value="7 DÍAS">7D</option>
                            <option value="CONTADO">0D</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label fw-600">Payment method code</label>
                          {/* <input
                            ref={payment_method_code}
                            disabled={editEnable}
                            type="text"
                            className="d-block w-100"
                            defaultValue={
                              customer?.payment_method_code || customerData?.payment_method_code
                            }
                            id="payment_method_code"
                            placeholder="not available"
                          /> */}
                          <select ref={payment_method_code}
                            disabled={editEnable}
                            className="d-block w-100"
                            defaultValue={
                              customer?.payment_method_code || customerData?.payment_method_code
                            }
                            id="payment_method_code"
                            placeholder="not available">
                            <option value="">not available</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="08">08</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="17">17</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="99">99</option>
                          </select>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </>
            ))}
          </div>
        ) , (
          ""
        ))}

        {conditionalExp(location.pathname == `/${customerId}/booking_number` , (
          <div>
            <div className="w-100 py-3" style={{ paddingLeft: "60px" }}>
              
              <Breadcrumb separator=">" className="fs-6" items={brItems} />
              <div className="plus-btn pb-3 pt-5">
                <strong className="fw-700 fs-20">Containers</strong>
               
              </div>
            </div>
            <ul className="pan-btn">
              {containers?.map((container, index) => (
                <li key={container?.container_id + "container"}>
                  <button
                    type="button"
                    onClick={() => updateSelectedContainer(container)}
                    style={{
                      background:
                        selectedContainer.container_id ==
                        container?.container_id
                          ? "#EEF1FF"
                          : "",
                    }}
                  >
                    {container?.container_id}
                  </button>
                </li>
              ))}
              
            </ul>
          </div>
        ) , (
          ""
        ))}
      </aside>
    </>
  );
}
export default AsideBar;
