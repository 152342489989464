import React, { useState } from "react";
import Loader from "./Loader";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { DatePicker } from "antd";
import { getFormattedDate } from "../utils/commonFn";

function AddTagRangeModal({
  showrange,
  handleCloserange,
  tagCalculationSKUList,
  isLoadingTagCalSKU,
}) {
  const [selectedOption, setSelectedOption] = useState();
  const [submittingAddNewTagRange, setsubmittingAddNewTagRange] =
    useState(false);
  const [addNewTagRangeErrors, setaddNewTagRangeErrors] = useState({
    starting_num: false,
    final_num: false,
    num: false,
    g1: false,
    registered_date: false,
    taxpaid_date: false,
  });
  const [addNewTagRangeDetails, setaddNewTagRangeDetails] = useState({
    starting_num: "",
    final_num: "",
    num: "",
    g1: "",
    registered_date: "",
    taxpaid_date: "",
  });
  const [submitAddNewWarehouseError, setsubmitAddNewWarehouseError] =
    useState(null);

  const handleAddNewTagRangeChange = (e) => {
    setaddNewTagRangeDetails({
      ...addNewTagRangeDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewTagRangeErrors({
      ...addNewTagRangeErrors,
      [e.target.id]: false,
    });
  };

  const navigate = useNavigate();

  function smallCheckFnForAddRange() {
    let starting_num =
      addNewTagRangeDetails?.starting_num?.trim() == "" ? true : false;
    let final_num =
      addNewTagRangeDetails?.final_num?.trim() == "" ? true : false;
    let num = addNewTagRangeDetails?.num?.trim() == "" ? true : false;
    let g1 = addNewTagRangeDetails?.g1?.trim() == "" ? true : false;
    let registered_date =
      addNewTagRangeDetails?.registered_date?.trim() == "" ? true : false;
    let taxpaid_date =
      addNewTagRangeDetails?.taxpaid_date?.trim() == "" ? true : false;

    if (
      starting_num ||
      final_num ||
      num ||
      g1 ||
      registered_date ||
      taxpaid_date
    ) {
      setaddNewTagRangeErrors({
        starting_num,
        final_num,
        num,
        g1,
        registered_date,
        taxpaid_date,
      });
      return true;
    } else {
      return false;
    }
  }

  function  handleCloserangeIn(){
    setaddNewTagRangeErrors({
      starting_num: false,
      final_num: false,
      num: false,
      g1: false,
      registered_date: false,
      taxpaid_date: false,
    })
    setaddNewTagRangeDetails({
      starting_num: "",
      final_num: "",
      num: "",
      g1: "",
      registered_date: "",
      taxpaid_date: "",
    })

    setSelectedOption()
    setsubmittingAddNewTagRange(false)
    setsubmitAddNewWarehouseError()
    handleCloserange()
  }
  const onSubmitAddNewTagRange = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("mtm_user_token");

    if (smallCheckFnForAddRange()) {
      return;
    }
      setsubmittingAddNewTagRange(true);
      setsubmitAddNewWarehouseError(null);

      let data = {
        item_code: selectedOption?.value ,
        starting_tags: addNewTagRangeDetails.starting_num ,
        ending_tags: addNewTagRangeDetails.final_num ,
        number: addNewTagRangeDetails.num,
        g1: addNewTagRangeDetails.g1 ,
        registered_date: addNewTagRangeDetails.registered_date ,
        tax_paid_date: addNewTagRangeDetails.taxpaid_date , 
      };
      try {
        let response = await axios({
          url : `${process.env.REACT_APP_BASE_URL}/mtmtagcalculation/addTagRange`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
          data: data,
        });
        if (response.status == 200) {
          if (!response.data.error) {
            setTimeout(() => {
              handleCloserangeIn();
            }, 2000)
          } else {
            setsubmittingAddNewTagRange(false);
            setsubmitAddNewWarehouseError(response.data.message);
          }
        }
      } catch (error) {
        setsubmittingAddNewTagRange(false);
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        console.log(error);
      }
  };

  return (
    <Modal
      show={showrange}
      centered
      onHide={handleCloserangeIn}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4">
        <p className="text-center fs-26 fw-bold pb-2">Add Tag Range</p>
        <form onSubmit={onSubmitAddNewTagRange}>
          <div className="mb-2">
            {selectedOption && (
              <label htmlFor="sku" className="pb-1">
                Choose SKU
              </label>
            )}
            <Select
              id="sku"
              required
              value={selectedOption}
              onChange={setSelectedOption}
              options={tagCalculationSKUList?.map((tag) => ({
                label: `${tag?.item_code} || ${tag?.item_description}`,
                value: tag?.item_code,
              }))}
              isSearchable={true}
              placeholder="Choose SKU"
              isClearable
              isLoading={isLoadingTagCalSKU}
            />
          </div>
          <div className="input-box mb-2">
            {addNewTagRangeDetails.starting_num && (
              <label htmlFor="starting_num" className="pb-1">
                Starting Number (INICIO)
              </label>
            )}
            <input
              id="starting_num"
              type="number"
              className="w-100"
              placeholder="Enter Starting Number (INICIO)"
              onChange={handleAddNewTagRangeChange}
              value={addNewTagRangeDetails.starting_num}
            />
          </div>
          {addNewTagRangeErrors.starting_num && (
            <div className="text-danger mb-3">
              Please enter Starting Number (INICIO)
            </div>
          )}
          <div className="input-box mb-2">
            {addNewTagRangeDetails.final_num && (
              <label htmlFor="final_num" className="pb-1">
                Final Number
              </label>
            )}
            <input
              id="final_num"
              type="number"
              className="w-100"
              placeholder="Enter Final Number"
              onChange={handleAddNewTagRangeChange}
              value={addNewTagRangeDetails.final_num}
            />
          </div>
          {addNewTagRangeErrors.final_num && (
            <div className="text-danger mb-3">Please enter Final Number</div>
          )}
          {parseInt(addNewTagRangeDetails.final_num) < parseInt(addNewTagRangeDetails.starting_num) && (
            <div className="text-danger mb-3">Final Number cannot be less than Starting Number</div>
          )}
          <div className="input-box mb-2">
            {addNewTagRangeDetails.num && (
              <label htmlFor="num" className="pb-1">
                Number (Numero)
              </label>
            )}
            <input
              id="num"
              type="text"
              className="w-100"
              placeholder="Enter Number  (Numero)"
              onChange={handleAddNewTagRangeChange}
              value={addNewTagRangeDetails.num}
            />
          </div>
          {addNewTagRangeErrors.num && (
            <div className="text-danger mb-3">Please enter Number (Numero)</div>
          )}
          <div className="input-box mb-2">
            {addNewTagRangeDetails.g1 && (
              <label htmlFor="g1" className="pb-1">
                G1
              </label>
            )}
            <input
              id="g1"
              type="text"
              className="w-100"
              placeholder="Enter G1"
              value={addNewTagRangeDetails.g1}
              onChange={handleAddNewTagRangeChange}
            />
          </div>
          {addNewTagRangeErrors.g1 && (
            <div className="text-danger mb-3">Please enter G1</div>
          )}
          <div className="mb-2">
            {addNewTagRangeDetails.registered_date && (
              <label htmlFor="registered_date" className="pb-1">
                Registered Date (FECHA)
              </label>
            )}
            <DatePicker
              id="registered_date"
              className="form-control z-"
              placeholder="Select Registered Date (FECHA)"
              onChange={(values) => {
                if (!values) {
                  setaddNewTagRangeDetails((pre) => ({
                    ...pre,
                    registered_date: "",
                  }));
                  setaddNewTagRangeErrors((pre) => ({
                    ...pre,
                    registered_date: false,
                  }));
                } else {
                  setaddNewTagRangeDetails((pre) => ({
                    ...pre,
                    registered_date: getFormattedDate(values),
                  }));
                  setaddNewTagRangeErrors((pre) => ({
                    ...pre,
                    registered_date: false,
                  }));
                }
              }}
              style={{ height: "38px" }}
              format={"DD-MM-YYYY"}
            />
          </div>
          {addNewTagRangeErrors.registered_date && (
            <div className="text-danger mb-3">
              Please select Registered Date (FECHA)
            </div>
          )}
          <div className="mb-2">
            {addNewTagRangeDetails.taxpaid_date && (
              <label htmlFor="taxpaid_date" className="pb-1">
                Tax Paid Date
              </label>
            )}
            <DatePicker
              id="taxpaid_date"
              className="form-control z-"
              placeholder="Select Tax Paid Date"
              onChange={(values) => {
                if (!values) {
                  setaddNewTagRangeDetails((pre) => ({
                    ...pre,
                    taxpaid_date: "",
                  }));
                  setaddNewTagRangeErrors((pre) => ({
                    ...pre,
                    taxpaid_date: false,
                  }));
                } else {
                  setaddNewTagRangeDetails((pre) => ({
                    ...pre,
                    taxpaid_date: getFormattedDate(values),
                  }));
                  setaddNewTagRangeErrors((pre) => ({
                    ...pre,
                    taxpaid_date: false,
                  }));
                }
              }}
              style={{ height: "38px" }}
              format={"DD-MM-YYYY"}
            />
          </div>
          {addNewTagRangeErrors.taxpaid_date && (
            <div className="text-danger mb-3">Please select Tax Paid Date</div>
          )}
          <div className="d-flex">
            <div className="ms-auto">
              <p className="text-danger">{submitAddNewWarehouseError}</p>
              <button disabled={submittingAddNewTagRange} className="grd-btn rounded-pill" type="submit">
                {!submittingAddNewTagRange ? "Submit" : <Loader/>}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddTagRangeModal;
