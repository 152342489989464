import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../components/Loader";
import { SuccessModal } from "../components/SuccessModal";
import validator from "validator";
import CryptoJS from "crypto-js";
import { CustomerContext } from "../context/customerContext";

function Warehouse_users() {
  const navigate = useNavigate();
  const { customer } = useContext(CustomerContext);

  const { customerId, warehouse_id,warehouse_name } = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssigning, setIsLoadingAssigning] = useState(false)
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessagePopup, setsuccessMessagePopup] = useState();
  const [error, seterror] = useState();
  const [usersList, setUsersList] = useState([]);
const [showAssignUserModal, setShowAssignUserModal] = useState(false)
const [showAddUserModal, setShowAddUserModal] = useState(false)
const [showDelete, setShowDelete] = useState(false)
const [isLoadingDelete, setisLoadingDelete] = useState(false)
const [isLoadingAdding, setIsLoadingAdding] = useState(false)
const [selectedUser, setSelectedUser] = useState()
const [selectedCompany, setSelectedCompany] = useState()
const [selectedUserType, setSelectedUserType] = useState()
const [showPassword, setShowPassword] = useState(false)
const [addNewUserDetails, setaddNewUserDetails] = useState({
  name:"",
  email:"",
  phone:"",
  password:""
})
const [addNewUserErrors, setaddNewUserErrors] = useState({
  name:false,
  email:false,
  phone:false,
  password:false
})

const [warehouseUsers, setWarehouseUsers] = useState([])
const [companyList, setCompanyList] = useState([])
  const userTypes = [
    {  label: "Control Table", value: 6 },
    {  label: "Distribution", value: 7 },
  ];
  // submit purchase order function
  const getWarehouseUsers = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/getUsers`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
      });
      if (response.status == 200 && !response.data.error) {
        let userList = response.data.users?.map(user =>({label:user.user_full_name,value:user.id}))
        setWarehouseUsers([{label:"Add User",value:'Add User'},...userList]);
      }else{
        setWarehouseUsers([{label:"Add User",value:'Add User'}])
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  const getWarehouseCompanies = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/getCompanyList`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        
      });
      if (response.status == 200 && !response.data.error) {
        let userList = response.data?.company?.map(user =>({label:user.db_company,value:user.db_company,id:user.id}))
        setCompanyList(userList);
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  const getAssignedUsers = async () => {
    setIsLoading(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/assignedUsers/get`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          warehouse_id: warehouse_id,
          customer_id: customerId,
        },
      });
      if (response.status == 200 && !response.data.error) {
        setUsersList(response.data.users);
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  function handleCloseSuccess() {
    setShowSuccess(false)
    setsuccessMessagePopup()
  }
  function handleCloseDelete() {
    setShowDelete(false)
    setSelectedUser()
  }
  function handleCloseAssignUserModal() {
    setShowAssignUserModal(false)
    setSelectedUser()
    setSelectedCompany()
  }
  function handleCloseAddUserModal() {
    setShowAddUserModal(false)
    setSelectedUserType()
    setaddNewUserDetails({
      name:"",
      email:"",
      phone:"",
      password:""
    })
    setaddNewUserErrors({
      name:false,
      email:false,
      phone:false,
      password:false
    })
  }

  async function handleUserDelete(){
    setIsLoading(true)
    setisLoadingDelete(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/dissociateUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          warehouse_id: warehouse_id,
          customer_id: customerId,
          user_id: selectedUser?.id
        },
      });
      if (response.status == 200 && !response.data.error) {
        setUsersList([])
        await getAssignedUsers()
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
      setisLoadingDelete(false);
      handleCloseDelete()
    }
  };
  async function handleAssignUser(e){
    e.preventDefault()
    setIsLoading(true)
    setIsLoadingAssigning(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/assignUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          warehouse_id: warehouse_id,
          customer_id: customerId,
          user_id: selectedUser?.value,
          company_name: selectedCompany?.value || null
        },
      });
      if (response.status == 200 && !response.data.error) {
        setUsersList([])
        await getAssignedUsers()
      } else{
        seterror(response.data.message);
        setShowErrModal(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
      setIsLoadingAssigning(false);
      handleCloseAssignUserModal()
    }
  };
  function smallCheckFnForUser() {
    let name =
      addNewUserDetails?.name?.trim() == "" ? true : false;
    let email =
      !validator.isEmail(addNewUserDetails?.email);
    let phone =
      addNewUserDetails?.phone?.trim() == "" ? true : false;
    let password =
      !validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0})
   console.log(addNewUserDetails?.password,validator.isStrongPassword(addNewUserDetails?.password,{minLength:5,minUppercase:1,minSymbols:1,minNumbers:0}));
    if (name || email || phone || password) {
      setaddNewUserErrors({
        name,
        email,
        phone,
        password,
      });
      return true;
    } else {
      return false;
    }
  }
  async function handleAddUser(e){
    e.preventDefault()

    if (smallCheckFnForUser()) {
      return;
    }

    setIsLoading(true)
    setIsLoadingAdding(true)
    const token = localStorage.getItem("mtm_user_token");
    try {
      const cipherText = CryptoJS.AES.encrypt(
        addNewUserDetails.password,
        process.env.REACT_APP_SECRET
      );
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmwarehouse/addNewUser`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          user_type: selectedUserType?.value,
          name:addNewUserDetails?.name,
          email: addNewUserDetails?.email,
          phone: addNewUserDetails?.phone,
          password: cipherText.toString()
        },
      });
      if (response.status == 200 && !response.data.error) {
        setSelectedUser({label:response?.data?.user?.user_full_name,value:response.data?.user?.id})
        await getWarehouseUsers()
      } 
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
      seterror("Error: " + error);
      setShowErrModal(true);
    } finally {
      setIsLoading(false);
      setIsLoadingAdding(false);
      handleCloseAddUserModal()
    }
  };

  const handleAddNewUserChange = (e) => {
    setaddNewUserDetails({
      ...addNewUserDetails,
      [e.target.id]: e.target.value,
    });
    setaddNewUserErrors({
      ...addNewUserErrors,
      [e.target.id]: false,
    });
  };

  useEffect(() => {
    getAssignedUsers();
    getWarehouseUsers()
    customer?.everest_type && getWarehouseCompanies()
  }, []);

  return (
    <>
      <div className="w-100 ">
        <div className="py-3">
          <button
            type="button"
            className="back-btn d-flex alig-items-center"
            onClick={() => {
              navigate(`/${customerId}`, { state: location?.state });
            }}
          >
            <svg className="icon me-2 fs-22">
              <use href="#icon_leftarrow"></use>
            </svg>
            <strong className="fs-14">Back</strong>
          </button>
        </div>
        <div className=" ">
          <h2 className="fw-700 fs-24 text-center pb-4">Assigned Users To {warehouse_name}</h2>

          <div className="w-100 d-flex flex-column overflow-auto sku-tbl ">
            <button onClick={()=>setShowAssignUserModal(true)} className="grd-btn rounded-pill align-self-end">
              Assign User
            </button>
            {isLoading ? <Loader/>:<><table class="table text-center my-4 border">
              <thead>
                <tr className="border" style={{ background: "#EBEEFF" }}>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Type</th>
                  <th>Company</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  usersList.map((user, index) => (
                    <tr key={user?.name + user?.email}>
                      <td>{index + 1}</td>
                      <td>{user?.user_full_name}</td>
                      <td>{user?.email}</td>
                      <td>{user?.phone}</td>
                     
                      <td>
                        {
                          userTypes.find(
                            (item) => item.value == user?.user_type
                          )?. label
                        }
                      </td>
                      <td>{user?.company_name}</td>
                      <td >
                       <div className="icon-btn"><button className="delete-btn" onClick={()=>{
                        setSelectedUser(user)
                        setShowDelete(true)
                       }}>
                       <svg className="icon  fs-22">
                          <use href="#icon_delete"></use>
                        </svg>
                       </button></div>
                      </td>
                    </tr>
                  )) }
              </tbody>
            </table>
            {!usersList.length && <h2 className="fw-700 fs-24 text-center w-100">No users found</h2> }</>}
          </div>
        </div>
      </div>

      <SuccessModal
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        successMessagePopup={successMessagePopup}
      />
      <Modal
        show={showErrModal}
        onHide={() => {
          setShowErrModal(false);
          seterror();
        }}
        centered
      >
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center text-danger pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <h3>Error</h3>
          <h4>{error}</h4>
          <div className="d-flex justify-content-center  text-center mt-2">
            <Button
              variant="primary"
              className=""
              onClick={() => {
                setShowErrModal(false);
                seterror();
              }}
            >
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* delete confirmation modal */}
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">Are you sure you want delete ?</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="danger"
              disabled={isLoadingDelete}
              onClick={() => {
               handleUserDelete()
              }}
            >
              {!isLoadingDelete ? "Delete" : <Loader />}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/*assign new user modal */}
      <Modal show={showAssignUserModal} onHide={handleCloseAssignUserModal}   centered>
      <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Title >
          <p className="fs-4 text-center">Assign User To {warehouse_name}</p>
          </Modal.Title>
        <Modal.Body className="p-4">
          <form onSubmit={handleAssignUser} className="d-flex justify-content-center mt-2 flex-column">
          
          <div className="mb-3">
          {selectedUser && (
            <label className="mb-1">User</label>
          )}
          <Select
            value={selectedUser}
            onChange={option=> {setSelectedUser(option); if(option.value == "Add User"){
              setShowAddUserModal(true)
            }}}
            options={warehouseUsers}
            isSearchable={true}
            placeholder="select user "
            isLoading={!warehouseUsers.length}
          />
        </div>
          {customer?.everest_type && <div className="mb-3">
          {selectedCompany && (
            <label className="mb-1">Company</label>
          )}
          <Select
            value={selectedCompany}
            onChange={option=> {setSelectedCompany(option);
            }}
            options={companyList}
            isSearchable={true}
            placeholder="Select Company"
            isLoading={!companyList?.length}
          />
        </div>}
            <Button
              disabled={isLoadingAssigning}
             type="submit"
             className="align-self-end"
            >
              {!isLoadingAssigning ? "Submit" : <Loader />}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      {/*assign new user modal */}
      <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}   centered>
      <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Title >
          <p className="fs-4 text-center">Add New User</p></Modal.Title>
        <Modal.Body className="p-4">
          <form onSubmit={handleAddUser} className="d-flex justify-content-center mt-2 flex-column">
          <div className="input-box mb-3">
          {addNewUserDetails.name &&
            addNewUserDetails.name != "" && (
              <label htmlFor="name"> Name*</label>
            )}
          <input
            type="text"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="name"
            value={addNewUserDetails.name}
            placeholder="Enter Name*"
          />
        </div>
        {addNewUserErrors.name && (
          <div className="text-danger mb-3">
            Please enter valid name 
          </div>
        )}
          <div className="input-box mb-3">
          {addNewUserDetails.email &&
            addNewUserDetails.email != "" && (
              <label htmlFor="email"> Email*</label>
            )}
          <input
            type="email"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="email"
            value={addNewUserDetails.email}
            placeholder="Enter  Email*"
          />
        </div>
        {addNewUserErrors.email && (
          <div className="text-danger mb-3">
            Please enter valid email address
          </div>
        )}
          <div className="input-box mb-3">
          {addNewUserDetails.phone &&
            addNewUserDetails.phone != "" && (
              <label htmlFor="phone"> Phone *</label>
            )}
          <input
            type="number"
            className="w-100"
            onChange={handleAddNewUserChange}
            id="phone"
            value={addNewUserDetails.phone}
            placeholder="Enter  Phone*"
          />
        </div>
        {addNewUserErrors.phone && (
          <div className="text-danger mb-3">
            Please enter phone number
          </div>
        )}
        <div className="input-box mb-3">
          {addNewUserDetails.password &&
            addNewUserDetails.password != "" && (
              <label htmlFor="password"> Password*</label>
            )}
          <div className="position-relative">
          <input
            type={showPassword ? "text":"password"}
            className="w-100"
            onChange={handleAddNewUserChange}
            id="password"
            value={addNewUserDetails.password}
            placeholder="Enter Password*"
          />
           <button
           className="vision"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
       {!showPassword ? (
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
      </button>
          </div>
        </div>
        {addNewUserErrors.password && (
          <div className="text-danger mb-3">
            <p>Please enter strong password.</p>
            <small><i>**note: password should contain atleast 5 characters,1 uppercase letter & 1 special character</i></small> 
          </div>
        )}
          <div className="mb-3">
          {selectedUserType && (
            <label className="mb-1">User Type</label>
          )}
          <Select
          required
            value={selectedUserType}
            onChange={option=> {setSelectedUserType(option)}}
            options={userTypes}
            placeholder="select user type"
          />
          
        </div>
            <Button
              disabled={isLoadingAdding}
             type="submit"
             className="align-self-end"
            >
              {!isLoadingAdding ? "Submit" : <Loader />}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Warehouse_users;
