import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from './Loader'

export const DeleteVerificationModal = ({showDeleteUser
    ,handleCloseDelete,handleDelete,isLoadingDeleteUser}) => {
  return (
    <Modal key={'deleteverification'} show={showDeleteUser} onHide={handleCloseDelete} centered>
    <Modal.Body className="p-4">
      <p className="fs-4 text-center">Are you sure that you want to remove the user ?</p>
      <div className="d-flex justify-content-center mt-2">
        <Button
          variant="secondary"
          className="me-3"
          onClick={handleCloseDelete}
        >
          No
        </Button>
        <Button
          variant="danger"
          disabled={isLoadingDeleteUser}
          onClick={handleDelete}
        >
          {!isLoadingDeleteUser ? "Yes" : <Loader />}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
  )
}
