import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import axios from "axios";
import { conditionalExp, getCountriesFn } from "../utils/commonFn";
import  Select  from "react-select";

function AddSkuModal({ addSKUDetails,
    setaddSKUDetails,
    addSKUDetailsError,
    setaddSKUDetailsError, showSKUAdd,handleCloseSKUAdd, setselectedSKUListOption,customerId,getSKUList,setmodalData }) {
    const [submittingAddNewSku, setsubmittingAddNewSku] = useState(false);
    const [submitAddNewSKUError, setsubmitAddNewSKUError] = useState(null);
    const [optionsForCountries, setOptionsForCountries] = useState([]);
 

  const navigate = useNavigate()

  const handleAddNewSKUChange = (e) => {
    setaddSKUDetails({
      ...addSKUDetails,
      [e.target.id]: e.target.value,
    });
    setaddSKUDetailsError({
      ...addSKUDetailsError,
      [e.target.id]: null,
    });
  };

  const incotermOptions = [
    { label: 'EXW', value: 'EXW' },
    { label: 'CIF', value: 'CIF' },
    { label: 'FOB', value: 'FOB' },
    { label: 'FAS', value: 'FAS' },
    { label: 'CFR', value: 'CFR' },
    { label: 'DDP', value: 'DDP' },
    { label: 'DAP', value: 'DAP' },
    { label: 'DPU', value: 'DPU' },
    { label: 'CIP', value: 'CIP' },
    { label: 'CPT', value: 'CPT' },
    { label: 'FCA', value: 'FCA' },
    { label: 'EXW-FCA', value: 'EXW-FCA' }
  ]

  function smallCheckFnForSku(){
  
    for (const key of Object.keys({
    item_code: "",
    item_name: "",
    // pallet_type: "",
    // tax_code: "",
    unit_cost: "",
    // boxes_per_pallet: "",
    package_factor: "",
    // boxes_per_layer: "",
    // pallet_length: "",
    // pallet_width: "",
    // pallet_height: "",
    // c_length: "",
    // c_width: "",
    // c_height: "",
    // item_weight: "",
    // minimum_request_qty: "",
    // stackable_pallet: "",
    no_of_stack: "",
    // capacity: "",
    // brand: "",
    // brand_classification: "",
    // vendor_code: "",
    supplier_country: "",
    item_country: "",
    // group_name: "",
    // units_per_box: "",
    // units_per_pallet: "",
    // purchase_status: "",
    // sales_status: "",
    // inactive_status: "",
    // bar_code: "",
    // alcohal_grad: "",
    incoterm: "",
    // stock_qty: "",
  })) {
      if (addSKUDetails[key] == "" || addSKUDetails[key] == null) {
        if(key == 'no_of_stack' && (addSKUDetails['stackable_pallet'] == 'no' || !addSKUDetails['stackable_pallet'])){
          continue;
        }
        setaddSKUDetailsError({
         ...addSKUDetailsError,
          [key]: key.replaceAll('_', " ") +" is required",
        });
        return true;
      } 
    }

  }

  const onSubmitAddNewSku = async () => {
    const token = localStorage.getItem("mtm_user_token");
    
    
    if (smallCheckFnForSku()) {
      return;
    }
    let data = {
      ...addSKUDetails,
      alcohal_grad: addSKUDetails.alcohal_grad? addSKUDetails.alcohal_grad + "%" : 0 + "%",
    };
    data["customer_id"] = customerId;
    setsubmittingAddNewSku(true);
    setsubmitAddNewSKUError(null);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/item_sku_update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (response.status == 200) {
        if (!response.data.error) {
          getSKUList();
          setTimeout(() => {
            setmodalData && setmodalData({
              sku: addSKUDetails.item_code,
              qty: "",
              unit_cost: addSKUDetails.unit_cost,
              description: addSKUDetails.item_name,
              volume: "",
              pallet: "",
            });

            setselectedSKUListOption && setselectedSKUListOption({
              label: addSKUDetails.item_code,
              value: addSKUDetails.item_code,
            });
            handleCloseSKUAdd();
            setaddSKUDetails({
              customer_id: customerId,
              item_code: "",
              item_name: "",
              pallet_type: "",
              tax_code: "",
              unit_cost: "",
              boxes_per_pallet: "",
              package_factor: "",
              boxes_per_layer: "",
              pallet_length: "",
              pallet_width: "",
              pallet_height: "",
              c_length: "",
              c_width: "",
              c_height: "",
              item_weight: "",
              minimum_request_qty: "",
              stackable_pallet: "",
              no_of_stack: "",
              capacity: "",
              brand: "",
              brand_classification: "",
              vendor_code: "",
              supplier_country: "",
              item_country: "",
              group_name: "",
              units_per_box: "",
              units_per_pallet: "",
              purchase_status: "",
              sales_status: "",
              inactive_status: "",
              bar_code: "",
              alcohal_grad: "",
              incoterm: "",
              stock_qty: "",
            });
            
            setsubmittingAddNewSku(false);
          }, 2000);
        } else {
          setsubmitAddNewSKUError(response.data.message);
        }
      }
    } catch (error) {
      setsubmittingAddNewSku(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  
  useEffect(() => {
    getCountriesFn(
      setOptionsForCountries,
      null,
      navigate,
      customerId
    );
  }, [])


  return (
    <Modal
      show={showSKUAdd}
      centered
      onHide={() => {
        handleCloseSKUAdd();
        setselectedSKUListOption && setselectedSKUListOption(null);
      }}
      size="lg"
      
      scrollable={true}
    >
      <Modal.Header className="border-0 text-center" closeButton>        <p className="text-center fs-26 fw-bold pb-2 ">

        {conditionalExp(!addSKUDetails?.id,'Add SKU','Edit SKU')}
      </p>
</Modal.Header>
      <Modal.Body className="px-4 row-cols-3 row">

        <Col>
        <div className="input-box mb-3">
            <label htmlFor="item_code">SKU *</label>
          
          <input
          readOnly={addSKUDetails?.id ? true : false}
            type="text"
            className="w-100"
            id="item_code"
            value={addSKUDetails.item_code}
            onChange={handleAddNewSKUChange}
            placeholder="Enter SKU*"
          />
        </div>
        {addSKUDetailsError.item_code && (
          <div className="text-danger mb-3">{addSKUDetailsError.item_code}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
            <label htmlFor="item_name">Item Name *</label>
          <input
            type="text"
            className="w-100"
            id="item_name"
            value={addSKUDetails.item_name}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Item Name*"
          />
        </div>
        {addSKUDetailsError.item_name && (
          <div className="text-danger mb-3">{addSKUDetailsError.item_name}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
            <label htmlFor="pallet_type">Pallet Type </label>
          
          <input
            type="text"
            className="w-100"
            id="pallet_type"
            value={addSKUDetails.pallet_type}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Pallet Type"
          />
        </div>
        {addSKUDetailsError.pallet_type && (
          <div className="text-danger mb-3">{addSKUDetailsError.pallet_type}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3"> 
            <label htmlFor="tax_code">Tax Code </label>
           
          <input
            type="text"
            className="w-100"
            id="tax_code"
            value={addSKUDetails.tax_code}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Tax Code"
          />
        </div>
        {addSKUDetailsError.tax_code && (
          <div className="text-danger mb-3">{addSKUDetailsError.tax_code}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="unit_cost">Unit Cost (USD) *</label>
        
          <input
            type="number"
            className="w-100"
            id="unit_cost"
            value={addSKUDetails.unit_cost}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Unit Cost*"
          />
        </div>
        {addSKUDetailsError.unit_cost && (
          <div className="text-danger mb-3">{addSKUDetailsError.unit_cost}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="boxes_per_pallet">Boxes Per Pallet </label>
           <input
            type="number"
            className="w-100"
            id="boxes_per_pallet"
            value={addSKUDetails.boxes_per_pallet}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Boxes Per Pallet"
          />
        </div>
        {addSKUDetailsError.boxes_per_pallet && (
          <div className="text-danger mb-3">{addSKUDetailsError.boxes_per_pallet}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="package_factor">Package Factor *</label>
           <input
            type="number"
            className="w-100"
            id="package_factor"
            value={addSKUDetails.package_factor}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Package Factor*"
          />
        </div>
        {addSKUDetailsError.package_factor && (
          <div className="text-danger mb-3">{addSKUDetailsError.package_factor}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="boxes_per_layer">Boxes Per Layer </label>
           <input
            type="number"
            className="w-100"
            id="boxes_per_layer"
            value={addSKUDetails.boxes_per_layer}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Boxes Per Layer"
          />
        </div>
        {addSKUDetailsError.boxes_per_layer && (
          <div className="text-danger mb-3">{addSKUDetailsError.boxes_per_layer}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="pallet_length">Pallet Length (mm) </label>
           <input
            type="number"
            className="w-100"
            id="pallet_length"
            value={addSKUDetails.pallet_length}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Pallet Length (mm)"
          />
        </div>
        {addSKUDetailsError.pallet_length && (
          <div className="text-danger mb-3">{addSKUDetailsError.pallet_length}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="pallet_width">Pallet Width (mm) </label>
           <input
            type="number"
            className="w-100"
            id="pallet_width"
            value={addSKUDetails.pallet_width}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Pallet Width (mm)"
          />
        </div>
        {addSKUDetailsError.pallet_width && (
          <div className="text-danger mb-3">{addSKUDetailsError.pallet_width}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="pallet_height">Pallet Height (mm) </label>
           <input
            type="number"
            className="w-100"
            id="pallet_height"
            value={addSKUDetails.pallet_height}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Pallet Height (mm)"
          />
        </div>
        {addSKUDetailsError.pallet_height && (
          <div className="text-danger mb-3">{addSKUDetailsError.pallet_height}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="c_length">Box Length (mm) </label>
           <input
            type="number"
            className="w-100"
            id="c_length"
            value={addSKUDetails.c_length}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Box Length (mm)"
          />
        </div>
        {addSKUDetailsError.c_length && (
          <div className="text-danger mb-3">{addSKUDetailsError.c_length}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="c_width">Box Width (mm) </label>
           <input
            type="number"
            className="w-100"
            id="c_width"
            value={addSKUDetails.c_width}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Box Width (mm)"
          />
        </div>
        {addSKUDetailsError.c_width && (
          <div className="text-danger mb-3">{addSKUDetailsError.c_width}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="c_height">Box Height (mm) </label>
           <input
            type="number"
            className="w-100"
            id="c_height"
            value={addSKUDetails.c_height}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Box Height (mm)"
          />
        </div>
        {addSKUDetailsError.c_height && (
          <div className="text-danger mb-3">{addSKUDetailsError.c_height}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="item_weight">Unit Weight (grams) </label>
           <input
            type="number"
            className="w-100"
            id="item_weight"
            value={addSKUDetails.item_weight}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Unit Weight (grams)"
          />
        </div>
        {addSKUDetailsError.item_weight && (
          <div className="text-danger mb-3">{addSKUDetailsError.item_weight}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="minimum_request_qty">Min Request Qty </label>
           <input
            type="number"
            className="w-100"
            id="minimum_request_qty"
            value={addSKUDetails.minimum_request_qty}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Min Request Qty"
          />
        </div>
        {addSKUDetailsError.minimum_request_qty && (
          <div className="text-danger mb-3">{addSKUDetailsError.minimum_request_qty}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="stackable_pallet">Stackable Pallet </label>
           <select
            className="w-100 form-select"
            id="stackable_pallet"
            value={addSKUDetails.stackable_pallet}
            onChange={handleAddNewSKUChange}
          >
            <option value="">Select Stackable Pallet</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        {addSKUDetailsError.stackable_pallet && (
          <div className="text-danger mb-3">{addSKUDetailsError.stackable_pallet}</div>
        )}
        </Col>
        {addSKUDetails.stackable_pallet == "yes" && <Col>
        <div className="input-box mb-3">
             <label htmlFor="no_of_stack">No of stack </label>
           <input
            type="number"
            className="w-100"
            id="no_of_stack"
            value={addSKUDetails.no_of_stack}
            onChange={handleAddNewSKUChange}
            placeholder="Enter No of stack"
          />
        </div>
        {addSKUDetailsError.no_of_stack && (
          <div className="text-danger mb-3">{addSKUDetailsError.no_of_stack}</div>
        )}
        </Col>}
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="capacity">Capacity </label>
           <input
            type="text"
            className="w-100"
            id="capacity"
            value={addSKUDetails.capacity}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Capacity"
          />
        </div>
        {addSKUDetailsError.capacity && (
          <div className="text-danger mb-3">{addSKUDetailsError.capacity}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="brand">Brand </label>
           <input
            type="text"
            className="w-100"
            id="brand"
            value={addSKUDetails.brand}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Brand"
          />
        </div>
        {addSKUDetailsError.brand && (
          <div className="text-danger mb-3">{addSKUDetailsError.brand}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="brand_classification">Brand Classification </label>
           <input
            type="text"
            className="w-100"
            id="brand_classification"
            value={addSKUDetails.brand_classification}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Brand Classification"
          />
        </div>
        {addSKUDetailsError.brand_classification && (
          <div className="text-danger mb-3">{addSKUDetailsError.brand_classification}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="vendor_code">Vendor Code </label>
           <input
            type="text"
            className="w-100"
            id="vendor_code"
            value={addSKUDetails.vendor_code}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Vendor Code"
          />
        </div>
        {addSKUDetailsError.vendor_code && (
          <div className="text-danger mb-3">{addSKUDetailsError.vendor_code}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="supplier_country">Supplier Country *</label>
           <Select
            className="w-100 "
            id="supplier_country"
            options={optionsForCountries}
            value={optionsForCountries.find((option) => option.value == addSKUDetails.supplier_country ) || conditionalExp(addSKUDetails.supplier_country,{label:addSKUDetails.supplier_country,value:addSKUDetails.supplier_country},null) }
            onChange={(option)=>{
              handleAddNewSKUChange({
                target:{
                  id:"supplier_country",
                  value:option.value
                }
              })
            }}
            placeholder="Enter Supplier Country*"
          />
        </div>
        {addSKUDetailsError.supplier_country && (
          <div className="text-danger mb-3">{addSKUDetailsError.supplier_country}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="item_country">Item Country *</label>
             <Select
            className="w-100 "
            id="item_country"
            options={optionsForCountries}
            value={optionsForCountries.find((option) => option.value == addSKUDetails.item_country ) || conditionalExp(addSKUDetails.item_country,{label:addSKUDetails.item_country,value:addSKUDetails.item_country},null) }
            onChange={(option)=>{
              handleAddNewSKUChange({
                target:{
                  id:"item_country",
                  value:option.value
                }
              })
            }}
            placeholder="Enter Supplier Country*"
          />
        </div>
        {addSKUDetailsError.item_country && (
          <div className="text-danger mb-3">{addSKUDetailsError.item_country}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="group_name">Group Name </label>
           <input
            type="text"
            className="w-100"
            id="group_name"
            value={addSKUDetails.group_name}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Group Name"
          />
        </div>
        {addSKUDetailsError.group_name && (
          <div className="text-danger mb-3">{addSKUDetailsError.group_name}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="units_per_box">Units Per Box </label>
           <input
            type="number"
            className="w-100"
            id="units_per_box"
            value={addSKUDetails.units_per_box}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Units Per Box"
          />
        </div>
        {addSKUDetailsError.units_per_box && (
          <div className="text-danger mb-3">{addSKUDetailsError.units_per_box}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="units_per_pallet">Units Per Pallet </label>
           <input
            type="number"
            className="w-100"
            id="units_per_pallet"
            value={addSKUDetails.units_per_pallet}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Units Per Pallet"
          />
        </div>
        {addSKUDetailsError.units_per_pallet && (
          <div className="text-danger mb-3">{addSKUDetailsError.units_per_pallet}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="purchase_status">Purchase status </label>
           <select
            className="w-100 form-select"
            id="purchase_status"
            value={addSKUDetails.purchase_status}
            onChange={handleAddNewSKUChange}
          >
            <option value="">Select Purchase status</option>
            <option value="A">Active</option>
            <option value="I">Inactive</option>
          </select>
        </div>
        {addSKUDetailsError.purchase_status && (
          <div className="text-danger mb-3">{addSKUDetailsError.purchase_status}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="sales_status">Sales status </label>
           <select
            className="w-100 form-select"
            id="sales_status"
            value={addSKUDetails.sales_status}
            onChange={handleAddNewSKUChange}
          >
            <option value="">Select Sales status</option>
            <option value="A">Active</option>
            <option value="I">Inactive</option>
          </select>
        </div>
        {addSKUDetailsError.sales_status && (
          <div className="text-danger mb-3">{addSKUDetailsError.sales_status}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="inactive_status">Inactive status </label>
           <select
            className="w-100 form-select"
            id="inactive_status"
            value={addSKUDetails.inactive_status}
            onChange={handleAddNewSKUChange}
          >
            <option value="">Select Inactive status</option>
            <option value="A">Active</option>
            <option value="I">Inactive</option>
          </select>
        </div>
        {addSKUDetailsError.inactive_status && (
          <div className="text-danger mb-3">{addSKUDetailsError.inactive_status}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="bar_code">Barcode </label>
           <input
            type="text"
            className="w-100"
            id="bar_code"
            value={addSKUDetails.bar_code}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Barcode"
          />
        </div>
        {addSKUDetailsError.bar_code && (
          <div className="text-danger mb-3">{addSKUDetailsError.bar_code}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="alcohal_grad">Alcohol % </label>
           <input
            type="number"
            className="w-100"
            id="alcohal_grad"
            value={addSKUDetails.alcohal_grad?.replaceAll("%","")}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Alcohol %"
          />
        </div>
        {addSKUDetailsError.alcohal_grad && (
          <div className="text-danger mb-3">{addSKUDetailsError.alcohal_grad}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="incoterm">Incoterm *</label>
             <select
            className="w-100 form-select"
            id="incoterm"
            value={addSKUDetails.incoterm}
            onChange={handleAddNewSKUChange}
          >
            <option value="">Select Incoterm*</option>
            {incotermOptions.map((incoterm) => (
              <option value={incoterm.value}>{incoterm.label}</option>
            ))}
          </select>
        </div>
        {addSKUDetailsError.incoterm && (
          <div className="text-danger mb-3">{addSKUDetailsError.incoterm}</div>
        )}
        </Col>
        <Col>
        <div className="input-box mb-3">
             <label htmlFor="stock_qty">Stock Qty </label>
           <input
            type="number"
            className="w-100"
            id="stock_qty"
            value={addSKUDetails.stock_qty}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Stock Qty"
          />
        </div>
        {addSKUDetailsError.stock_qty && (
          <div className="text-danger mb-3">{addSKUDetailsError.stock_qty}</div>
        )}
        </Col>



        
      </Modal.Body>
      <Modal.Footer>
      {submitAddNewSKUError && (
          <div className="mb-3 text-danger">{submitAddNewSKUError}</div>
        )}

        <div className="d-flex">
          <div className="ms-auto">
            <button
              className="grd-btn rounded-pill"
              onClick={onSubmitAddNewSku}
              disabled={submittingAddNewSku}
            >
              {submittingAddNewSku ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSkuModal;
